import { ResponseData } from "services/models/TypedResponse";
import { AsyncStatus } from "store/AsyncStateObject";

export const MaxActiveDownloads = 5;

export interface FileDownloadInterface {
  id: string;
  error: string | null;
  featureId: string;
  fileType: string;
  fileName: string | undefined;
  status: AsyncStatus;
  url: string | undefined;
}

export interface AddFileDownloadInterface {
  id: string;
  featureId: string;
  fileType: string;
  call: Promise<ResponseData<any>>;
  abort?: AbortController;
  message?: string;
}

export interface RemoveFileDownloadInterface {
  id: string;
  featureId: string;
}

export function AddFileDownloadInterfaceFactory(): Partial<AddFileDownloadInterface> {
  return { id: "", featureId: "", fileType: "" };
}

export function FileDownloadInterfaceFactory(): FileDownloadInterface {
  return {
    id: "",
    error: null,
    featureId: "",
    fileType: "",
    fileName: undefined,
    status: AsyncStatus.IDLE,
    url: undefined,
  };
}
