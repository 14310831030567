import { useState, useLayoutEffect } from "react";
import { storeKey } from "./reducers";
import { store } from "index";
//import { getState, subscribe } from "./reducers";

export const getState = () => store.getState()[storeKey];

const subscribe = (f: Function) => {
  let lastState = getState();
  return store.subscribe(
    () => lastState !== getState() && f((lastState = getState()))
  );
};
const useNotifications = () => {
  const [state, setState] = useState(getState());
  useLayoutEffect(() => subscribe(setState), [setState]);

  return { state };
};
export default useNotifications;
