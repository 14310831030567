import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  ReducersMapObject,
  Store,
  Reducer,
  Action,
} from "redux";
import thunk from "redux-thunk";
import { ProfileDuck } from "./userProfileStore";
import { SystemDuck } from "./systemStore";
import { PriceAndAvailabilityDuck } from "./priceAndAvailabilityStore";
import { cartSlice } from "features/OrderEntry/store/reducers";
import { orderSearchSlice } from "features/OrderSearch/store/reducers";
import { rmaSearchSlice } from "features/RMARequests/store/reducers";
import { systemDownloadManagerSlice } from "./system/downloadManager/reducers";
import { systemNotificationSlice } from "./system/notifications/reducers";
import { invoiceSearchSlice } from "../features/InvoiceSearch/store/reducers";
import { quoteSearchSlice } from "../features/QuoteSearch/store/reducers";
import { replacementPartsSlice } from "features/ReplacementParts/store/reducers";
import { featureSliceRmaEntry } from "features/RMAEntry/store/reducers";

export interface CustomAction<T> extends Action {
  payload: T;
}

export class ConfigureStore {
  public rootReducer: Reducer<any, CustomAction<any>>;
  constructor(private initialState: any) {
    const reducers = {
      userProfile: ProfileDuck.reducer,
      system: SystemDuck.reducer,
      priceAndAvailability: PriceAndAvailabilityDuck.reducer,
      // new redux architecture below here
      cart: cartSlice.reducer,
      systemDownloadManager: systemDownloadManagerSlice.reducer,
      systemNotifications: systemNotificationSlice.reducer,
      rmaSearch: rmaSearchSlice.reducer,
      rmaEntrySearch: featureSliceRmaEntry.reducer,
      invoiceSearch: invoiceSearchSlice.reducer,
      orderSearch: orderSearchSlice.reducer,
      quoteSearch: quoteSearchSlice.reducer,
      replacementParts: replacementPartsSlice.reducer,
    };

    this.rootReducer = combineReducers<any, CustomAction<any>>({
      ...reducers,
    } as ReducersMapObject<any, CustomAction<any>>);
  }

  configure(): Store<any> {
    const middleware = [thunk];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers: any[] = [];

    const isDevelopment = process.env.NODE_ENV === "development";
    if (
      isDevelopment &&
      typeof window !== "undefined" &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION__
    ) {
      enhancers.push((window as any).__REDUX_DEVTOOLS_EXTENSION__());
    }
    return createStore<any, any, any, any>(
      this.rootReducer,
      this.initialState,
      compose(applyMiddleware(...middleware), ...enhancers)
    );
  }
}
