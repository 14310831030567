import { UserTypes } from "../models/Admin";
import { ErrorResponse } from "../models/Error";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Brand, CustomerAccount } from "../models/UserAccount";
import { ApiCancelTokenSource } from "./ApiService";
import { Guid } from "../models/Guid";
import { UserRecord } from "../store/systemStore";

export interface priceAndAvailabilitySearchItemInterface {
  materialNumber: string;
  quantity: number | string;
  salesOrg?: string;
  division?: string;
}

export interface materialLookupInterface {
  value: string;
  cancelTokenSource?: ApiCancelTokenSource;
}

export interface customerMaterialLookupInterface
  extends materialLookupInterface {
  accountNumber: string;
}

export interface materialSearchInterface
  extends customerMaterialLookupInterface {}

export interface materialToStepIdInterface {
  materialNumber: string;
  salesOrg: string;
  division: string;
  cancelTokenSource?: ApiCancelTokenSource;
}

export interface priceAndAvailabilitySearchMultipleInterface {
  items: priceAndAvailabilitySearchItemInterface[];
  accountNumber: string;
  priceDate?: Date;
  cancelTokenSource?: ApiCancelTokenSource;
}

export const isPriceAndAvailabilitySearchMultipleInterface = (
  x: any
): x is priceAndAvailabilitySearchMultipleInterface =>
  typeof x === "object" && "items" in x;

export interface priceAndAvailabilitySearchSingleInterface {
  item: priceAndAvailabilitySearchItemInterface;
  accountNumber: string | undefined;
  priceDate?: Date;
  cancelTokenSource?: ApiCancelTokenSource;
}

export const isPriceAndAvailabilitySearchSingleInterface = (
  x: any
): x is priceAndAvailabilitySearchSingleInterface =>
  typeof x === "object" && "item" in x;

export interface productDetailInterface {
  materialNumber: string;
  salesOrg: string;
  division: string;
}

export interface availabilityForecastForPlantLookupInterface {
  materialNumber: string;
  plantNumber: string;
  requestedDate: string; // YYYYMMDD
  qty: number;
}

export interface CustomerOrderSearchByDateInterface {
  accountNumber: string;
  startDate: string; // YYYYMMDD
  endDate: string; // YYYYMMDD
}

export interface CustomerOrderSearchByMaterialDateInterface
  extends CustomerOrderSearchByDateInterface {
  materialNumber: string;
}

export interface CustomerOrderSearchByPurchaseOrderNumberInterface {
  orderNumber: string;
}

export interface RouteDefinition {
  functionModuleId: string | undefined;
  name: string;
  title: string;
  redirectPath: string;
  modulePath: string;
  isPublic: boolean;
  exact: boolean;
  isDefault: boolean;
  icon: {
    id: string;
    name: string;
    shortName: string;
  };
  displayOrder: number;
  isDeleted: boolean;
  isAdmin: boolean;
  description: string;
  isActiveInClient: boolean;
  node: string | null;
}

export interface ApplicationUserInterface {
  userId: string;
  userName: string;
  name: string;
  lastLogon: string;
  isActive: boolean;
}

export interface IconWithDefinitionInterface {
  id: number;
  name: string;
  iconname: string;
  icondefinition: IconDefinition;
}

export interface UserFunctionModuleInterface {
  userFunctionModuleId: string;
  userName: string;
  isDeleted: boolean;
  functionModuleViewModel: RouteDefinition;
}

export interface PaginatedInterface {
  pageSize?: number;
  page?: number;
  searchTerm?: string;
}

export interface PaginatedUserAccountsInterface extends PaginatedInterface {
  userEmail: string;
}

export interface UserAccountSearchInterface {
  userId: string;
  accountNumber?: string;
}

export interface UserBrandSearchInterface extends PaginatedInterface {
  userId: string;
}

export interface UserAccountMetaDataDefaultInterface {
  soldToPartyId: string;
  isDefault: boolean;
  isDeleted: boolean;
}
export interface UserAccountMetaDataFavoriteInterface {
  soldToPartyId: string;
  isDeleted: boolean;
  isFavorite: boolean;
}

export interface UserAccountMetaDataUpdateInterface
  extends UserAccountMetaDataDefaultInterface,
    UserAccountMetaDataFavoriteInterface {}

export interface UserAccountMetadataUpdate
  extends UserAccountMetaDataDefaultInterface,
    UserAccountMetaDataFavoriteInterface {
  userId: string;
  userAccountId: string;
  message: string;
}

export interface UserSearchInterface {
  searchTerm: string;
  offset: number;
  limit: number;
}

export interface Document {
  fileName: string | undefined;
  fileData: Blob | undefined;
  url?: string;
}

export interface ResponseData<T> {
  Error: ErrorResponse;
  Results?: T;
}

export interface ResponseArray<T> {
  Error?: ErrorResponse;
  Results: T[];
}
// eslint-disable-next-line
export interface PaginatedSearchResponse<T> {
  count: number;
  matched: number;
  pageCount: number;
  total: number;
}

export interface User {
  //userId: string; // not a valid userId
  name: string;
  userName: string;
  lastLogon: string; // date string
}

export interface UserIdentityResponseInterface {
  userId: string;
  userPrincipalName: string;
  accountsAssigned: boolean;
  accountsCount: number;
  brandsCount: number;
  accountGroupsCount: number;
  userType: {
    userTypeId: number;
    name: string;
  };
  phoneNumber: string;
  companyName: string;
  country: string;
}

export interface UserSearchResponse
  extends PaginatedSearchResponse<UserRecord> {
  users: UserRecord[];
}

export interface AssignUserAccountRequest {
  userId: string;
  accountNumber: string;
}

export interface CreateGroupAccountRequest {
  userId: string;
  accountNumber: string;
  brands: AssignedBrands[];
}

export interface UpdateGroupAccountRequest {
  userId: string;
  accountNumber: string;
  brands: AssignedBrands[];
}

export interface UpdateUserAccountRequest extends AssignUserAccountRequest {
  brands: AssignedBrands[];
}

export interface AssignedBrands {
  salesOrg: string;
  division: string;
  enabled: boolean;
}

export interface BrandRequest extends AssignedBrands {
  accountNumber: string;
  name: string;
}

export interface AssignUserAccountResponse {
  userId: string;
  accountNumber: string;
}

export interface PaginatedAssignUserAccountResponse extends PaginatedInterface {
  userId: string;
  accountNumber: string;
}

export interface AssignBrandAccountRequest {
  userId: string;
  division: string;
  salesOrg: string;
}

export interface DeleteBrandAccountRequest {
  userId: string;
  userBrandGroupId: string;
}

export interface DeleteGroupAccountRequest {
  userId: string;
  accountGroupNumber: string;
}
// eslint-disable-next-line
export interface PaginatedIdentityResponse<T> {
  totalCount: number;
  numberOfPages: number;
  pageSize: number;
}

export interface PaginatedUserRequestId extends PaginatedInterface {
  userId: Guid;
}

export interface PaginatedUserRequestEmail extends PaginatedInterface {
  userEmail: string;
}

export interface PaginatedCustomerResponse
  extends PaginatedIdentityResponse<CustomerAccount> {
  userId: string;
  customers: CustomerAccount[];
}

export interface AssignedBrand extends Brand {  
  enabled?: boolean;
  disabled?: boolean;
  mappingType?: string;
}

export interface BrandsSearchResponse
  extends PaginatedIdentityResponse<AssignedBrand> {
  brands: AssignedBrand[];
}

export interface AvailabilityForecastForPlant {
  materialNumber: string;
  unit: string;
  requestedDate: string;
  quantity: number;
  //serviceErrorsReturned: string;
  plant: {
    number: string;
  };
  availability: [
    {
      promiseDate: string;
      quantity: number;
    }
  ];
}
export interface CustomerAccountRequest {
  userId: string;
  firstName: string;
  lastName: string;
  displayName: string;
  companyName: string;
  branchNumber: string;
  phoneNumber: string;
  emailAddress: string;
  country:string;
  userType: {
    userTypeId: UserTypes;
    name: string;
  };
  userBrandGroupRequests: BrandRequest[];
  userAccountGroupRequests: BrandRequest[];
  userCustomerBrandRequests: BrandRequest[];
}
export interface UserTypeInterface {
  userTypeId: UserTypes;
  name: string;
}

export interface OrderSearchByOrderNumberInterface {
  purchaseOrderNumber?: string;
  orderNumber?: string;
}

export interface OrderSearchBySoldToAccountInterface {
  accountNumber: string;
  fromDate?: string; // YYYYMMDD
  toDate?: string; // YYYYMMDD
}

export interface OrderSearchByMaterialNumberInterface
  extends OrderSearchBySoldToAccountInterface {
  productNumber: string;
}

export interface PaginatedPriceFileRequest extends PaginatedInterface {
  searchTerm: string;
}

export interface PaginatedPriceFileResponse<PriceFile> {
  TotalCount: number;
  NumberOfPages: number;
  PageSize: number;
  PriceFiles: PriceFile[];
}
