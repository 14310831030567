import { AbstractService } from "./AbstractService";

export namespace Customers {
  export interface Address {
    accountNumber: string;
    name1: string;
    name2: string;
    address: string;
    poBox: string;
    poBoxPostalCode: string;
    poBoxCity: string;
    city: string;
    region: string;
    postalCode: string;
    district: string;
    countryCode: string;
  }
  export namespace Input {
    export interface GetBillToPartnerAddress {
      customerAccountNumber: string;
      division: string;
      salesOrg: string;
    }
  }
  export namespace Output {}
  export class Service extends AbstractService {
    /**
     *
     * @description gets the Bill-To partener address for an account and sales org/divsion
     * @param {Input.GetBillToPartnerAddress} query
     * @param {AbortSignal|undefined} abortSignal
     * @returns {Promise<Address>}
     */
    async getBillToPartnerAddress(
      query: Input.GetBillToPartnerAddress,
      abortSignal?: AbortSignal
    ): Promise<Address> {
      const { customerAccountNumber, ...params } = query;
      const headers = await this.getAuthorizationHeaders();
      let result = await this.connection.get(
        `customers/${customerAccountNumber}/bill-to`,
        {
          baseURL: this.baseUrl,
          headers: headers,
          params: params,
          signal: abortSignal,
        }
      );
      return result.data;
    }
  }
}
