import * as React from "react";
import classNames from "classnames";

export type PageLayoutProps = {
  children: React.ReactNode;
  className?: string;
};

const Header = ({ className, children, ...props }: PageLayoutProps) => {
  return (
    <div className={classNames(className, "app-header")} {...props}>
      {children}
    </div>
  );
};
const Sidebar = ({ className, children, ...props }: PageLayoutProps) => {
  return (
    <div className={classNames(className, "app-sidebar")} {...props}>
      {children}
    </div>
  );
};
const Body = ({ className, children, ...props }: PageLayoutProps) => {
  return (
    <div className={classNames(className, "app-body")} {...props}>
      {children}
    </div>
  );
};
const Footer = ({ className, children, ...props }: PageLayoutProps) => {
  return (
    <div className={classNames(className, "app-footer")} {...props}>
      {children}
    </div>
  );
};

const Page = ({ className, children, ...props }: PageLayoutProps) => {
  return (
    <div className={classNames(className, "app-container")} {...props}>
      {children}
    </div>
  );
};

Page.displayName = "Page";
Page.Header = Header;
Page.Sidebar = Sidebar;
Page.Body = Body;
Page.Footer = Footer;

export default Page;
