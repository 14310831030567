import { FC, useCallback, useEffect, useState } from "react";
import useInterval from "../hooks/useInterval";
import { removeFromDownloadList, updateToDownloadList } from "store/system/downloadManager/dispatchers";
import DownloadFIleService, {
  DownloadStatus,
} from "services/DownloadFIleService";
import { DeNotify, Notify } from "store/system/notifications/helpers";

const maxInterval = 1000 * 60 * 15; // 15 minutes max interval

/*
 * Component to periodically check the download status
 */
const DownloadPollingComponent: FC<{
  downloadID: string;
  status: string;
}> = ({ downloadID, status }): JSX.Element => {
  const [lastRan, setLastRan] = useState(new Date().getTime());
  const [pollingInterval, setPollingInterval] = useState<number | null>(10000); //make use of set polling interval to stop polling
  const [abortController, setAbortController] = useState<AbortController>();
  const setupAbort = useCallback(() => {
    const controller = new AbortController();
    setAbortController(controller);
    return controller;
  }, []);

  const hybernationPoll = useCallback(() => {
    const currentTime = new Date().getTime();
    // if the interval was paused for more than 15 minutes
    if (currentTime > lastRan + maxInterval) {
      console.info(
        "Max interval for document download reached, Clearing the ID for download"
      );
      let id = new Date().getTime().toString();
      Notify({
        id: id,
        title: "Timed Out",
        message: "Download timed out since server took time to respond.",
        allowDismiss: true,
      });
      setPollingInterval(null);
      removeFromDownloadList({
        Id: downloadID,
        Status: status,
        Error: "Timed Out",
      } as DownloadStatus);
    }
  }, [lastRan, downloadID, status]);

  useInterval(() => {
    async function fetchData() {
      console.log("status", status);
      const currentTime = new Date().getTime();
      let downloadService = new DownloadFIleService();
      let id = new Date().getTime().toString();
      let controller = setupAbort();
      await downloadService
        .downloadStatus(downloadID, controller.signal)
        .then((response: any) => {
          if (response.data !== undefined) {
            updateToDownloadList(response.data);
            if (
              response.data.DownloadUrl &&
              response.data.DownloadUrl !== null
            ) {
              setPollingInterval(null);
            } else if (response.data.Error !== undefined || response.data.Status === "Error") {
              Notify({
                id: id,
                title: "Error",
                message:
                  "An undefined error occurred while requesting your Quote Details.",
                allowDismiss: true,
              });
              setTimeout(() => {
                DeNotify(id);
              }, 1000);
              setPollingInterval(null);
            }
          }
        })
        .catch((e: any) => {
          Notify({
            id: id,
            title: "Download failed",
            message:
              e?.message ||
              `An undefined error occurred while requesting your Quote Details.`,
            allowDismiss: true,
          });
          // adding below code to stop polling and
          abortController?.abort();
          setLastRan(currentTime);
          setPollingInterval(null);
        });
    }
    fetchData();
  }, pollingInterval);

  useEffect(() => {
    if (status === "Completed") {
      setPollingInterval(null);
    }
  }, [status]);

  useInterval(hybernationPoll, 10000);

  return <></>;
};
export default DownloadPollingComponent;
