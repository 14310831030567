import { ControlledTableState } from "features/common/components/Table/models";
import {
  AsyncStateObject,
  AsyncStateObjectInitialStateFactory,
} from "store/AsyncStateObject";
import {
  DefaultReplacementPartsSearchQuery,
  ReplacementPartsQuery,
} from "./ReplacementPartsQuery";
import { HybrisApi } from "services/HybrisApi";
import SapRfcIntegrationApi from "services/SapRfcIntegrationApi";

export interface MaterialPriceAndAvailabilityEntry
  extends AsyncStateObject<SapRfcIntegrationApi.Materials.Output.PriceAndAvailability> {}

export default interface ReplacementPartsState {
  userInput: ReplacementPartsQuery;
  searchResult: AsyncStateObject<
    SapRfcIntegrationApi.ReplacementParts.Output.SearchResult[]
  >;
  componentsResult: AsyncStateObject<
    SapRfcIntegrationApi.ReplacementParts.Output.MaterialComponent[]
  >;
  materialDetail: AsyncStateObject<HybrisApi.Output.ProductDetailResult>;
  materialPriceAndAvailability: {
    [
      key: string // material_salesOrg_division
    ]: MaterialPriceAndAvailabilityEntry;
  };
  tableState: {
    searchResult: ControlledTableState | undefined;
    componentsResult: ControlledTableState | undefined;
  };
}

export const InitialReplacementPartsState: ReplacementPartsState = {
  userInput: DefaultReplacementPartsSearchQuery,
  searchResult: AsyncStateObjectInitialStateFactory(),
  componentsResult: AsyncStateObjectInitialStateFactory(),
  materialDetail: AsyncStateObjectInitialStateFactory(),
  materialPriceAndAvailability: {},
  tableState: {
    searchResult: undefined,
    componentsResult: undefined,
  },
};
