// this file exports our bundled API classes under a common namespace

import { AbstractService } from "./AbstractService";
import ProductDetail from "./models/ProductDetail";

export namespace HybrisApi {
  export namespace Output {
    export interface ProductDetailResult extends ProductDetail {}
  }
  export class Service extends AbstractService {
    /**
     *
     * @param {string} stepId
     * @param {AbortSignal|undefined} signal
     * @returns {Promise<ProductDetail>}
     */
    async productDetail(
      stepId: string,
      signal?: AbortSignal
    ): Promise<Output.ProductDetailResult> {
      const headers = await this.getAuthorizationHeaders();
      const result = await this.connection.get(`products/${stepId}`, {
        signal: signal,
        baseURL: this.baseUrl,
        headers,
      });
      return result.data;
    }
  }
}
