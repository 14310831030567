import { FC } from "react";
import "flag-icons/css/flag-icons.min.css";

import {
  CountryCode,
  EnvironmentName,
  EnvironmentNames,
} from "store/system/constants";
import classNames from "classnames";

//const environmentIndicator = EnvironmentName + "-" + CountryCode;
const environmentIndicator =
  EnvironmentName !== EnvironmentNames.PROD ? EnvironmentName : "";
const EnvironmentIndicator: FC<{}> = (): JSX.Element => {
  return (
    <small className="environment_indicator">
      <span className="country">
        <span
          className={classNames([
            "icon",
            "fi",
            `fi-${CountryCode.toLowerCase()}`,
          ])}
        ></span>
        {CountryCode}
      </span>
      <span className="environment">{environmentIndicator}</span>
    </small>
  );
};
export default EnvironmentIndicator;
