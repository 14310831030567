import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from "@fortawesome/pro-solid-svg-icons";
import {
  IconPrefix,
  IconName,
  IconLookup,
  IconDefinition,
  findIconDefinition
} from '@fortawesome/fontawesome-svg-core'

const FaSolidIconPrefix = "fas";

export const getIconDefinition = (shortName: string): IconDefinition => {
    library.add(fas);
    let prefix = FaSolidIconPrefix as IconPrefix;
    let iconName = shortName as IconName;
    let iconLookup: IconLookup = { prefix: prefix, iconName: iconName };
    let iconDefinition: IconDefinition = findIconDefinition(iconLookup);

    return (iconDefinition);
}