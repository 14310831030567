import { store } from "index";
import { useLayoutEffect, useState } from "react";
import { mapSystemStateToProps } from "store/systemStore";

export const getState = () => store.getState()["system"];

const subscribe = (f: Function) => {
  let lastState = getState();
  return store.subscribe(
    () => lastState !== getState() && f((lastState = getState()))
  );
};

export const useSystem = () => {
  const [state, setState] = useState(getState());
  useLayoutEffect(() => subscribe(setState), [setState]);
  return { state: mapSystemStateToProps(state) };
};
export default useSystem;
