import { OrderEventPayload } from "../OrderEntryActionButton/models/OrderEventPayload";

const instanceId = Math.floor(Math.random() * Date.now()).toString(36);
console.log("instance id", instanceId);
export const AddToCartEventKey = "HC_AddToCart_" + instanceId;
export const RefreshCartEventKey = "HC_RefreshCart_" + instanceId;

export const AddToCartEvent = (data: OrderEventPayload) =>
  new CustomEvent(AddToCartEventKey, {
    bubbles: true,
    cancelable: false,
    detail: data,
  });

export const RefreshCartEvent = () =>
  new CustomEvent(RefreshCartEventKey, {
    bubbles: true,
    cancelable: false,
  });

export const dispatchRefreshCartEvent = () => {
  document.dispatchEvent(RefreshCartEvent());
};

export const dispatchAddToCartEvent = (payload: OrderEventPayload) => {
  return document.dispatchEvent(AddToCartEvent(payload));
};
