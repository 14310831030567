import { FC } from "react";

const ErrorPage: FC<{}> = (): JSX.Element => {
  return (
    <div className="text-center container my-5">
      <h1>Error</h1>
      <p>
        There was an issue initializing the system. Please try again later or contact {" "}
        <a href="mailto:hubbellconnectsupport@hubbell.com">customer support</a>{" "}
         of the problem persists.
      </p>
    </div>
  );
}
export default ErrorPage;
