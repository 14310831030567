import HubbellConnectOrder, {
  Address,
  Detail,
  Item,
  OrderAddressTypeName,
} from "models/HubbellConnectOrder";

import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import OrderEntryService from "services/OrderEntryService";
import CartState, {
  AddressUpdateAsyncStateObject,
  AddressValidateAsyncStateObject,
  FormStatus,
  HubbellOrderItemUpdateType,
  ItemPriceUpdateAsyncStateObject,
  ItemUpdateAsyncStateObject,
} from "./models/CartState";
import {
  DocumentURLUpdateInterface,
  UpdateOrderAddress,
  UpdateOrderItemInterface,
  ValidateAddress,
  fetchOrderDraftForAccount,
  fetchPriceAndAvailabilityForOrderItem,
} from "./dispatchers";
import { updateItem } from "./helpers";
import { LocalStorage } from "store/localStorage";
import { DeNotify, Notify } from "store/system/notifications/helpers";
import NotificationLevel from "store/system/notifications/models/NotificationLevel";
import {
  calculateOrderItemExtendedPrice,
  checkRequiredItemFieldNotEmpty,
  getSelectedPlant,
} from "./helpers";
import SapRfcIntegrationApi from "services/SapRfcIntegrationApi";
import { selectAccountNumber, selectCartOrder } from "./selectors";
import { cloneDeep } from "lodash";
import { validateOrderItem } from "./models/OrderValidation";
import {
  AsyncStateObjectInitialStateFactory,
  AsyncStatus,
} from "store/AsyncStateObject";
import { DataQuality } from "services/DataQuality";

export const storeKey = "cart";

export const revertAll = createAction("REVERT_ALL");

export const initialState: CartState = {
  orderDraft: AsyncStateObjectInitialStateFactory(),
  checkoutValidity: {
    items: FormStatus.INVALID,
    shippingDetails: FormStatus.INVALID,
    orderDetails: FormStatus.INVALID,
  },
  loading: false,
  itemData: new Array<ItemPriceUpdateAsyncStateObject>(),
  itemUpdateQueue: new Array<ItemUpdateAsyncStateObject>(),
  cartValidation: AsyncStateObjectInitialStateFactory(),
  addressValidation: new Array<AddressValidateAsyncStateObject>(),
  addressUpdate: new Array<AddressUpdateAsyncStateObject>(),
  orderCurrency: "",
};

const cachedState: CartState = LocalStorage.loadSerialized(storeKey);

export interface GetDraftForAccount {
  accountNumber: string;
  update: boolean;
}

// get draft for provided account number
export const getOrderDraftForAccount = createAsyncThunk(
  storeKey + "/getOrderDraftAccountNumber",
  async (args: GetDraftForAccount, thunkApi) => {
    const orderEntryServiceCall = new OrderEntryService();

    try {
      let response = await orderEntryServiceCall
        .OrderDraftForAccountNumber(args.accountNumber);
      // @ToDo: refactor P&A integration
      response.items.forEach((item) => {
        //item.priceLastRefreshed = item.createdOn;
        //item.inventoryLastRefreshed = item.createdOn;
        if (updateItem(item)) {
          const args: updatePriceAndAvailabilityInput = {
            materialNumber: item?.materialNumber,
            quantity: Number(item?.quantity),
            salesOrg: item?.salesOrg,
            division: item?.pricingDivision,
            quoteNumber: item.referenceQuoteNumber,
            customerAccountNumber: response.accountNumber,
            orderItemId: item.orderItemId,
            orderId: response.orderId,
          };
          fetchPriceAndAvailabilityForOrderItem(args);
        }
      });
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

// save address in db
export const saveOrderAddress = createAsyncThunk(
  storeKey + "/saveOrderAddress",
  async (args: UpdateOrderAddress, thunkApi) => {
    const orderEntryServiceCall = new OrderEntryService();
    return orderEntryServiceCall.updateOrderEntryAddress(
      args.orderId,
      args.orderAddressId,
      args.address
    );
  }
);

// validate address order address
export const validateOrderAddress = createAsyncThunk(
  storeKey + "/validateOrderAddress",
  async (args: ValidateAddress, thunkApi) => {
    const service = new DataQuality.Service();
    const query: DataQuality.Input.ValidateAddress = {
      line1: args.address.address1,
      line2: args.address.address2,
      city: args.address.city,
      region: args.address.region,
      postalcode: args.address.postal,
      country: args.address.country,
    };
    return service.validateAddress(query);
  }
);

// save item in db
export const updateOrderItem = createAsyncThunk(
  storeKey + "/updateOrderItem ",
  async (args: UpdateOrderItemInterface, thunkApi) => {
    const orderEntryServiceCall = new OrderEntryService();
    let tries = 0;
    let response;
    if (args.orderId && checkRequiredItemFieldNotEmpty(args.item)) {
      while (true) {
        try {
          response = await orderEntryServiceCall.updateOrderItem(
            args.orderId,
            args.item.orderItemId,
            args.item
          );
          return response;
        } catch (error: any) {
          //it will call the API 3 times before showing the error message
          if (tries === 3) {
            let date = new Date();
            let time = date.getTime();
            let id = time.toString();
            Notify({
              id: id,
              time: time,
              type: "cart-error",
              message: "Error while updating cart database",
              title: "Cart Error",
              level: NotificationLevel.DANGER,
              allowDismiss: true,
            });
            setTimeout(() => {
              DeNotify(id);
            }, 2000);
            return thunkApi.rejectWithValue(
              error?.response?.data?.detail || error?.message || error
            );
          }
        }
        tries++;
      }
    }
  }
);

// validate all order items before checkout
export const validateOrderItems = createAsyncThunk(
  storeKey + "/validateOrderItems ",
  async (args: boolean, thunkApi) => {
    const orderEntryServiceCall = new OrderEntryService();
    const orderId = selectCartOrder()?.orderId;
    if (orderId) {
      return orderEntryServiceCall.validateOrderItem(orderId).then((result) => {
          const accountNumber = selectAccountNumber();
          accountNumber !== undefined &&
            fetchOrderDraftForAccount(accountNumber, true);
        return result;
      });
    } else {
      throw new Error("Invalid call to validateOrderItems: order id not found");
    }
  }
);

// validate  order  before submission
export const validateOrder = createAsyncThunk(
  storeKey + "/validateOrder ",
  async (_, thunkApi) => {
    const orderEntryServiceCall = new OrderEntryService();
    const state: CartState = thunkApi.getState() as CartState;
    const orderId = state.orderDraft.data?.orderId;
    if (orderId) {
      return orderEntryServiceCall.validateWholeOrder(orderId);
    } else {
      throw new Error("Invalid call to validateOrder: order id not found");
    }
  }
);

// update  price and availability for item
export interface updatePriceAndAvailabilityInput
  extends SapRfcIntegrationApi.Materials.Input.PriceAndAvailability {
  orderId: string;
  orderItemId: string;
}
export const updatePriceAndAvailability = createAsyncThunk(
  storeKey + "/updatePriceAndAvailability",
  async (args: updatePriceAndAvailabilityInput, thunkApi) => {
    const materialDataService = new SapRfcIntegrationApi.Materials.Service();
    const payload: SapRfcIntegrationApi.Materials.Input.PriceAndAvailability = {
      materialNumber: args.materialNumber,
      salesOrg: args.salesOrg,
      division: args.division,
      quantity: args.quantity,
      customerAccountNumber: args.customerAccountNumber,
      quoteNumber: args.quoteNumber,
    };

    let tries = 0;
    const maxTries = 3;
    let response;
    while (true) {
      try {
        response = await materialDataService.priceAndAvailability(payload);
        return response;
      } catch (error: any) {
        if (tries === maxTries) {
          return thunkApi.rejectWithValue(
            error?.response?.data?.detail || error?.message || error
          );
        }
      }
      tries++;
    }
  }
);

export const cartSlice = createSlice({
  name: storeKey,
  initialState: cachedState || initialState,
  reducers: {
    //Update the store for order currency
    setOrderCurrency: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        orderCurrency: action.payload,
      };
    },

    // deletes order item and cleans up any residual associations
    deleteOrderItem: (state, action: PayloadAction<string>) => {
      let draftItems = state.orderDraft.data?.items.filter(
        (i) => i.orderItemId !== action.payload
      );

      if (
        draftItems &&
        state.orderDraft?.data?.items &&
        draftItems.length < state.orderDraft.data.items.length
      ) {
        state.orderDraft.data.items = draftItems;
        // update order details if no products left in brand
        let draftBrandSet = new Set<string>();
        draftItems.forEach((d) => draftBrandSet.add(d.orderDetailId));
        let detailsBrandSet = new Set<string>();
        state.orderDraft.data.details.forEach((d) =>
          detailsBrandSet.add(d.orderDetailId)
        );
        // check length of details against length of set
        if (detailsBrandSet.size !== draftBrandSet.size) {
          // remove details where id is not in set
          const filteredDetails = state.orderDraft.data.details.filter((d) =>
            draftBrandSet.has(d.orderDetailId)
          );
          state.orderDraft.data.details = filteredDetails;
        }
      }
      // item update queue
      let updateItems = state.itemUpdateQueue?.filter(
        (i) => i.data?.orderItemId === action.payload
      );
      if (
        updateItems &&
        state.itemUpdateQueue &&
        updateItems.length < state.itemUpdateQueue.length
      ) {
        state.itemUpdateQueue = updateItems;
      }
      // price updates
      let priceItems = state.itemData?.filter(
        (i) => i.orderItemId === action.payload
      );
      if (
        priceItems &&
        state.itemData &&
        priceItems?.length < state.itemData.length
      ) {
        state.itemData = priceItems;
      }
    },

    resetCart: (state) => {
      Object.assign(state, initialState);
    },

    updateCart: (state, action: PayloadAction<Partial<CartState>>) => {
      state = Object.assign({}, state, action.payload);
    },
    updateOrderDraft: (
      state,
      action: PayloadAction<Partial<HubbellConnectOrder>>
    ) => {
      const orderDraftData: any = action.payload;
      state.orderDraft.data = orderDraftData;
      state.orderDraft.status = AsyncStatus.LOADING;
      state.orderDraft.error = undefined;
    },
    addNewItemToDraft: (state, action: PayloadAction<Item>) => {
      state.orderDraft.data?.items.push(action.payload);
    },
    updateShippingAddress: (state, action: PayloadAction<Partial<Address>>) => {
      const addressIndex = state.orderDraft.data?.orderAddresses
        ? state.orderDraft.data.orderAddresses.findIndex(
            (item) =>
              item.orderAddressType?.name === OrderAddressTypeName.ShipTo
          )
        : -1;
      const addresses = state.orderDraft.data?.orderAddresses.map((a: any) => {
        if (a.orderAddressType?.name !== OrderAddressTypeName.ShipTo) {
          return a;
        } else return action.payload;
      });
      if (addresses && addressIndex !== -1)
        state.orderDraft.data!.orderAddresses = addresses;
      else {
        state.orderDraft.data!.orderAddresses.push(action.payload as Address);
        console.error(storeKey + "/updateAddress: shipping address not found.");
      }
    },
    updateDraftItem: (
      state,
      action: PayloadAction<{
        item: Partial<Omit<Item, "orderItemId">>;
        orderItemId: string;
      }>
    ) => {
      const accountNumber = state.orderDraft?.data?.accountNumber || "";
      const itemIndex = state.orderDraft.data
        ? state.orderDraft?.data?.items.findIndex(
            (item) => item.orderItemId === action.payload.orderItemId
          )
        : -1;

      const item =
        itemIndex >= 0
          ? { ...state.orderDraft?.data?.items[itemIndex] }
          : undefined;
      if (item !== undefined) {
        let clonedItem = cloneDeep(item);
        let updatedItem = Object.assign(
          {},
          clonedItem,
          action.payload.item
        ) as Item;
        if (validateOrderItem(updatedItem, "quantity") !== true) {
          updatedItem.extendedPrice = "0";
        }
        let updatedData: ItemUpdateAsyncStateObject = {
          data: updatedItem,
          status: AsyncStatus.IDLE,
          error: undefined,
          time: new Date().toISOString(),
        };
        state.orderDraft.data!.items = state.orderDraft.data!.items!.toSpliced(
          itemIndex,
          1,
          updatedItem
        );
        if (
          validateOrderItem(
            updatedItem,
            undefined,
            undefined,
            accountNumber
          ) === true
        ) {
          state.itemUpdateQueue = state.itemUpdateQueue?.length
            ? [...state?.itemUpdateQueue, updatedData]
            : [updatedData];
        }
      }
    },

    updateDraftOrderDetails: (state, action: PayloadAction<Detail[]>) => {
      state.orderDraft.error = undefined;
      state.orderDraft.data!.details = action.payload;
    },

    updateDocumentURL: (
      state,
      action: PayloadAction<DocumentURLUpdateInterface>
    ) => {
      state.orderDraft.data?.details.forEach((item) => {
        if (item.orderDetailId === action.payload.orderDetailId) {
          item.poAttachmentUrl = action.payload.documentURL;
        }
      });
    },
    resetDocumentURL: (state, action: PayloadAction<string>) => {
      state.orderDraft.data?.details.forEach((item) => {
        if (item.orderDetailId === action.payload) {
          item.poAttachmentUrl = "";
        }
      });
    },
    setCartValidityStatus: (
      state,
      action: PayloadAction<Partial<CartState["checkoutValidity"]>>
    ) => {
      state.checkoutValidity = Object.assign(
        {},
        state.checkoutValidity,
        action.payload
      );
    },

    setOrderDetails: (state, action: PayloadAction<Detail[]>) => {
      if (action && action.payload)
        state.orderDraft.data!.details = action.payload;
    },
    resetCartValidation: (state) => {
      state.cartValidation = initialState.cartValidation;
    },
  },
  extraReducers(builder) {
    // get order draft data for an account number thunk reducers
    builder.addCase(getOrderDraftForAccount.pending, (state, { meta }) => {
      state.orderDraft.status = meta.arg.update
        ? AsyncStatus.UPDATING
        : AsyncStatus.LOADING;
      state.orderDraft.error = undefined;
      state.loading = meta.arg.update ? false : true;
      state.checkoutValidity = {
        items: FormStatus.INVALID,
        shippingDetails: FormStatus.INVALID,
        orderDetails: FormStatus.INVALID,
      };
      state.itemData = [];
    });
    builder.addCase(
      getOrderDraftForAccount.fulfilled,
      (state, { payload }: { payload: HubbellConnectOrder }) => {
        state.orderDraft.status = AsyncStatus.SUCCEEDED;
        state.orderDraft.data = payload;
        state.loading = false;
        let validity = { ...state.checkoutValidity };
        if (payload?.items?.length === 0) {
          validity = {
            items: FormStatus.INVALID,
            shippingDetails: FormStatus.INVALID,
            orderDetails: FormStatus.INVALID,
          };
        } else {
          validity.items = FormStatus.VALID;
        }
        state.checkoutValidity = validity;
      }
    );
    builder.addCase(getOrderDraftForAccount.rejected, (state, thunkApi) => {
      state.orderDraft.status = AsyncStatus.FAILED;
      state.orderDraft.data = undefined;
      state.orderDraft.error =
        thunkApi.error.message ||
        "An error occurred while fetching order draft";
      console.error(thunkApi.error, thunkApi.meta.arg);
      state.checkoutValidity = {
        items: FormStatus.INVALID,
        shippingDetails: FormStatus.INVALID,
        orderDetails: FormStatus.INVALID,
      };
    });
    // update item price and availability data thunk reducers
    builder.addCase(updatePriceAndAvailability.pending, (state, { meta }) => {
      const itemData: updatePriceAndAvailabilityInput = meta.arg;

      const updatedItemData: ItemPriceUpdateAsyncStateObject = {
        orderItemId: itemData.orderItemId,
        status: AsyncStatus.LOADING,
        updateType: HubbellOrderItemUpdateType.priceInventory,
        error: undefined,
        data: undefined,
      };
      let index = state.itemData
        ? state.itemData?.findIndex(
            (i) => i.orderItemId === meta.arg.orderItemId
          )
        : -1;
      let _new: any = [];

      if (index !== -1 && state.itemData) {
        _new = state.itemData.toSpliced(index, 1, updatedItemData);
      } else {
        if (state.itemData && state.itemData.length > 0) {
          _new = [...state.itemData, updatedItemData];
        } else {
          _new = [updatedItemData];
        }
      }
      state.itemData = _new;
    });
    builder.addCase(
      updatePriceAndAvailability.fulfilled,
      (state, { meta, payload }) => {
        const materialResult:
          | SapRfcIntegrationApi.Materials.MaterialResult
          | undefined = payload.materialResults?.length
          ? payload?.materialResults[0]
          : undefined;
        const validationResults = payload?.validationResults;
        const serviceErrorsReturned = payload?.serviceErrorsReturned;
        const skippedMaterials = payload?.skippedMaterials;
        const orderItemId: string = meta.arg.orderItemId;
        const accountNumber = state.orderDraft?.data?.accountNumber;
        let updatedQueueItem: ItemUpdateAsyncStateObject = {
          ...AsyncStateObjectInitialStateFactory(),
          time: new Date().toISOString(),
        };

        const draftItemIndex = state.orderDraft.data
          ? state.orderDraft.data.items.findIndex(
              (item: Item) => item.orderItemId === orderItemId
            )
          : -1;
        const itemDataIndex = state.itemData
          ? state.itemData.findIndex((item) => {
              let test = item.orderItemId === orderItemId;
              return test;
            })
          : -1;
        if (!materialResult) {
          console.error("Material result not found");
        }
        if (draftItemIndex === undefined || draftItemIndex < 0) {
          console.error("Item draft index not found", draftItemIndex);
        }
        if (itemDataIndex === undefined || itemDataIndex < 0) {
          console.error("Item data index not found", itemDataIndex);
        }
        let updatedQueueItemData =
          draftItemIndex >= 0
            ? state.orderDraft!.data!.items[draftItemIndex]
            : undefined;
        let itemData =
          itemDataIndex >= 0 && state.itemData
            ? state.itemData[itemDataIndex]
            : undefined;

        if (
          updatedQueueItemData &&
          itemData &&
          (materialResult ||
            validationResults ||
            skippedMaterials ||
            serviceErrorsReturned)
        ) {
          updatedQueueItem.data = Object.assign({}, updatedQueueItemData);

          let qty: number = !isNaN(Number(updatedQueueItem.data?.quantity))
            ? Number(updatedQueueItem.data.quantity)
            : 0;
          updatedQueueItem.data.extendedPrice = "0";
          if (materialResult) {
            updatedQueueItem.data.priceUnit = !isNaN(
              Number(materialResult.priceUnit)
            )
              ? Number(materialResult.priceUnit)
              : 1;
            updatedQueueItem.data.unitPrice = materialResult.price || "0";
            updatedQueueItem.data.extendedPrice = !isNaN(
              Number(materialResult.price)
            )
              ? calculateOrderItemExtendedPrice(
                  Number(materialResult.price),
                  updatedQueueItem.data.priceUnit,
                  qty
                )
              : "0"; // not getting extended price from p&a

            updatedQueueItem.data.materialDescription =
              materialResult?.description
                ? materialResult.description
                : updatedQueueItemData.materialDescription;
            updatedQueueItem.data.minimumOrderQty =
              materialResult.minimumOrderQty || "0";
            updatedQueueItem.data.baseUnitOfMeasure =
              materialResult.baseUnitOfMeasure || "0";
            updatedQueueItem.data.standardPackageQty =
              materialResult.standardPackageQty || "0";
            updatedQueueItem.data.palletQty = !isNaN(
              Number(materialResult.palletQty)
            )
              ? Number(materialResult.palletQty)
              : 0;
            updatedQueueItem.data.caseQty = !isNaN(
              Number(materialResult.caseQty)
            )
              ? Number(materialResult.caseQty)
              : 0;
            updatedQueueItem.data.cartonQty = materialResult.cartonQty || "0";
            updatedQueueItem.data.grossWeight = !isNaN(
              Number(materialResult.grossWeight)
            )
              ? Number(materialResult.grossWeight)
              : 0;
            updatedQueueItem.data.inventory = materialResult.inventory;
            updatedQueueItem.data.currency = materialResult.currency || "USD";
          }
          // these get refreshed no matter what
          // set the item plant and description if not already
          const plantSetting = getSelectedPlant(updatedQueueItem.data);
          updatedQueueItem.data.plantNumber = plantSetting?.plant || "";
          updatedQueueItem.data.plantDescription =
            plantSetting?.plantDescription || "";
          updatedQueueItem.data.priceLastRefreshed = new Date().toISOString();
          updatedQueueItem.data.inventoryLastRefreshed =
            new Date().toISOString();
          updatedQueueItem.data.validationResults = validationResults;
          updatedQueueItem.data.serviceErrorsReturned = serviceErrorsReturned;
          updatedQueueItem.data.skippedMaterials = skippedMaterials;
          // add updated item to update queue
          if (updatedQueueItem?.data && state.orderDraft.data) {
            let valid = validateOrderItem(
              updatedQueueItem.data,
              undefined,
              undefined,
              accountNumber
            );

            state.orderDraft.data.items = state.orderDraft.data.items.toSpliced(
              draftItemIndex,
              1,
              updatedQueueItem.data
            );

            if (valid === true) {
              state.itemUpdateQueue =
                state.itemUpdateQueue && state.itemUpdateQueue.length > 0
                  ? [...state?.itemUpdateQueue, updatedQueueItem]
                  : [updatedQueueItem];
            }
          }
          // clear itemData
          if (state.itemData && state.itemData[itemDataIndex]) {
            state.itemData = state.itemData.toSpliced(itemDataIndex, 1);
          }
        }
      }
    );
    builder.addCase(updatePriceAndAvailability.rejected, (state, thunkApi) => {
      state.itemData?.forEach((item: any) => {
        if (thunkApi.meta.arg.orderItemId === item.orderItemId) {
          item.status = AsyncStatus.FAILED;
          item.data = undefined;
          item.error =
            thunkApi.error.message ||
            "An error occurred while retrieving Price and Availability";
        }
      });
      state.orderDraft?.data?.items?.forEach((item: any) => {
        if (thunkApi.meta.arg.orderItemId === item.orderItemId) {
          item.serviceErrorsReturned = [
            {
              text: `An error occurred while retrieving Price and Availability.${" "}${
                thunkApi.payload
              }.`,
            },
          ];
        }
      });
      console.error(thunkApi.error, thunkApi.meta.arg);
    });

    // save orders items to db thunk reducers
    builder.addCase(updateOrderItem.pending, (state, { meta }) => {
      let index: any = state.itemUpdateQueue
        ? state.itemUpdateQueue.findIndex(
            (item) =>
              item.data?.orderItemId === meta.arg.item.orderItemId &&
              meta.arg.time === item.time
          )
        : -1;
      if (index !== -1 && state.itemUpdateQueue) {
        state.itemUpdateQueue[index].status = AsyncStatus.LOADING;
        state.itemUpdateQueue[index].error = undefined;
      }
    });
    builder.addCase(updateOrderItem.fulfilled, (state, { meta, payload }) => {
      let index: any = state.itemUpdateQueue
        ? state.itemUpdateQueue.findIndex(
            (item) =>
              item.data?.orderItemId === meta.arg.item.orderItemId &&
              meta.arg.time === item.time
          )
        : -1;
      if (index !== -1 && state.itemUpdateQueue) {
        //state.itemUpdateQueue = state.itemUpdateQueue.splice(index, 1);
        state.itemUpdateQueue[index].status = AsyncStatus.SUCCEEDED;
        state.itemUpdateQueue[index].error = undefined;
      }
    });
    builder.addCase(updateOrderItem.rejected, (state, thunkApi) => {
      let index = state.itemUpdateQueue
        ? state.itemUpdateQueue?.findIndex(
            (item) =>
              item.data?.orderItemId === thunkApi.meta.arg.item.orderItemId &&
              thunkApi.meta.arg.time === item.time
          )
        : -1;
      if (index !== -1 && state.itemUpdateQueue) {
        state.itemUpdateQueue[index].status = AsyncStatus.FAILED;
        state.itemUpdateQueue[index].error = thunkApi.error.message;
      }
    });

    // save related order address in db thunk reducers
    builder.addCase(saveOrderAddress.pending, (state, { meta }) => {
      const addressId = meta.arg.orderAddressId || "";
      let index = -1;
      if (state.addressUpdate.length > 0) {
        index = state.addressUpdate.findIndex((i) => i.addressId === addressId);
      }
      const entry: AddressUpdateAsyncStateObject = {
        addressId: addressId,
        status: AsyncStatus.LOADING,
        data: undefined,
        error: undefined,
      };
      if (index !== -1) {
        state.addressUpdate = state.addressUpdate.toSpliced(index, 1, entry);
      } else {
        state.addressUpdate = [entry];
      }
    });
    builder.addCase(saveOrderAddress.fulfilled, (state, { payload, meta }) => {
      //update async state object array
      const addressId = meta.arg.orderAddressId || "";
      const index = state.addressUpdate.findIndex(
        (i) => i.addressId === addressId
      );
      if (index !== -1) {
        let entry = Object.assign({}, state.addressUpdate[index]);
        entry.data = payload;
        entry.status = AsyncStatus.SUCCEEDED;
        state.addressUpdate = state.addressUpdate.toSpliced(index, 1, entry);

        // update local draft address data
        const addressIndex = state.orderDraft.data?.orderAddresses
          ? state.orderDraft.data.orderAddresses.findIndex(
              (item) => item.orderAddressId === payload.orderAddressId
            )
          : -1;
        const addresses = state.orderDraft.data?.orderAddresses.map((a) => {
          if (a.orderAddressId !== payload.orderAddressId) {
            return a;
          } else return payload;
        });
        if (addresses && addressIndex !== -1) {
          state.orderDraft.data!.orderAddresses = addresses;
        } else {
          state.orderDraft.data!.orderAddresses = [
            ...state.orderDraft.data!.orderAddresses,
            payload,
          ];
        }
      } else {
        console.error("Address update index not found", index);
      }
    });
    builder.addCase(saveOrderAddress.rejected, (state, thunkApi) => {
      const addressId = thunkApi.meta.arg.orderAddressId || "";
      const index = state.addressUpdate.findIndex(
        (i) => i.addressId === addressId
      );
      if (index !== -1) {
        let entry = Object.assign({}, state.addressUpdate[index]);
        entry.error = thunkApi.error.message;
        entry.status = AsyncStatus.FAILED;
        state.addressUpdate = state.addressUpdate.toSpliced(index, 1, entry);
      } else {
        console.error("Address validation index not found", index);
      }
    });

    // validate order items thunk reducers
    builder.addCase(validateOrderItems.pending, (state, { meta }) => {
      state.cartValidation = {
        status: AsyncStatus.LOADING,
        data: undefined,
        error: undefined,
      };
    });
    builder.addCase(
      validateOrderItems.fulfilled,
      (state, { meta, payload }) => {
        state.cartValidation = {
          status: AsyncStatus.SUCCEEDED,
          data: payload,
          error: undefined,
        };
      }
    );
    builder.addCase(validateOrderItems.rejected, (state, { meta, error }) => {
      state.cartValidation = {
        status: AsyncStatus.FAILED,
        data: undefined,
        error: error.message,
      };
    });

    // validate whole order thunk reducers
    builder.addCase(validateOrder.pending, (state, { meta }) => {
      state.cartValidation = {
        status: AsyncStatus.LOADING,
        data: undefined,
        error: undefined,
      };
    });
    builder.addCase(validateOrder.fulfilled, (state, { meta, payload }) => {
      state.cartValidation = {
        status: AsyncStatus.SUCCEEDED,
        data: payload,
        error: undefined,
      };
    });
    builder.addCase(validateOrder.rejected, (state, { meta, error }) => {
      state.cartValidation = {
        status: AsyncStatus.FAILED,
        data: undefined,
        error: error.message,
      };
    });

    // validate order address thunk reducers
    builder.addCase(validateOrderAddress.pending, (state, { meta }) => {
      const addressId = meta.arg.orderAddressId;
      const detailId = meta.arg?.orderDetailId;
      let index = -1;
      if (state.addressValidation.length > 0) {
        index = state.addressValidation.findIndex((i) =>
          detailId ? i.orderDetailId === detailId : i.addressId === addressId
        );
      }
      const entry: AddressValidateAsyncStateObject = {
        addressId: addressId,
        orderDetailId: detailId,
        status: AsyncStatus.LOADING,
        data: undefined,
        error: undefined,
      };
      if (index !== -1) {
        state.addressValidation = state.addressValidation.toSpliced(
          index,
          1,
          entry
        );
      } else {
        state.addressValidation = [...state.addressValidation, entry];
      }
    });
    builder.addCase(
      validateOrderAddress.fulfilled,
      (state, { meta, payload }) => {
        const addressId = meta.arg.orderAddressId;
        const detailId = meta.arg?.orderDetailId;
        const index = state.addressValidation.findIndex((i) =>
          detailId ? i.orderDetailId === detailId : i.addressId === addressId
        );
        if (index !== -1) {
          let entry = Object.assign({}, state.addressValidation[index]);
          entry.data = payload;
          entry.status = AsyncStatus.SUCCEEDED;
          state.addressValidation = state.addressValidation.toSpliced(
            index,
            1,
            entry
          );
        } else {
          console.error("Address validation index not found", index);
        }
      }
    );
    builder.addCase(validateOrderAddress.rejected, (state, thunkApi) => {
      const addressId = thunkApi.meta.arg.orderAddressId;
      const detailId = thunkApi.meta.arg?.orderDetailId;
      const index = state.addressValidation.findIndex((i) =>
        detailId ? i.orderDetailId === detailId : i.addressId === addressId
      );
      if (index !== -1) {
        let entry = Object.assign({}, state.addressValidation[index]);
        entry.error = thunkApi.error.message;
        entry.status = AsyncStatus.FAILED;
        state.addressValidation = state.addressValidation.toSpliced(
          index,
          1,
          entry
        );
      } else {
        console.error("Address validation index not found", index);
      }
    });
  },
});
