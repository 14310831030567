import { LocalStorage } from "store/localStorage";
import { ProductImage } from "../models/Product";

export const set = (key: string, value?: string) =>
  LocalStorage.save(key, value);

export const get = (key: string) => LocalStorage.load(key);

export const clear = () => LocalStorage.clear();

const imageInfoPrefix = "hubbell.image.{0}";

export const ImagePath = (productNumber: string): ProductImage | null => {
  let imageInfo = get(imageInfoPrefix.replace("{0}", productNumber));
  return imageInfo ? JSON.parse(imageInfo) : imageInfo;
};

export const SetImagePath = (
  productNumber: string,
  productImage: ProductImage
) => {
  set(
    imageInfoPrefix.replace("{0}", productNumber),
    JSON.stringify(productImage)
  );
};

const moduleTablePrefix = "hubbell.table.{0}";
export interface PersistedTableSettings {
  pageSize: number;
  pageIndex: number;
  filters?: Array<{ id: string; value: any }>;
}

export const TableSettings = (
  tableId: string
): PersistedTableSettings | null => {
  let settings = get(moduleTablePrefix.replace("{0}", tableId));
  return settings ? JSON.parse(settings) : null;
};

export const SetTableSettings = (
  tableId: string,
  settings: PersistedTableSettings
) => {
  let settingsToPersist = { ...settings };
  if (settingsToPersist && !settingsToPersist.filters) {
    let persistedSettings = TableSettings(tableId);
    settingsToPersist.filters =
      persistedSettings && persistedSettings.filters
        ? persistedSettings.filters
        : [];
  }
  set(
    moduleTablePrefix.replace("{0}", tableId),
    JSON.stringify(settingsToPersist)
  );
};
