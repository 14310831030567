import { faShoppingCart } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useCart } from "features/OrderEntry/store/useCart";
import "./style.scss";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { AsyncStatus } from "store/AsyncStateObject";

const CartIcon: React.FC<{}> = () => {
  const { orderDraft } = useCart();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (orderDraft.status === AsyncStatus.LOADING) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [orderDraft]);
  return (
    <Button
      variant="clear"
      className="cartIcon"
      onClick={() => navigate("/order-entry")}
      title="View Cart"
    >
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={faShoppingCart} fixedWidth className="icon" />
        {!loading && (
          <span className="fa-layers-text itemCount">
            {orderDraft.data?.items.length || 0}
          </span>
        )}
      </span>
    </Button>
  );
};
export default CartIcon;
