import { FC } from "react";
import useDownloadManager from "store/system/downloadManager/useDownloadManager";
import DownloadPollingComponent from "./DownloadPollingComponent";
import DownloadToasts from "./DownloadToasts";

const DownloadManager: FC<{}> = (): JSX.Element => {
  const { downloadList } = useDownloadManager();
  return (
    <>
      {downloadList.length > 0 &&
        downloadList.map((item) => {
          if (item.Status === "Processing")
            return (
              <>
                <DownloadPollingComponent
                  downloadID={item.Id}
                  status={item.Status}
                />
              </>
            );
          else if (item.DownloadUrl !== null && item.DownloadUrl !== undefined)
            return (
              <DownloadToasts
                downloadID={item.Id}
                downloadURL={item.DownloadUrl}
              />
            );
          else return <></>;
        })}
    </>
  );
};
export default DownloadManager;
