import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
//import { debounce } from "lodash";
import SapRfcIntegrationApi from "services/SapRfcIntegrationApi";
//import store, { registerReducer, StateSelectorFactory } from "store";
import {
  AsyncStateObjectInitialStateFactory,
  AsyncStatus,
} from "store/AsyncStateObject";
import { LocalStorage } from "store/localStorage";
import OrderSearchState, {
  initialOrderSearchQueryState,
} from "./models/OrderSearchState";
import { ControlledTableState } from "features/common/components/Table/models";

export const storeKey = "orderSearch";

export const initialState: OrderSearchState = {
  query: initialOrderSearchQueryState,
  searchResult: AsyncStateObjectInitialStateFactory(),
  tableState: undefined,
};
const cachedState: OrderSearchState = LocalStorage.loadSerialized(storeKey);

export const searchOrders = createAsyncThunk(
  storeKey + "/searchOrders",
  async (query: SapRfcIntegrationApi.SalesOrder.Input.SearchAll, thunkApi) => {
    const salesOrderService = new SapRfcIntegrationApi.SalesOrder.Service();
    const abortController = new AbortController();
    const abortSignal = abortController.signal;
    thunkApi.signal.addEventListener("abort", () => {
      abortController.abort();
    });
    let data;
    await salesOrderService.searchAll(query, abortSignal).then((r) => {
      data = r;
    });
    return data;
  }
);

export const orderSearchSlice = createSlice({
  name: storeKey,
  initialState: cachedState || initialState,
  reducers: {
    setQuery: (
      state,
      action: PayloadAction<Partial<OrderSearchState["query"]>>
    ) => {
      state.query = Object.assign(state.query, action.payload);
    },
    reset: (
      state,
      action: PayloadAction<Partial<OrderSearchState> | undefined>
    ) => {
      return action.payload
        ? Object.assign({}, initialState, action.payload)
        : initialState;
    },
    setSearchResult: (
      state,
      action: PayloadAction<Partial<OrderSearchState["searchResult"]>>
    ) => {
      state.searchResult = Object.assign(state.searchResult, action.payload);
    },
    setTableState: (
      state,
      action: PayloadAction<ControlledTableState | undefined>
    ) => {
      state.tableState = action.payload;
    },
  },
  extraReducers(builder) {
    // THUNK
    builder.addCase(searchOrders.pending, (state) => {
      state.searchResult.status = AsyncStatus.LOADING;
      state.searchResult.error = undefined;
      state.searchResult.data = undefined;
    });
    builder.addCase(searchOrders.fulfilled, (state, { payload }) => {
      state.searchResult.status = AsyncStatus.SUCCEEDED;
      state.searchResult.error = undefined;
      state.searchResult.data = payload;
    });
    builder.addCase(searchOrders.rejected, (state, { error }) => {
      state.searchResult.status = AsyncStatus.FAILED;
      state.searchResult.error =
        error.message ||
        "An unknown error occurred while searching for orders.";
      console.error("searchOrders", error);
    });
  },
});

/*
export function initModuleState() {
  registerReducer(storeKey, featureSlice.reducer);
}

export const getState = StateSelectorFactory(initialState, storeKey);

export const subscribe = (f: Function) => {
  let lastState = getState();
  return store.subscribe(
    () => lastState !== getState() && f((lastState = getState()))
  );
};

// cached state changes to local storage
store.subscribe(
  debounce(() => {
    LocalStorage.saveSerialized(storeKey, getState());
  }, 800)
);
*/
