import { AbstractService } from './AbstractService';

export interface SupportFAQ {
  question: string;
  answer: string;
}

export type SupportVideo = Record<string, any>;
export interface SupportContent {
  title?: string;
  summary?: string;
  videos?: SupportVideo[];
  faq?: SupportFAQ[];
}

export class SupportService extends AbstractService {
  /**
   *
   * @param {AbortSignal|undefined} signal
   * @returns {Promise<Output.SupportContent>} JSON formatted content
   */
  async getSupportData(signal?: AbortSignal): Promise<SupportContent> {
    const url = '/HubbellConnect/support.json';
    const r = await this.connection.get(url, {
      baseURL: this.baseUrl,
      signal,
    });
    return r.data;
  }
}
