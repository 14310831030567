import { Component, ErrorInfo, ReactNode } from "react";
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {ApplicationInsights, SeverityLevel} from '@microsoft/applicationinsights-web';

const reactPlugin = new ReactPlugin()
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
})
ai.loadAppInsights()


export const {appInsights} = ai

export interface ErrorBoundaryProps {
  children: ReactNode;
  message?: string | ReactNode;
}

export interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
    this.setState({hasError: true})
    ai.appInsights.trackException({
      error,
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties: {...errorInfo},
    })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          {this.props.message ? (
            <>{this.props.message}</>
          ) : (
            <>Sorry.. there was an error rendering this content!</>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary
