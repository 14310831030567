import { AbstractService } from './AbstractService';
export enum ServiceMessageLevels {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export interface ServiceMessage {
  level: ServiceMessageLevels;
  title: string;
  message: string;
  type?: string;
}

export class ServiceMessageService extends AbstractService {
  /**
   *
   * @param {AbortSignal|undefined} signal
   * @returns {Promise<ServiceMessage[]>} JSON formatted content
   */
  async getServiceMessages(signal?: AbortSignal): Promise<ServiceMessage[]> {
    const url = '/HubbellConnect/serviceMessages.json';
    const r = await this.connection.get(url, {
      baseURL: this.baseUrl,
      signal,
    });

    return r.data;
  }
}
