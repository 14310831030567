import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
//import { debounce } from "lodash";
import SapRfcIntegrationApi from "services/SapRfcIntegrationApi";
//import store, { registerReducer, StateSelectorFactory } from "store";
import {
  AsyncStateObjectInitialStateFactory,
  AsyncStatus,
} from "store/AsyncStateObject";
import { LocalStorage } from "store/localStorage";

import { ControlledTableState } from "features/common/components/Table/models";
import QuoteSearchState, {
  initialQuoteSearchQueryState,
} from "./models/QuoteSearchState";

export const storeKey = "quoteSearch";

export const initialState: QuoteSearchState = {
  query: initialQuoteSearchQueryState,
  searchResult: AsyncStateObjectInitialStateFactory(),
  tableState: undefined,
};
const cachedState: QuoteSearchState = LocalStorage.loadSerialized(storeKey);

export const searchQuotes = createAsyncThunk(
  storeKey + "/searchQuotes",
  async (query: SapRfcIntegrationApi.SalesQuote.Input.Search, thunkApi) => {
    const salesQuoteService = new SapRfcIntegrationApi.SalesQuote.Service();
    const abortController = new AbortController();
    const abortSignal = abortController.signal;
    thunkApi.signal.addEventListener("abort", () => {
      abortController.abort();
    });
    let data;
    await salesQuoteService.search(query, abortSignal).then((r) => {
      data = r;
    });
    return data;
  }
);

export const quoteSearchSlice = createSlice({
  name: storeKey,
  initialState: cachedState || initialState,
  reducers: {
    setQuery: (
      state,
      action: PayloadAction<Partial<QuoteSearchState["query"]>>
    ) => {
      state.query = Object.assign(state.query, action.payload);
    },
    reset: (
      state,
      action: PayloadAction<Partial<QuoteSearchState> | undefined>
    ) => {
      return action.payload
        ? Object.assign({}, initialState, action.payload)
        : initialState;
    },
    setSearchResult: (
      state,
      action: PayloadAction<Partial<QuoteSearchState["searchResult"]>>
    ) => {
      state.searchResult = Object.assign(state.searchResult, action.payload);
    },
    setTableState: (
      state,
      action: PayloadAction<ControlledTableState | undefined>
    ) => {
      state.tableState = action.payload;
    },
  },
  extraReducers(builder) {
    // THUNK
    builder.addCase(searchQuotes.pending, (state) => {
      state.searchResult.status = AsyncStatus.LOADING;
      state.searchResult.error = undefined;
      state.searchResult.data = undefined;
    });
    builder.addCase(searchQuotes.fulfilled, (state, { payload }) => {
      state.searchResult.status = AsyncStatus.SUCCEEDED;
      state.searchResult.data = payload;
    });
    builder.addCase(searchQuotes.rejected, (state, { error }) => {
      state.searchResult.status = AsyncStatus.FAILED;
      state.searchResult.error =
        error.message ||
        "An unknown error occurred while searching for Quotes.";
      console.error("searchQuotes", error);
    });
  },
});

/*
export function initModuleState() {
  registerReducer(storeKey, featureSlice.reducer);
}

export const getState = StateSelectorFactory(initialState, storeKey);

export const subscribe = (f: Function) => {
  let lastState = getState();
  return store.subscribe(
    () => lastState !== getState() && f((lastState = getState()))
  );
};

// cached state changes to local storage
store.subscribe(
  debounce(() => {
    LocalStorage.saveSerialized(storeKey, getState());
  }, 800)
);
*/
