import { SalesOrgDivision } from "../models/UserAccount";

export const isValidEmail = (email: string, required?: boolean): boolean => {
  if (required && email?.trim() === "") return false;
  // eslint-disable-next-line
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

export const isValidEmailList = (
  emailList: string[],
  required?: boolean
): boolean => {
  if (required && emailList?.length === 0) return false;
  return emailList?.every((e) =>
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      e
    )
  );
};

export const isValidName = (name: string, required?: boolean): boolean => {
  if (required && name?.trim() === "") return false;
  return /^[a-zA-Z-'.& ]+$/g.test(name);
};

export const isPermittedBrand = (
  salesOrg: string,
  division: string,
  brands: SalesOrgDivision[]
) => {
  return (
    brands?.find(
      (i) =>
        i.salesOrg?.trim() === salesOrg?.trim() &&
        i.division?.trim() === division?.trim()
    ) !== undefined
  );
};
