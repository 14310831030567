import SapRfcIntegrationApi from "services/SapRfcIntegrationApi";

export interface ReplacementPartsQuery {
  accountNumber: string;
  catalogNumber: string;
  description: string;
  selectedMaterial:
    | SapRfcIntegrationApi.ReplacementParts.Output.SearchResult
    | undefined;
}

export const DefaultReplacementPartsSearchQuery: ReplacementPartsQuery = {
  accountNumber: "",
  catalogNumber: "",
  description: "",
  selectedMaterial: undefined,
};
