import { AbstractService } from "./AbstractService";

export namespace DataQuality {
  export const SAP_AddressValidation_Supported_Countries = ["US", "CA", "MX"];

  export namespace Input {
    export interface ValidateAddress {
      line1: string;
      line2: string;
      city: string;
      region: string;
      postalcode: string;
      country: string;
    }
  }
  export namespace Output {
    export interface ValidateAddressErrorResponse {
      subject: string;
      message: string;
    }
    export interface ValidateAddress {
      line1?: string;
      line2?: string;
      city?: string;
      region?: string;
      postalcode?: string;
      country?: string;
      errors?: ValidateAddressErrorResponse;
    }
  }
  export class Service extends AbstractService {
    /**
     *
     * @param {Input.ValidateAddress} query
     * @param {AbortSignal|undefined} abortSignal
     * @returns {Promise<Output.ValidateAddress>}
     */
    async validateAddress(
      query: Input.ValidateAddress,
      abortSignal?: AbortSignal
    ): Promise<Output.ValidateAddress> {
      const headers: { [key: string]: any } =
        await this.getAuthorizationHeaders();
      if (!this.addressSupportedByDataQuality(query)) {
        // if not supported, resolve with the provided address
        return Promise.resolve(query);
      }

      const url = `/AddressCleanse`;
      let result = await this.connection.post(url, query, {
        baseURL: this.baseUrl,
        headers: headers,
        signal: abortSignal,
      });
      return result.data;
    }

    addressSupportedByDataQuality = (args: Input.ValidateAddress): boolean => {
      const country = args.country;
      return SAP_AddressValidation_Supported_Countries.includes(country);
    };
  }
}
