import { RouteDefinition } from "../services/ApiServiceInterfaces";

export function isTopLevelFunctionModule(module: RouteDefinition) {
  let isTopModule = module?.redirectPath === "";
  if (!isTopModule && module.redirectPath) {
    let parts = module.redirectPath.split("/");
    isTopModule = parts.length < 3;
  }
  return isTopModule;
}

export function getTopLevelFunctionModules(
  allFunctionModules: Array<RouteDefinition>
) {
  let topModules: Array<RouteDefinition> = [];

  allFunctionModules?.forEach((m: RouteDefinition) => {
    let isTopModule = isTopLevelFunctionModule(m);
    if (isTopModule) {
      topModules.push(m);
    }
  });

  return topModules;
}

export function getTopLevelUserFunctionModules(
  topModules: Array<RouteDefinition>,
  allUserFunctionModules: Array<RouteDefinition>
) {
  let topUserModules: Array<RouteDefinition> = [];

  topModules.forEach((mod: RouteDefinition) => {
    let userMod = allUserFunctionModules.find(
      (m: any) => m.name.toLowerCase() === mod.name.toLowerCase()
    );
    if (userMod !== undefined) {
      topUserModules.push(userMod);
    } else {
      let userSubModule = allUserFunctionModules.find((m: any) =>
        m.redirectPath.includes(mod.redirectPath)
      );
      if (userSubModule !== undefined) {
        topUserModules.push(mod);
      }
    }
  });

  return topUserModules;
}
