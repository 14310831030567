import { Brand } from "models/UserAccount";
import { AbstractService } from "./AbstractService";

export namespace ReplacementParts {
  export namespace Input {
    export interface Search {
      accountNumber: string;
      catalogNumber: string;
      searchTerm: string;
    }
  }
  export namespace Output {
    export interface SearchResult {
      materialNumber: string;
      description: string;
      bomNumber: string;
      brand: Brand;
      plan: string;
    }
    export interface MaterialComponent {
      bomNumber: string;
      bomComponent: string;
      position: number;
      baseUnitOfMeasure: string;
      priceUnit: number;
      description: string;
    }
  }
  export class Service extends AbstractService {
    /**
     *
     * @description Replacement part search
     * @param {Input.Search} query
     * @param {AbortSignal|undefined} abortSignal
     * @returns
     */
    async search(
      query: Input.Search,
      abortSignal?: AbortSignal
    ): Promise<Output.SearchResult[]> {
      const headers = await this.getAuthorizationHeaders();
      let result = await this.connection
        .get("replacementparts/search", {
          baseURL: this.baseUrl,
          headers: headers,
          params: query,
          signal: abortSignal,
        })
        .catch((e) => {
          if (e.response.status === 401 && query.accountNumber) {
            throw new Error(
              `User is not authorized to access account ${query.accountNumber}`
            );
          } else if (e.response?.data?.detail) {
            throw new Error(e.response.data.detail);
          } else {
            throw e;
          }
        });
      return result.data;
    }

    /**
     *
     * @description Gets the BOM component list for a given material
     * @param {Input.GetBomComponentsForMaterial} query
     * @param {AbortSignal|undefined} abortSignal
     * @returns
     */
    async getBomComponentsForMaterial(
      bomNumber: string,
      abortSignal?: AbortSignal
    ): Promise<Output.MaterialComponent[]> {
      const headers = await this.getAuthorizationHeaders();
      let url = `replacementparts/${bomNumber}/components`;
      let result = await this.connection
        .get(url, {
          baseURL: this.baseUrl,
          headers: headers,
          signal: abortSignal,
        })
        .catch((e) => {
          if (e.response?.data?.detail) {
            throw new Error(e.response.data.detail);
          } else {
            throw e;
          }
        });
      return result.data;
    }
  }
}
