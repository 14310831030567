import { AxiosError, AxiosResponse } from "axios";
import {
  Brand,
  AssignedGroupAccount,
  AssignedGroupsResponseInterface,
  UserBrandResponseInterface,
  UserSoldToBrands,
  UnassignedGroupsResponseInterface,
} from "../models/UserAccount";
import ApiService, { ApiCancelTokenSource } from "./ApiService";
import {
  AssignBrandAccountRequest,
  AssignUserAccountRequest,
  AssignUserAccountResponse,
  BrandsSearchResponse,
  CreateGroupAccountRequest,
  DeleteBrandAccountRequest,
  DeleteGroupAccountRequest,
  PaginatedAssignUserAccountResponse,
  PaginatedCustomerResponse,
  PaginatedInterface,
  PaginatedUserAccountsInterface,
  PaginatedUserRequestId,
  PaginatedUserRequestEmail,
  UpdateGroupAccountRequest,
  UpdateUserAccountRequest,
  UserAccountMetaDataFavoriteInterface,
  UserAccountSearchInterface,
  UserBrandSearchInterface,
  UserIdentityResponseInterface,
  UserAccountMetaDataUpdateInterface,
  UserTypeInterface,
} from "./ApiServiceInterfaces";

const coreAPIM_Header = process.env.REACT_APP_APIM_CORE_KEY_HEADER_NAME;
const coreAPIM_Key = process.env.REACT_APP_APIM_CORE_KEY;
const identityAPI_Base = process.env.REACT_APP_IDENTITYAPI_BASE;

const connectBaseURL = process.env.REACT_APP_HUBBELLCONNECT_API_BASE;

class UserAccountService extends ApiService {
  /* Hubbell Core APIM Endpoints */

  async getPaginatedUserAccounts(
    query: PaginatedUserRequestId,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<PaginatedCustomerResponse>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const params = {
      limit: query.pageSize,
      offset:
        query.pageSize && query.page ? query.pageSize * query.page : undefined,
      searchTerm: query.searchTerm,
    };
    const url = `Users/${query.userId.toString()}/Customers`;

    let response = this.connection.get(url, {
      baseURL: identityAPI_Base,
      params: params,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return response;
  }

  async getAssignedSoldToAccountCustomers(
    query: PaginatedUserRequestId,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<PaginatedCustomerResponse>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const url = `Users/${query.userId.toString()}/Customers`;

    return this.connection.get(url, {
      baseURL: identityAPI_Base,
      params: {
        limit: query.pageSize,
        offset: query.pageSize && query.page ? query.pageSize * query.page : 0,
        searchTerm: query.searchTerm || undefined,
      },
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async getAssignedSoldToAccounts(
    query: PaginatedUserRequestEmail,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<UserSoldToBrands>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const url = `Users/${query.userEmail}/Customers`;

    return this.connection.get(url, {
      baseURL: identityAPI_Base,
      params: {
        limit: query.pageSize,
        offset: query.pageSize && query.page ? query.pageSize * query.page : 0,
        searchTerm: query.searchTerm || undefined,
      },
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async getAssignedSoldToAccountsSummary(
    userIdentityId: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<UserIdentityResponseInterface>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    const url = `users/${userIdentityId}/customers/summary`;
    return this.connection.get(url, {
      baseURL: identityAPI_Base,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async getAssignedSoldToAccountsSummarybyEmail(
    userEmail: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<UserIdentityResponseInterface>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
      headers["version"] = "2"; // to do - remove when Auth header changes are pushed to Identityh Apim PROD
    }
    const url = `users/${encodeURIComponent(userEmail)}`;
    return this.connection.get(url, {
      baseURL: identityAPI_Base,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async getUserTypeByID(
    userIdentityId: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<UserTypeInterface>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    const url = `users/${userIdentityId}/type`;
    return this.connection.get(url, {
      baseURL: identityAPI_Base,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async getSoldToAccounts(
    query: PaginatedUserAccountsInterface,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<PaginatedCustomerResponse>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const params = {
      limit: query.pageSize,
      offset: query.pageSize && query.page ? query.pageSize * query.page : 0,
      searchTerm: query.searchTerm,
      upn: query.userEmail,
    };
    const url = `Customers/notAssignedByUpn`;

    return this.connection.get(url, {
      baseURL: identityAPI_Base,
      params: params,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async postSoldToAccount(
    query: AssignUserAccountRequest,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<AssignUserAccountResponse>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const data = {
      accountNumber: query.accountNumber,
    };
    const url = `Users/${query.userId}/Customers`;
    return this.connection.post(url, data, {
      headers,
      baseURL: identityAPI_Base,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async deleteSoldToAccount(
    query: AssignUserAccountRequest,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<AssignUserAccountResponse>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const url = `Users/${query.userId}/Customers/${query.accountNumber}`;
    return this.connection.delete(url, {
      baseURL: identityAPI_Base,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async putSoldToAccount(
    query: UpdateUserAccountRequest,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<AssignUserAccountResponse>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    const url = `Users/${query.userId}/customers/${query.accountNumber}/brands`;
    return this.connection.put(url, query, {
      baseURL: identityAPI_Base,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async getUnassignedBrandAccounts(
    query: UserBrandSearchInterface,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<UserBrandResponseInterface>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const params = {
      userId: query.userId,
      limit: query.pageSize,
      offset:
        query.pageSize && query.page ? query.pageSize * query.page : undefined,
      searchTerm: query.searchTerm,
      level: 1, // limit to parent sales orgs #160851
    };
    const url = `Brands/notAssigned`;
    return this.connection.get(url, {
      baseURL: identityAPI_Base,
      params: params,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async postBrandAccount(
    query: AssignBrandAccountRequest,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<Brand>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const url = `Users/${query.userId}/brands`;
    return this.connection.post(
      url,
      {
        division: query.division,
        salesOrg: query.salesOrg,
      },
      {
        baseURL: identityAPI_Base,
        headers,
        cancelToken: cancelTokenSource?.token,
      }
    );
  }

  async deleteBrandAccount(
    query: DeleteBrandAccountRequest,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<Brand>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    const url = `Users/${query.userId}/brands/${query.userBrandGroupId}`;
    return this.connection.delete(url, {
      baseURL: identityAPI_Base,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async getBrandAssignments(
    query: UserBrandSearchInterface,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<UserBrandResponseInterface>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const params = {
      limit: query.pageSize,
      offset:
        query.pageSize && query.page ? query.pageSize * query.page : undefined,
      searchTerm: query.searchTerm,
    };
    const url = `Users/${query.userId}/brands`;
    return this.connection.get(url, {
      baseURL: identityAPI_Base,
      params: params,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async getAllBrands(
    query: PaginatedInterface,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<BrandsSearchResponse>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const params = {
      limit: query.pageSize,
      offset:
        query.pageSize && query.page ? query.pageSize * query.page : undefined,
      searchTerm: query.searchTerm,
    };
    const url = `brands`;
    return this.connection.get(url, {
      baseURL: identityAPI_Base,
      params: params,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async getAssignedAccountBrands(
    query: PaginatedAssignUserAccountResponse,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<BrandsSearchResponse>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const params = {
      limit: query.pageSize,
      offset:
        query.pageSize && query.page ? query.pageSize * query.page : undefined,
      searchTerm: query.searchTerm,
    };
    const url = `Users/${query.userId}/customers/${query.accountNumber}/brands`;
    return this.connection.get(url, {
      baseURL: identityAPI_Base,
      params: params,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async getCustomerAccountsSummary(
    userAccountName: string,
    customerAccounts: Array<string>,
    cancelTokenSource?: ApiCancelTokenSource
  ) {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const params = {
      accountNumbers: customerAccounts.join(","),
    };

    let url = `Users/${userAccountName}/customerDetails`;
    return this.connection.get(url, {
      baseURL: identityAPI_Base,
      params: params,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async findUserAccount(
    query: UserAccountSearchInterface,
    cancelTokenSource?: ApiCancelTokenSource
  ) {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const params = {
      searchTerm: query.accountNumber,
    };

    const url = `Users/${query.userId}/Customers`;
    return this.connection.get(url, {
      baseURL: identityAPI_Base,
      params: params,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async getGroupAccounts(
    query: PaginatedUserRequestId,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<UnassignedGroupsResponseInterface>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const params = {
      userId: query.userId.toString(),
      limit: query.pageSize,
      offset:
        query.pageSize && query.page ? query.pageSize * query.page : undefined,
      searchTerm: query.searchTerm,
    };
    const url = `GroupingAccounts/notAssigned`;
    return this.connection.get(url, {
      baseURL: identityAPI_Base,
      params: params,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async getGroupAssignments(
    query: PaginatedUserRequestId,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<AssignedGroupsResponseInterface>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const params = {
      limit: query.pageSize,
      offset:
        query.pageSize && query.page ? query.pageSize * query.page : undefined,
      searchTerm: query.searchTerm,
    };
    const url = `Users/${query.userId.toString()}/groupingaccounts`;
    return this.connection.get(url, {
      baseURL: identityAPI_Base,
      params: params,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async postGroupAssignments(
    query: CreateGroupAccountRequest,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<AssignedGroupAccount>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const url = `Users/${query.userId}/groupingaccounts`;
    return this.connection.post(url, query, {
      headers,
      baseURL: identityAPI_Base,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async putGroupAssignments(
    query: UpdateGroupAccountRequest,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<AssignedGroupAccount>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const url = `Users/${query.userId}/groupingaccounts/${query.accountNumber}/brands`;
    return this.connection.put(url, query, {
      headers,
      baseURL: identityAPI_Base,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async getAssignedGroupBrands(
    query: PaginatedAssignUserAccountResponse,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<BrandsSearchResponse>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const params = {
      limit: query.pageSize,
      offset:
        query.pageSize && query.page ? query.pageSize * query.page : undefined,
      searchTerm: query.searchTerm,
    };
    const url = `Users/${query.userId}/groupingaccounts/${query.accountNumber}/brands`;
    const result = await this.connection
      .get(url, {
        baseURL: identityAPI_Base,
        params: params,
        headers: headers,
        cancelToken: cancelTokenSource?.token,
      })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 404) {
          console.warn("GetGroupAccountBrands found no brands");
          return {
            ...e.response,
            data: {
              brands: [],
              totalCount: 0,
              numberOfPages: 1,
              pageSize: 0,
            },
          } as AxiosResponse<BrandsSearchResponse>;
        } else throw e;
      });
    return result;
  }

  async getGroupBrands(
    query: PaginatedAssignUserAccountResponse,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<BrandsSearchResponse>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const params = {
      limit: query.pageSize,
      offset:
        query.pageSize && query.page ? query.pageSize * query.page : undefined,
      searchTerm: query.searchTerm,
    };
    const url = `GroupingAccounts/${query.accountNumber}/brands`;
    const result = await this.connection
      .get(url, {
        baseURL: identityAPI_Base,
        params: params,
        headers: headers,
        cancelToken: cancelTokenSource?.token,
      })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 404) {
          console.warn("GetGroupBrands found no brands");
          return {
            ...e.response,
            data: {
              brands: [],
              totalCount: 0,
              numberOfPages: 1,
              pageSize: 0,
            },
          } as AxiosResponse<BrandsSearchResponse>;
        } else throw e;
      });
    return result;
  }

  async deleteGroupAssignments(
    query: DeleteGroupAccountRequest,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const url = `Users/${query.userId}/groupingaccounts/account/${query.accountGroupNumber}`;
    return this.connection.delete(url, {
      baseURL: identityAPI_Base,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  /* Hubbell Connect API Endpoints */

  async getUserAccountsSoldTos(
    userEmail: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<UserSoldToBrands>> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();

    const url = "/UserAccount?userName=" + encodeURIComponent(userEmail);
    return this.connection.get(url, {
      baseURL: connectBaseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async updateUserAccountFavorite(
    userEmail: string,
    userAccount: UserAccountMetaDataFavoriteInterface,
    cancelTokenSource?: ApiCancelTokenSource
  ) {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();

    const url = "/UserAccount?userName=" + encodeURIComponent(userEmail);
    return this.connection.post(url, userAccount, {
      baseURL: connectBaseURL,
      params: { action: "Favorite" },
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async updateDefaultUserAccount(
    userEmail: string,
    userAccount: UserAccountMetaDataUpdateInterface,
    cancelTokenSource?: ApiCancelTokenSource
  ) {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();

    const url = "/UserAccount?userName=" + encodeURIComponent(userEmail);
    return this.connection.post(url, userAccount, {
      baseURL: connectBaseURL,
      params: { action: userAccount.isDefault ? "Default" : "" },
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async updateUserAccount(
    userEmail: string,
    userAccount: UserAccountMetaDataUpdateInterface,
    cancelTokenSource?: ApiCancelTokenSource
  ) {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();

    const url = "/UserAccount?userName=" + encodeURIComponent(userEmail);
    return this.connection.post(url, userAccount, {
      baseURL: connectBaseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }
}
export default UserAccountService;
