import React, { FC, useState } from "react";
import { ToastContainer, Toast } from "react-bootstrap";

const DownloadToasts: FC<{
  downloadID: string;
  downloadURL: string;
}> = ({ downloadID, downloadURL }): JSX.Element => {
  const [showToast, setShowToast] = useState(true);

  const toggleShowToast = () => setShowToast(!showToast);
  return (
    <div>
      <ToastContainer position="top-end" className="hubbell-toast-stack">
        <Toast
          key={downloadID}
          className={`hubbell-notification`}
          show={showToast}
          onClose={toggleShowToast}
        >
          <Toast.Header closeButton={true}>
            <strong>Download File Ready</strong>
          </Toast.Header>
          <Toast.Body>
            <a href={downloadURL}>Click to download file</a>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default DownloadToasts;
