import {
  loginRequest,
  tokenRequest,
  silentRequest,
  resetPasswordRequest,
  msalConfig,
  getResetPasswordRequestPolicy,
} from "../config/authConfig";
import {
  AccountInfo,
  AuthenticationResult,
  EndSessionRequest,
  IPublicClientApplication,
  PopupRequest,
  SilentRequest,
} from "@azure/msal-browser";
import { clear } from "./Storage";

/*
// If you support IE, our recommendation is that you sign-in using Redirect flow
const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ") > -1;
  const msie11 = ua.indexOf("Trident/") > -1;
  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  // const isEdge = ua.indexOf("Edge/") > -1;
  return msie || msie11;
};
const useRedirectFlow = isIE();
*/

// feedback form is a global jquery resource so we need to declare it
declare global {
  interface Window {
    setFeedbackEmail: (email: string) => void;
    msalInstance: IPublicClientApplication;
  }
}

export class AuthService {
  private msalApp: IPublicClientApplication;
  constructor(msal?: IPublicClientApplication) {
    this.msalApp = msal || window.msalInstance;
  }

  isPasswordReset = (error: Error): boolean => {
    return error.message.indexOf("AADB2C90118") > -1;
  };

  initialize = (
    callback?: (username: string) => Promise<void>
  ): Promise<AccountInfo | null> => {
    return new Promise<AccountInfo | null>((resolve) => {
      const account = this.getAndSetAccount();
      if (!account) {
        this.msalApp
          .handleRedirectPromise()
          .then((response) => {
            const a = this.handleMsalResponse(response);
            a.then((result) => {
              if (result?.username) {
                this.setFeedbackEmail(result.username);
                if (callback) {
                  callback(result.username);
                }
              }
            }).finally(() => resolve(a));
          })
          .catch((error) => {
            if (this.isPasswordReset(error)) {
              console.info("User requested password reset.");
              this.msalApp.loginRedirect(resetPasswordRequest);
            } else {
              console.error(error);
              resolve(null);
            }
          });
      } else {
        this.msalApp.setActiveAccount(account);
        this.setFeedbackEmail(account.username);

        if (callback) {
          callback(account.username);
        }
        resolve(account);
      }
    });
  };

  getAndSetAccount = (): AccountInfo | null => {
    let account = this.msalApp.getActiveAccount();
    if (!account) {
      let currentAccounts = this.msalApp.getAllAccounts();
      if (!currentAccounts || currentAccounts.length === 0) {
        console.error("No accounts detected!");
        return null;
      } else {
        // unable to find active account, search by username, set it
        account = this.msalApp.getAccountByUsername(
          currentAccounts[0].username
        );
        if (!account) {
          console.error("No account found with provided username");
          return null;
        }
        this.msalApp.setActiveAccount(account);
      }
    }
    return account;
  };

  setFeedbackEmail = (email: string): void => {
    if (!global.window.setFeedbackEmail) {
      console.info("Feedback global.window.setFeedbackEmail not found!");
    }
    console.info(`Setting feedback email to: <${email}>`);
    if (email.indexOf("@") !== -1) {
      global.window.setFeedbackEmail(email);
    } else {
      global.window.setFeedbackEmail("");
    }
  };

  handleMsalResponse = (
    response: AuthenticationResult | null,
    callback?: () => Promise<void>
  ): Promise<AccountInfo | null> => {
    return new Promise<AccountInfo | null>((resolve) => {
      if (response?.account != null) {
        if (response.account.username) {
          Promise.resolve(callback).finally(() => {
            this.msalApp.setActiveAccount(response?.account);
            resolve(response.account);
          });
        }
      } else {
        let account = this.getAndSetAccount();
        resolve(account);
      }
    });
  };

  acquireToken = (): Promise<string> => {
    const account = this.getAndSetAccount();
    return new Promise<string>((resolve, reject) => {
      if (!account?.username) {
        // unable to sign in
        return reject();
      }

      let request: SilentRequest = { scopes: silentRequest.scopes, account };
      console.info("acquiring token for username: " + account?.username);
      const acr: string = (account.idTokenClaims as any)?.acr;
      if (
        acr &&
        acr.toLowerCase().indexOf(getResetPasswordRequestPolicy()) > -1
      ) {
        request.authority = resetPasswordRequest.authority;
      }
      return this.msalApp
        .acquireTokenSilent(request)
        .then((response) => {
          resolve(response.accessToken);
        })
        .catch((error) => {
          console.error(
            "Silent token acquisition failed. acquiring token using interactive method",
            error
          );
          // fallback to popup interaction when silent call fails
          let request: PopupRequest = {
            scopes: tokenRequest.scopes,
            account: account,
          };
          return this.msalApp
            .acquireTokenPopup(request)
            .then((response) => {
              resolve(response.accessToken);
            })
            .catch((error) => {
              console.error(error);
              if (this.isPasswordReset(error)) {
                console.info("User requested password reset.");
                this.msalApp.loginRedirect(resetPasswordRequest);
              } else {
                reject();
              }
            });
        });
    });
  };

  signIn = async () => {
    return this.msalApp.loginRedirect(loginRequest).catch((error) => {
      console.error(error.message);
    });
  };

  signOut = async () => {
    this.setFeedbackEmail("");
    const authUserName = this.msalApp.getActiveAccount()?.username;
    this.msalApp.setActiveAccount(null);
    const logoutRequest: EndSessionRequest = {
      authority: msalConfig.auth.authority,
      account: authUserName
        ? this.msalApp.getAccountByUsername(authUserName)
        : undefined,
      postLogoutRedirectUri: "/login",
      onRedirectNavigate: () => {
        clear();
        return true;
      },
    };
    if (!authUserName) {
      return this.msalApp.logout(logoutRequest);
    }
    return this.msalApp.logoutRedirect(logoutRequest);
  };
}
