export interface Inventory {
  location: string;
  quantity: number;
}

export interface ResourceItem {
  filename: string;
  url: string;
  thumbnail?: string;
  medium?: string;
  large?: string;
}

export interface Resource {
  type: string;
  items: Array<ResourceItem>;
}

export interface Feature {
  type: string;
  attributes: { [key: string]: string };
}

export enum ProductImageFormat {
  Product = "product",
  Zoom = "zoom",
  Thumb = "thumbnail",
  Icon = "cartIcon",
}

export enum ProductImageType {
  Primary = "PRIMARY",
  Gallery = "GALLERY",
}

export interface ProductImage {
  imageType: ProductImageType;
  format: ProductImageFormat;
  url: string;
  altText: string;
  galleryIndex?: number;
}
