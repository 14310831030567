const ReplacementParts_Group = [{ salesOrg: "C520", division: "5W" }];
/**
 *
 * @param {string} salesOrg
 * @param {string} division
 * @returns {boolean}
 */
export function brandSupportsReplacementParts(
  salesOrg: string,
  division: string
) {
  return (
    ReplacementParts_Group.find(
      (i: any) => i.salesOrg === salesOrg && i.division === division
    ) !== undefined
  );
}
