// Definitions

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
//import store, { StateSelectorFactory } from "store";
import Notification from "./models/Notification";
export const storeKey = "systemNotifications";

export type NotificationState = Array<Notification>;

export const initialState: NotificationState = [];

// Slice Definition

export const systemNotificationSlice = createSlice({
  name: storeKey,
  initialState: initialState,
  reducers: {
    clearAll() {
      return { ...initialState };
    },
    update(
      state,
      action: PayloadAction<{ id: string } & Partial<Notification>>
    ) {
      for (let i = 0; i < state.length; i++) {
        if (state[i].id === action.payload.id) {
          let updatedMessage = Object.assign({}, state[i], action.payload);
          state.splice(i, 1, updatedMessage);
          break;
        }
      }
    },
    remove(state, action: PayloadAction<string>) {
      return [...state.filter((n) => n.id !== action.payload)];
    },
    add(state, action: PayloadAction<Notification>) {
      return state.concat(action.payload);
    },
  },
  extraReducers(builder) {},
});

// Helpers

/*
export const getState = StateSelectorFactory(initialState, storeKey);

export const subscribe = (f: Function) => {
  let lastState = getState();
  return store.subscribe(
    () => lastState !== getState() && f((lastState = getState()))
  );
};
*/
