import { store } from "index";
import { UserAccount } from "models/UserAccount";
import { ProfileDuck, UserProfile } from "store/userProfileStore";

export const setSystemUserCurrentAccount = (
  account: UserAccount,
  user: UserProfile
) => {
  ProfileDuck.actions.SET_CURRENT_ACCOUNT(store.dispatch, {
    account: account,
    user: user,
  });
};
