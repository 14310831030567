//import store from "store";
import { store } from "index";
import {
  AddFileDownloadInterface,
  RemoveFileDownloadInterface,
} from "./FileDownloadInterface";
import {
  addDownload as addDownloadAction,
  // downloadDocumentRequest,
  systemDownloadManagerSlice,
} from "./reducers";
import { DownloadStatus } from "services/DownloadFIleService";

export const addDownload = (payload: AddFileDownloadInterface) =>
  store.dispatch(addDownloadAction(payload)).unwrap();

export const removeDownload = (payload: RemoveFileDownloadInterface) =>
  store.dispatch(systemDownloadManagerSlice.actions.removeDownload(payload));

export const removeFromDownloadList = (payload: DownloadStatus) =>
  store.dispatch(
    systemDownloadManagerSlice.actions.removeFromDownloadList(payload)
  );

export const pushDetailsToDownloadList = (payload: DownloadStatus) =>
  store.dispatch(
    systemDownloadManagerSlice.actions.pushToDownloadList(payload)
  );
  export const updateToDownloadList = (payload: DownloadStatus) =>
    store.dispatch(
      systemDownloadManagerSlice.actions.updateDownloadList(payload)
    );