import ApiService, { ApiCancelTokenSource } from "./ApiService";
import HubbellConnectOrder, {
  Address,
  Detail,
  FreightDetailsTerms,
  Item,
  DetailData,
  CarrierPreference,
} from "../models/HubbellConnectOrder";
import { AddressValidationInterface } from "features/OrderEntry/store/dispatchers";
import {
  OrderEntryItemValidationResult,
  OrderEntryOrderValidationResult,
} from "../models/OrderEntrySAPValidation";
import {
  OrderEntryItem,
  OrderEntryRequest,
} from "features/OrderEntry/components/OrderEntryActionButton/models/OrderEventPayload";

const coreAPIM_Header = process.env.REACT_APP_APIM_CORE_KEY_HEADER_NAME;
const coreAPIM_Key = process.env.REACT_APP_APIM_CORE_KEY;
const baseCoreURL = process.env.REACT_APP_APIM_CORE_BASE_COMMERCE;
const baseURL = process.env.REACT_APP_HUBBELLCONNECT_API_BASE;
const freightTermURL = process.env.REACT_APP_FREIGHT_API;
const dataQuality = "https://api.hubbell.com/dataquality/";
const carrierURL = process.env.REACT_APP_CARRIER_API;
const baseURlSAP = process.env.REACT_APP_SRI_API_BASE;
const sapEnvironmentKey = process.env.REACT_APP_SAPRFC_ENV_KEY;

export interface validateAddressErrorResponse {
  subject: string;
  message: string;
}

export interface validateAddressResponse {
  line1?: string;
  line2?: string;
  city?: string;
  region?: string;
  postalcode?: string;
  country?: string;
  error?: validateAddressErrorResponse;
}

class OrderEntryService extends ApiService {
  /*
  async DeleteOrderItem(
    orderId: string,
    itemId: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<HubbellConnectOrder> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }

    const url = `order/${orderId}/item/${itemId}`;
    return await this.connection.delete(url, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }
*/
  async OrderDraftCarrierDetails(
    salesOrg: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<CarrierPreference[]> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }

    const url = `/${salesOrg}`;
    let result = await this.connection.get(url, {
      baseURL: carrierURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }

  async OrderDraftForAccountNumber(
    accountNumber: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<HubbellConnectOrder> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }

    const url = `/order/GetDraft?accountNumber=${accountNumber}`;
    let result = await this.connection.get(url, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data.length ? result?.data[0] : result?.data;
  }
  /*
  async OrderDraftForOrderNumber(
    orderNumber: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<HubbellConnectOrder> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }

    const url = "/Order/getOrderDraftForOrderNumber";
    let result = await this.connection
      .get(url, {
        baseURL: baseUrl,
        headers: headers,
        cancelToken: cancelTokenSource?.token,
      })
      .then((resp: any) => {
        return resp;
      })
      .catch((error: any) => {
        console.log(
          "Exception occurred while calling OrderDraftForOrderNumber API",
          error
        );
        console.log("Sending Mock Order Data");
        return OrderData;
      });

    return result;
  }
*/

  async validateAddress(
    query: AddressValidationInterface,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<validateAddressResponse> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const url = `/AddressCleanse`;
    let result = await this.connection.post(url, query, {
      baseURL: dataQuality,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }

  async updateOrderEntryAddress(
    orderId: string,
    orderAddressId: string | undefined,
    query: Address | undefined,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Address> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const url = `/order/${orderId}/addresses/${orderAddressId}`;
    let result = await this.connection.put(url, query, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });

    return result.data;
  }

  async getOrderAddress(
    orderId: string,
    orderAddressId: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<any> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const url = `/Order/${orderId}/addresses/${orderAddressId}`;
    let result = await this.connection.get(url, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }

  async updateOrderAddress(
    orderId: string,
    orderAddressId: string,
    query: Address | undefined,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Address> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const url = `/Order/${orderId}/addresses/${orderAddressId}`;
    let result = await this.connection.put(url, query, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }

  async deleteOrderAddress(
    orderId: string,
    orderAddressId: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Address> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const relativeUrl = `/Order/${orderId}/item/${orderAddressId}`;
    return this.connection.delete(relativeUrl, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  //Below API Service is for order Item

  async createOrderItem(
    orderId: string,
    query: Item,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Item> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const relativeUrl = `/Order/${orderId}/item`;
    let result = await this.connection.post(relativeUrl, query, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }

  async updateOrderItem(
    orderId: string,
    orderItemId: string,
    query: Item,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Item> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const relativeUrl = `/Order/${orderId}/item/${orderItemId}`;
    let result = await this.connection.put(relativeUrl, query, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }

  async deleteOrderItem(
    orderId: string,
    orderItemId: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Item> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const relativeUrl = `/Order/${orderId}/item/${orderItemId}`;
    return this.connection.delete(relativeUrl, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  // freight terms service
  async freightTerm(
    accountNumber: string,
    salesOrg: string,
    division: string
  ): Promise<FreightDetailsTerms[]> {
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const relativeUrl = `?accountNumber=${accountNumber}&salesOrg=${salesOrg}&division=${division}`;
    let result = await this.connection.get(relativeUrl, {
      baseURL: freightTermURL,
      headers: headers,
    });
    return result.data;
  }

  async freightIncentive(
    accountNumber: string,
    salesOrg: string,
    division: string
  ): Promise<string> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    const h = coreAPIM_Header || "apim-header-not-found";
    const t = coreAPIM_Key || "key-not-found";
    headers["app-env-key"] = sapEnvironmentKey;
    headers[h] = t;
    const relativeUrl = `/FreightPrograms/${accountNumber}?salesOrg=${salesOrg}&division=${division}`;
    let result = await this.connection
      .get(relativeUrl, {
        baseURL: baseURlSAP,
        headers: headers,
      })
      .catch((e) => {
        // swallow the error; this data is not mission critical
        console.error(e);
      });
    return result?.data?.incentive || "";
  }

  async deleteOrder(
    orderId: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Item> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const relativeUrl = `/Order/${orderId}`;
    return this.connection.delete(relativeUrl, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  //Order Entry Details

  async updateOrderEntryDetails(
    orderId: string,
    orderDetailId: string,
    query: DetailData,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Detail> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const relativeUrl = `/order/${orderId}/orderdetail/${orderDetailId}`;
    let result = await this.connection.put(relativeUrl, query, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }

  async validateOrderItem(
    orderId: string
  ): Promise<OrderEntryItemValidationResult[]> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const url = `/order/${orderId}/items/validate`;
    let result = await this.connection.get(url, {
      baseURL: baseURL,
      headers: headers,
    });
    return result.data;
  }

  async validateWholeOrder(
    orderId: string
  ): Promise<OrderEntryOrderValidationResult[]> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const url = `/order/${orderId}/validate`;
    const result = await this.connection.get(url, {
      baseURL: baseURL,
      headers: headers,
    });

    return result.data;
  }

  async uploadOrderDetailAttachment(
    data: FormData,
    orderDetailId: string
  ): Promise<any> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const url = `/orders/attachment/${orderDetailId}`;
    return await this.connection.post(url, data, {
      baseURL: baseCoreURL,
      headers: headers,
    });
  }

  async getOrderDetailAttachment(orderDetailId: string): Promise<any> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const url = `/orders/attachment/${orderDetailId}`;
    return await this.connection.get(url, {
      baseURL: baseCoreURL,
      headers: headers,
      responseType: "arraybuffer",
    });
  }

  async deleteOrderDetailAttachment(orderDetailId: string): Promise<any> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const url = `/orders/attachment/${orderDetailId}`;
    return await this.connection.delete(url, {
      baseURL: baseCoreURL,
      headers: headers,
    });
  }

  /* need to make sure this name makes sense for this endpoint*/
  async updateOrderDraft(
    item: OrderEntryItem,
    orderId: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Item> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const url = `/order/${orderId}/item/`;
    let result = await this.connection.post(url, item, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }

  async createOrder(
    query: OrderEntryRequest,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<HubbellConnectOrder> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const url = `/order`;
    let result = await this.connection.post(url, query, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data?.length ? result.data[0] : result.data;
  }

  async commitOrder(
    orderId: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<SubmitOrderResult> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const url = `/order/${orderId}/submit`;
    let result = await this.connection.post(url, null, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }
}
export default OrderEntryService;

export interface SubmitOrderResult {}
