import { systemNotificationSlice } from "./reducers";
import Notification from "./models/Notification";

export const addNotificationAction = (notification: Notification) =>
  systemNotificationSlice.actions.add(notification);

export const removeNotificationAction = (id: string) =>
  systemNotificationSlice.actions.remove(id);

export const updateNotificationAction = (
  update: { id: string } & Partial<Notification>
) => systemNotificationSlice.actions.update(update);

export const clearAllNotificationsAction = () =>
  systemNotificationSlice.actions.clearAll();
