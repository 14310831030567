import { store } from "index";
import { useLayoutEffect, useState } from "react";

export const getState = () => store.getState()["userProfile"];

const subscribe = (f: Function) => {
  let lastState = getState();
  return store.subscribe(
    () => lastState !== getState() && f((lastState = getState()))
  );
};

const useUserProfile = () => {
  const [state, setState] = useState(getState());
  useLayoutEffect(() => subscribe(setState), [setState]);
  return { state };
};

export default useUserProfile;
