// determine if the current orders brand has shipment docs available

import UserAccountService from "services/UserAccountService";
import OrderEntryService from "../services/OrderEntryService";
import { OrderDocumentTypes } from "models/Order";
import {
  OrderEntryItem,
  OrderEntryRequest,
  OrderEventItem,
  OrderEventPayload,
} from "features/OrderEntry/components/OrderEntryActionButton/models/OrderEventPayload";

const shipmentGroups = [{ salesOrg: "C520", division: "5W" }];

export function getShipmentDocs(salesOrg: string, headerDivision: string) {
  return (
    shipmentGroups.find(
      (i: any) => i.salesOrg === salesOrg && i.division === headerDivision
    ) !== undefined
  );
}

export function displayCartItem(system: any) {
  const orderEntryModule = system.findUserModule("order-entry");
  return orderEntryModule ? orderEntryModule : false;
}

export function mapDataToRequest(item: OrderEventItem): OrderEntryItem {
  const modifiedItem: OrderEventItem = {} as OrderEventItem;
  modifiedItem.materialNumber = item.materialNumber;
  modifiedItem.quantity = parseInt(item.quantity.toString());
  modifiedItem.salesOrg = item.salesOrg;
  modifiedItem.division = item.division;
  modifiedItem.referenceQuoteNumber = item.referenceQuoteNumber;
  modifiedItem.referenceOrderNumber = item.referenceOrderNumber;
  // map optional? parameters
  if (item.description) {
    modifiedItem.description = item.description;
  }
  if (item.unitPrice) {
    modifiedItem.unitPrice = item.unitPrice;
  }
  /*
  * inventory needs to be pushed as an item update
  if (item.inventory) {
    modifiedItem.inventory = item.inventory;
  }
  */
  modifiedItem.purchaseOrderLineNumber = item.purchaseOrderLineNumber;
  modifiedItem.lineNumber = item.lineNumber;
  return modifiedItem;
}

export function mapOrderEventPayloadToOrderEntryRequest(
  data: OrderEventPayload
): OrderEntryRequest {
  let itemsPayload: OrderEntryItem[] = [];
  data.items.forEach((item: any) => {
    const modifiedItem: any = mapDataToRequest(item);
    itemsPayload.push(modifiedItem);
  });
  return {
    source: data.source,
    orderType: { name: data.orderType },
    accountNumber: Number(data.accountNumber).toString(),
    items: itemsPayload,
  };
}
/*
export async function validateOrderItem(orderItemId: string) {
  const orderEntryServiceCall = new OrderEntryService();
  return await orderEntryServiceCall
    .validateOrderItem(orderItemId)
    .then((resp: any) => {
      if (resp?.status === 200) {
        return resp.data;
      }
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
}
*/
export function mapOrderDetailToDescriptionAndBrand(
  orderDetailsData: any,
  createOrderResponse: any
) {
  createOrderResponse[0]?.items?.forEach((item: any, index: any) => {
    if (
      orderDetailsData?.items[0][index]?.materialNumber ===
        item.materialNumber ||
      orderDetailsData?.items[0]?.materialNumber === item.materialNumber
    ) {
      item.description =
        orderDetailsData?.items[0][index]?.description ||
        orderDetailsData?.items[0]?.description;
      item.brandName =
        orderDetailsData?.items[0][index]?.brandName ||
        orderDetailsData?.items[0][index]?.brand?.name ||
        orderDetailsData?.items[0]?.brand?.name ||
        orderDetailsData?.items[0]?.brand;
    }
  });
  return createOrderResponse;
}

export const deleteDraftOrders = async (orderId: string) => {
  const orderEntryServiceCall = new OrderEntryService();
  return await orderEntryServiceCall.deleteOrder(orderId).then((resp: any) => {
    if (resp?.status === 200) {
      return true;
    } else {
      return false;
    }
  });
};

export const changeCurrentAccount = (userId: string, account: number) => {
  const userAccountService = new UserAccountService();
  return userAccountService.findUserAccount(
    { userId: userId, accountNumber: account.toString() },
    userAccountService.generateSourceToken()
  );
};

export enum ModalTypes {
  Search = "search",
  Searching = "searching",
  ShippingInfo = "shippingInfo",
  ProductDetail = "productDetail",
  Email = "email",
  DownloadExceeded = "downloadExceeded",
  Terms = "terms",
}

export interface ModalData {
  type: ModalTypes;
  title?: string;
  data?: string[];
  docType?: OrderDocumentTypes;
}
