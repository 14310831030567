import { Dispatch } from "redux";
import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { wrapWorker } from "./helpers";
import { SalesOrganizationInterface } from "../models/SalesOrganization";
import {
  RouteDefinition,
  UserTypeInterface,
} from "../services/ApiServiceInterfaces";
import SystemService from "../services/SystemService";
import SalesOrganizationService from "../services/SalesOrganizationService";
import { AppState } from "./app";
import { ThunkDispatch } from "redux-thunk";
import {
  getTopLevelFunctionModules,
  getTopLevelUserFunctionModules,
} from "../util/systemHelper";
import CDNApi from "services/CDNApi";
import { ServiceMessage } from "services/CDNApi/ServiceMessage";

export enum SystemReadyStates {
  Initializing = "initializing",
  Loading = "loading",
  Ready = "ready",
}

export interface UserRecord {
  accountsAssigned: boolean;
  identityApiId: string;
  lastLogon: string;
  name: string;
  secondaryEmail: string | null;
  userId: string;
  userName: string;
  isActive: boolean;
  userTypeDetails: UserTypeInterface | undefined;
  phoneNumber: string;
  companyName: string;
  country: string;
}

export interface SystemState {
  readyState: SystemReadyStates;
  isRefreshing: boolean;
  modules: Array<RouteDefinition> | undefined;
  userModules: Array<RouteDefinition> | undefined;
  userSystemRecord: UserRecord | undefined;
  brands: Array<SalesOrganizationInterface> | undefined;
  topModules: Array<RouteDefinition> | undefined;
  topUserModules: Array<RouteDefinition> | undefined;
  hasError: boolean;
  serviceMessages: ServiceMessage[];
}

export interface MappedSystemState extends SystemState {
  isReady: boolean;
  brandsReady: boolean;
  hasAccounts: boolean;
  currentUser: UserRecord | undefined;
  isAdminUser: boolean;
  userModuleAuthorizations: (moduleName: string) => boolean;
  findModule: (moduleName: string) => RouteDefinition | undefined;
  findUserModule: (moduleName: string) => RouteDefinition | undefined;
}

export const initialSystemState: SystemState = {
  readyState: SystemReadyStates.Initializing,
  isRefreshing: false,
  modules: undefined,
  userModules: undefined,
  userSystemRecord: undefined,
  brands: undefined,
  topModules: undefined,
  topUserModules: undefined,
  hasError: false,
  serviceMessages: [],
};

const ac = actionCreatorFactory("System");

// Async Action creators
const clearState = ac<void>("clearState");
const update_readystate = ac<SystemReadyStates>("update_readystate");
const update_state = ac<Partial<SystemState>>("update_state");
const update_brands = ac<SalesOrganizationInterface[] | undefined>(
  "update_brands"
);
const update_refreshstatus = ac<boolean>("update_refreshstatus");
const update_errorPage = ac<boolean>("update_errorPage");

// thunks
// const updateUserWhenReady: ThunkAction<any, any, any, Action> = () => (dispatch: Dispatch, getState: () => AppState) => {
//   let state = getState();
//   let userProfile = state.userProfile;
//   let systemState = mapStateToProps(state.system);
//   if (userProfile.authentication?.isAuthenticated && systemState.currentUser && systemState.isReady) {
//     ProfileDuck.actions.UPDATE_USER(dispatch, mapAccountToUserProfile(userProfile.authentication, systemState.currentUser, initialUserProfileState));
//   }
// }

export const fetchSystemServiceMessages = () => {
  return (dispatch: ThunkDispatch<any, any, any>, getState: () => AppState) => {
    return new Promise<void>((resolve, reject) => {
      const cdnApi = new CDNApi.ServiceMessageService();
      cdnApi
        .getServiceMessages()
        .then((r) => dispatch(update_state({ serviceMessages: r })))
        .catch((e) => {
          console.error(e);
          dispatch(update_state({ serviceMessages: [] }));
        });
    });
  };
};

export const fetchConfigDataAndBrands = () => {
  return (dispatch: ThunkDispatch<any, any, any>, getState: () => AppState) => {
    return new Promise<void>((resolve, reject) => {
      let userSystemRecord: any = {};
      let modules: Array<any> = [];
      let systemService = new SystemService();

      const cancelToken = systemService.generateSourceToken();
      try {
        fetchSystemServiceMessages();
        systemService
          .functionModules()
          .then((modulesData) => {
            modules = modulesData;
            return systemService.getSystemUser(cancelToken);
          })
          .then((userSystemRecordData) => {
            userSystemRecord = userSystemRecordData;
            if (userSystemRecord.userName) {
              systemService
                .userModules(userSystemRecord.userName, cancelToken)
                .then((userModules) => {
                  systemService
                    .getUserDetails(undefined, cancelToken)
                    .then((identityResponse) => {
                      let identityDetails = identityResponse || undefined;
                      userSystemRecord.identityApiId =
                        identityDetails?.userId || undefined;
                      userSystemRecord.accountsAssigned =
                        identityDetails?.accountsAssigned || false;
                      userSystemRecord.userTypeDetails =
                        identityDetails.userType || undefined;
                      userSystemRecord.phoneNumber =
                        identityDetails.phoneNumber || "";
                      userSystemRecord.companyName =
                        identityDetails.companyName || "";
                      userSystemRecord.country = identityDetails.country || "";
                      let topModules: Array<any> =
                        getTopLevelFunctionModules(modules);
                      let topUserModules: Array<any> =
                        getTopLevelUserFunctionModules(topModules, userModules);

                      //ProfileDuck.actions.UPDATE_USER(dispatch, userSystemRecord);
                      SystemDuck.actions.update_state(dispatch, {
                        modules,
                        userSystemRecord,
                        userModules,
                        topModules,
                        topUserModules,
                      });

                      const salesOrganizationService =
                        new SalesOrganizationService();
                      return salesOrganizationService.getAll();
                    })
                    .then((brands) => {
                      SystemDuck.actions.update_brands(dispatch, brands);
                      SystemDuck.actions.update_readystate(
                        dispatch,
                        SystemReadyStates.Ready
                      );
                      // dispatch(update_errorPage(false));
                      resolve();
                    });
                });
            } else {
              // dispatch(update_errorPage(false));
              return resolve();
            }
            dispatch(update_errorPage(false));
          })
          .catch((e) => {
            console.error(e.message);
            dispatch(update_errorPage(true));
            reject();
          });
      } catch (e: any) {
        console.error(e.message);
        dispatch(update_errorPage(true));
        reject();
      }
    });
  };
};

export const refreshSystemConfigData = (refreshUserConfig?: boolean) => {
  return async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => AppState
  ) => {
    dispatch(update_refreshstatus(true));
    let systemService = new SystemService();
    //const cancelToken = systemService.generateSourceToken();
    try {
      fetchSystemServiceMessages();
      let modules = await systemService.functionModules();
      let topModules: Array<any> = getTopLevelFunctionModules(modules);
      SystemDuck.actions.update_state(dispatch, {
        modules,
        topModules,
      });
      if (!refreshUserConfig) {
        dispatch(update_refreshstatus(false));
      } else {
        //refresh user
        dispatch(refreshUserConfigData());
      }
    } catch (e: any) {
      console.error(e.message);
      dispatch(update_refreshstatus(false));
    }
  };
};

export const refreshBrands = () => {
  return async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => AppState
  ) => {
    dispatch(update_refreshstatus(true));
    const salesOrganizationService = new SalesOrganizationService();
    //const cancelToken = systemService.generateSourceToken();
    try {
      let brands = await salesOrganizationService.getAll();
      SystemDuck.actions.update_brands(dispatch, brands);
      dispatch(update_refreshstatus(false));
    } catch (e: any) {
      console.error(e.message);
      dispatch(update_refreshstatus(false));
    }
  };
};

export const refreshUserConfigData = () => {
  return async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => AppState
  ) => {
    dispatch(update_refreshstatus(true));
    let userSystemRecord: any = {};
    let systemService = new SystemService();
    const cancelToken = systemService.generateSourceToken();
    try {
      userSystemRecord = await systemService.getSystemUser(cancelToken);

      if (userSystemRecord.userName) {
        const { system: systemState } = getState();
        let userModules = await systemService.userModules(
          userSystemRecord.userName,
          cancelToken
        );
        let identityResponse = await systemService.getUserDetails(
          undefined,
          cancelToken
        );

        let identityDetails = identityResponse || undefined;
        userSystemRecord.identityApiId = identityDetails?.userId || undefined;
        userSystemRecord.accountsAssigned =
          identityDetails?.accountsAssigned || false;
        userSystemRecord.userTypeDetails =
          identityDetails.userType || undefined;
        userSystemRecord.phoneNumber = identityDetails.phoneNumber || "";
        userSystemRecord.companyName = identityDetails.companyName || "";
        userSystemRecord.country = identityDetails.country || "";
        let topModules = systemState.topModules || [];
        let topUserModules: Array<any> = getTopLevelUserFunctionModules(
          topModules,
          userModules
        );

        SystemDuck.actions.update_state(dispatch, {
          userSystemRecord,
          userModules,
          topUserModules,
        });
        dispatch(update_refreshstatus(false));
      }
    } catch (e: any) {
      console.error(e.message);
      dispatch(update_refreshstatus(false));
    }
  };
};

// selectors
export const mapSystemStateToProps = (
  state: SystemState
): MappedSystemState => {
  let isAdminUser =
    state.userModules?.find((m: any) => m.name === "admin") != null;
  let isReady = state.readyState === SystemReadyStates.Ready;
  return {
    ...state,
    isReady,
    brandsReady:
      state !== undefined &&
      state.readyState === SystemReadyStates.Ready &&
      state.brands !== undefined,
    hasAccounts:
      state.userSystemRecord != null && state.userSystemRecord.accountsAssigned,
    currentUser: state.userSystemRecord,
    isAdminUser,
    userModuleAuthorizations: (moduleName: string) => {
      return findUserModule(moduleName, state) != null;
    },
    findModule: (moduleName: string) => {
      return findModule(moduleName, state);
    },
    findUserModule: (moduleName: string) => {
      return findUserModule(moduleName, state);
    },
  };
};

const findModule = (moduleName: string, state: SystemState) => {
  return state.modules?.find(
    (m: any) => m.name?.toLowerCase() === moduleName?.toLowerCase()
  );
};

const findUserModule = (moduleName: string, state: SystemState) => {
  const module = findModule(moduleName, state);
  if (module) {
    const match = state.userModules?.find(
      (m: any) => m.functionModuleId === module.functionModuleId
    );

    if (match) {
      return module;
    } else {
      const topModuleMatch = state.topUserModules?.find(
        (m: any) => m.functionModuleId === module.functionModuleId
      );
      if (topModuleMatch) {
        return module;
      }
    }
  }
  return undefined;
};

//actions are where you can handle your async calls to the API and manipulate them as needed. Afterwards, the result is passed to the reducers to modify the global state.
const actions = {
  clearState: wrapWorker(clearState, (dispatch: Dispatch) => {}),
  update_readystate: wrapWorker(
    update_readystate,
    (dispatch: Dispatch, readyState: SystemReadyStates) => {
      return readyState;
    }
  ),
  update_state: wrapWorker(
    update_state,
    (dispatch: Dispatch, state: Partial<SystemState>) => {
      return state;
    }
  ),
  update_brands: wrapWorker(
    update_brands,
    (dispatch: Dispatch, brands: SalesOrganizationInterface[] | undefined) => {
      return brands;
    }
  ),
};

//the reducers modify the global state of redux with the results of the actions above
const SystemReducer = reducerWithInitialState(initialSystemState)
  .case(clearState, (state: any, payload: any) => {
    return { ...initialSystemState };
  })
  .case(update_readystate, (state: any, readyState: SystemReadyStates) => {
    return { ...state, readyState };
  })
  .case(update_state, (state: any, newState: Partial<SystemState>) => {
    return { ...state, ...newState };
  })
  .case(
    update_brands,
    (state: any, brands: SalesOrganizationInterface[] | undefined) => {
      return { ...state, brands };
    }
  )
  .case(update_refreshstatus, (state: any, isRefreshing: boolean) => {
    return { ...state, isRefreshing };
  })
  .case(update_errorPage, (state: any, hasError: boolean) => {
    return { ...state, hasError };
  })
  .build();

export const SystemDuck = {
  actions,
  reducer: SystemReducer,
};
