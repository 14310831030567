import { FC, useState, useEffect } from "react";
import { PrimaryNavigation, SecondaryNavigation } from "../AppNavigation";
import logo from "../../assets/images/HubbellConnectLogo2024.svg";
import UserMenuItem from "../UserMenuItem";
import UserAccountMenuItem from "../UserAccountMenuItem";
import Page from "../PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/pro-light-svg-icons";
import IdleUserComponent from "../IdleUserComponent";
import UserSessionTokenPollerComponent from "../UserSessionTokenPollerComponent";
import EnvironmentIndicator from "../EnvironmentIndicator";
import ErrorBoundary from "../ErrorBoundary";
import { AuthenticatedTemplate } from "@azure/msal-react";
import CartActionEventListener from "features/OrderEntry/components/CartActionEventListener";
import Toast from "store/system/notifications/components/Toast";
import { useWindowWidth } from "@react-hook/window-size";
import { useLocation } from "react-router-dom";
import useSystem from "store/system/useSystem";
import useUserProfile from "store/system/useUserProfile";
import { ServiceMessages } from "components/SystemServiceMessages";
import DownloadManager from "components/DownloadManager";
import BetaToggleHeader from "components/BetaToggle";

const AuthenticatedPage: FC<{ children: any }> = (props): JSX.Element => {
  const { state: system } = useSystem();
  const { state: user } = useUserProfile();
  const [sideBarState, setSidebarState] = useState(false);
  const toggleSidebar = () => {
    setSidebarState(!sideBarState);
  };
  const windowWidth = useWindowWidth();
  const location = useLocation();

  useEffect(() => {
    if (windowWidth < 811) {
      setSidebarState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <AuthenticatedTemplate>
      <Page className={sideBarState ? "max" : ""}>
      <div className="headerAndBanner">
      <Page.Header>
          <button
            className="btn btn-text btn-sm btn-toggleSidebar"
            onClick={toggleSidebar}
          >
            <FontAwesomeIcon icon={faBars} fixedWidth />
          </button>
          <div className="appLogo">
            <img src={logo} alt="Hubbell Connect" />
          </div>
          <EnvironmentIndicator />
          <div className="headerNav" translate="no">
            <div className="primaryNav"></div>
            <div className="secondaryNav">
              <ErrorBoundary>
                <CartActionEventListener
                  isLoading={
                    system.isReady === false || user.initialized === false
                  }
                />
              </ErrorBoundary>
              <ErrorBoundary>
                <UserAccountMenuItem
                  isLoading={
                    system.isReady === false || user.initialized === false
                  }
                />
              </ErrorBoundary>
              <ErrorBoundary>
                {windowWidth > 549 && <UserMenuItem />}
              </ErrorBoundary>
            </div>
          </div>
        </Page.Header>
        {/** beta toggle switch to go from V1 to V2 */}
        <BetaToggleHeader />
        </div>

        <Page.Sidebar>
          <div id="sidebar">
            <div id="sidebarHeader">
              <button
                className="btn btn-text btn-sm btn-toggleSidebar"
                onClick={toggleSidebar}
              >
                <FontAwesomeIcon icon={faTimes} fixedWidth />
              </button>
              <div className="appLogo">
                <img src={logo} alt="Hubbell Connect" />
              </div>
              <EnvironmentIndicator />
              {windowWidth < 550 && (
                <ErrorBoundary>
                  <UserMenuItem />
                </ErrorBoundary>
              )}
            </div>
            <ErrorBoundary>
              <PrimaryNavigation />
            </ErrorBoundary>
            <ErrorBoundary>
              <SecondaryNavigation />
            </ErrorBoundary>
          </div>
        </Page.Sidebar>

        <Page.Body>
          <ServiceMessages />
          {props.children}
          <Toast />
        </Page.Body>

        <Page.Footer>
          <></>
        </Page.Footer>
        {process.env.REACT_APP_ENABLE_IDLE_TIMER === "true" && (
          <>
            <IdleUserComponent />
            <UserSessionTokenPollerComponent />
          </>
        )}
        <DownloadManager />
      </Page>
    </AuthenticatedTemplate>
  );
};
export default AuthenticatedPage;
