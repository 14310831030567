import { ControlledTableState } from "features/common/components/Table/models";
import { AsyncStateObject } from "../../../../store/AsyncStateObject";
import { InvoiceSearchResult } from "./InvoiceSearchResult";
import SearchTypes from "./SearchTypes";

export const invoiceSearchState = {};

export interface InvoiceSearchQueryState {
  type: SearchTypes | undefined;
  accountNumber: string | undefined;
  fromDate: string | undefined;
  toDate: string | undefined;
  productNumber: string | undefined;
  orderNumber: string | undefined;
  purchaseOrderNumber: string | undefined;
  invoiceNumber: string | undefined;
  isCustomerProductNumber: boolean | undefined;
  brand: { salesOrg: string | undefined; division: string | undefined };
}

export default interface InvoiceSearchState {
  query: InvoiceSearchQueryState;
  searchResult: AsyncStateObject<InvoiceSearchResult[]>;
  tableState: ControlledTableState | undefined;
}

export const initialInvoiceSearchQueryState: InvoiceSearchQueryState = {
  type: SearchTypes.InvoiceNumber,
  accountNumber: undefined,
  fromDate: undefined,
  toDate: undefined,
  productNumber: undefined,
  orderNumber: undefined,
  purchaseOrderNumber: undefined,
  invoiceNumber: undefined,
  isCustomerProductNumber: undefined,
  brand: { salesOrg: undefined, division: undefined },
};
