import { Dictionary } from "models/Indexable";
import ApiService from "services/ApiService";
import { CountryCode } from "store/system/constants";

const subscriptionKeyHeader = process.env.REACT_APP_APIM_CORE_KEY_HEADER_NAME;
const subscriptionKeyValue = process.env.REACT_APP_APIM_CORE_KEY;

export abstract class AbstractService extends ApiService {
  // there is no qa for this endpoint
  protected baseUrl =
    process.env.REACT_APP_APIM_CORE_BASE?.replace("/qa", "") + "/dataquality";

  getAuthorizationHeaders = async (): Promise<{ [key: string]: any }> => {
    const headers: Dictionary = {};
    let token = await this.authorize();
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
      headers[`${subscriptionKeyHeader}`] = subscriptionKeyValue;
      headers["Country"] = CountryCode;
    }
    return headers;
  };
}
