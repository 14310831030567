import { sub, isBefore } from "date-fns";
import { Dispatch } from "redux";
import { AsyncActionCreators, ActionCreator } from "typescript-fsa";

const DataRefreshPeriodInMinutes = 15;

export function wrapAsyncWorker<TParameters, TSuccess, TError>(
  asyncAction: AsyncActionCreators<TParameters | undefined, TSuccess, TError>,
  worker: (dispatch: Dispatch, params?: TParameters) => Promise<TSuccess>
) {
  return function wrappedWorker(
    dispatch: Dispatch,
    params?: TParameters
  ): Promise<TSuccess | void> {
    dispatch(asyncAction.started(params));
    return worker(dispatch, params).then(
      (result) => {
        dispatch(asyncAction.done({ params, result }));
        return result;
      },
      (error: TError) => {
        dispatch(asyncAction.failed({ params, error }));
      }
    );
  };
}

export function wrapWorker<TParameters>(
  action: ActionCreator<TParameters>,
  worker: (dispatch: Dispatch, params: TParameters) => TParameters
) {
  return function wrappedWorker(
    dispatch: Dispatch,
    params: TParameters
  ): TParameters {
    dispatch(action(params));
    return worker(dispatch, params);
  };
}

export const hashExpired = (
  hash: string | undefined,
  dataRefreshPeriodInMinutes?: number
): boolean => {
  const timestamp = parseInt(hash as string);
  if (isNaN(timestamp)) {
    return false;
  }
  return dataExpired(new Date(timestamp), dataRefreshPeriodInMinutes);
};

export const dataExpired = (
  currentRefresh: Date,
  dataRefreshPeriodInMinutes?: number
): boolean => {
  let latestRefreshAllowed = sub(new Date(), {
    minutes: dataRefreshPeriodInMinutes || DataRefreshPeriodInMinutes,
  });
  let isStale = isBefore(currentRefresh, latestRefreshAllowed);
  return isStale;
};

export const createStringHash = (...args: any[]): string => {
  let result = "";
  args.map((x, i) => (result += args[i]));
  return result;
};

export const isNewTableFilters = (
  currentFilters: Array<{ id: string; value: any }>,
  newFilters: Array<{ id: string; value: any }>
): boolean => {
  if (currentFilters?.length !== newFilters?.length) {
    return true;
  }
  let isNew = false;
  for (let i = 0; i < newFilters.length && !isNew; i++) {
    let newFilterId = newFilters[i].id;
    let index = currentFilters.findIndex(
      (val: { id: string; value: any }) => val.id === newFilterId
    );
    if (index === -1) {
      isNew = true;
    } else {
      let newFilterValues = newFilters[i].value as Array<string>;
      let currentFilterValues = currentFilters[index].value as Array<string>;
      if (currentFilterValues.length !== newFilterValues.length) {
        isNew = true;
      } else {
        isNew = !newFilterValues.every((newVal: string) =>
          currentFilterValues.includes(newVal)
        );
      }
    }
  }
  return isNew;
};

export const isNewSelectedRows = (
  currentSelections: Record<string, boolean>,
  newSelections: Record<string, boolean>
): boolean => {
  let currentKeys = Object.keys(currentSelections);
  let newKeys = Object.keys(newSelections);
  if (currentKeys.length !== newKeys.length) {
    return true;
  }

  let isNew = !newKeys.every((newVal: string) => currentKeys.includes(newVal));
  return isNew;
};

export const isNewTableSortBy = (
  currentSortBy: Array<{ id: string; desc?: boolean }>,
  newSortBy: Array<{ id: string; desc?: boolean }>
): boolean => {
  if (currentSortBy.length !== newSortBy.length) {
    return true;
  }
  let isNew = false;
  for (let i = 0; i < newSortBy.length && !isNew; i++) {
    let newSortById = newSortBy[i].id;
    let index = currentSortBy.findIndex(
      (val: { id: string; desc?: boolean }) => val.id === newSortById
    );
    isNew = index === -1 || currentSortBy[index].desc !== newSortBy[i].desc;
  }
  return isNew;
};


export const mapSiteNameforCountry = (countryCode: string): string =>{
let siteName = ""

switch(countryCode) {
  case "CA" : siteName = "hubbellcanada";
  break;
  default: siteName = "hubbell";
}

return siteName;
}