import {
  RequestStatus,
  UserType,
  AccountValidationResponse,
  UserCustomerRequest,
  Approval,
  RequestActions,
  CustomerRequest,
  AccountLevelRequest,
  BrandLevelRequest,
  CustomerBrandRequest,
} from "../models/Admin";
import ApiService, { ApiCancelTokenSource } from "./ApiService";
import {
  ApplicationUserInterface,
  BrandRequest,
  CustomerAccountRequest,
  RouteDefinition,
} from "./ApiServiceInterfaces";
import { AxiosError, AxiosResponse } from "axios";
import { UpdateRequest } from "../modules/Admin_AccountRequestManagement/AccountRequestServiceStore";

const connectBaseURL: any = process.env.REACT_APP_HUBBELLCONNECT_API_BASE;
const getIconsURL: string = connectBaseURL + "Icon";
const functionModuleURL: string = connectBaseURL + "FunctionModule";
const userFunctionModuleURL: string = connectBaseURL + "UserFunctionModule";
const coreAPIM_IdentityFuncBase = process.env.REACT_APP_IDENTITYFUNC_BASE;
const coreAPIM_IdentityBase = process.env.REACT_APP_IDENTITYAPI_BASE;
const coreAPIM_Header = process.env.REACT_APP_APIM_CORE_KEY_HEADER_NAME;
const coreAPIM_Key = process.env.REACT_APP_APIM_CORE_KEY;

class AdminService extends ApiService {
  async setUserStatus(
    userName: string,
    status: boolean
  ): Promise<ApplicationUserInterface> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    let apiUrl =
      connectBaseURL +
      `ApplicationUser/SetStatus/${encodeURIComponent(userName)}/${status}`;

    const result = await this.connection.put(
      apiUrl,
      {},
      {
        headers: headers,
      }
    );
    return result.data as ApplicationUserInterface;
  }

  async updateUserFunctionModules(
    userFunctionModule: [],
    userName: string,
    createdBy: string,
    cancelToken?: ApiCancelTokenSource
  ) {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();

    let functionModuleIds: any = [];
    userFunctionModule.forEach((r: any) => {
      functionModuleIds.push(r.functionModuleId);
    });

    return this.connection
      .post(userFunctionModuleURL, functionModuleIds, {
        params: { userName: userName, createdBy: createdBy },
        headers: headers,
        cancelToken: cancelToken?.token,
      })
      .then((r: AxiosResponse) => {
        if (r.status === 200) {
          return r.data;
        } else {
          throw new Error();
        }
      });
  }

  async icons(setIcons: Function) {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();

    this.connection
      .get(getIconsURL, {
        headers: headers,
      })
      .catch((c) => {
        throw c;
      })
      .then((r: any) => {
        if (r.data.length) {
          let allowedIcons = r.data.map((item: any) => {
            return {
              id: item.id,
              name: item.name,
              iconname: item.shortName,
            };
          });
          setIcons(allowedIcons);
        }
      });
  }

  async updateFunctionModule(
    module: RouteDefinition
  ): Promise<RouteDefinition> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();

    let response = await this.connection.post(functionModuleURL, module, {
      headers: headers,
    });
    if (response.status === 200) {
      return response.data as RouteDefinition;
    } else {
      throw new Error("Error updating module");
    }
  }

  async getUserTypes(): Promise<UserType[]> {
    let relativeUrl = "/usertypes";

    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    let result = await this.connection.get(relativeUrl, {
      baseURL: coreAPIM_IdentityBase,
      headers: headers,
    });
    return result.data as UserType[];
  }

  async getRequestStatuses(): Promise<RequestStatus[]> {
    let relativeUrl = "/requests/RequestStatuses";

    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let result = await this.connection.get(relativeUrl, {
      baseURL: coreAPIM_IdentityBase,
      headers: headers,
    });
    return result.data as RequestStatus[];
  }

  async validateAccountNumber(
    accountNumber: string,
    cancelToken?: ApiCancelTokenSource
  ): Promise<AccountValidationResponse> {
    let baseUrl = `${coreAPIM_IdentityFuncBase}/validation`;
    let relativeUrl = "/account/" + accountNumber;
    let query = {
      includeBrands: true,
    };

    if (accountNumber.startsWith("4")) {
      relativeUrl = "/groupingaccount/" + accountNumber;
    }

    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let result = await this.connection.get(relativeUrl, {
      baseURL: baseUrl,
      headers: headers,
      params: query,
      cancelToken: cancelToken?.token,
    });
    let accountResponse = result.data as AccountValidationResponse;
    if (result.status === 404) {
      accountResponse = {
        accountNumber,
        isValid: false,
        brands: [],
      };
    }
    return accountResponse;
  }
  async getSoldToRequestsByStatus(
    userId?: string,
    status?: string
  ): Promise<UserCustomerRequest[]> {
    if (!userId) userId = this.userName;
    let relativeUrl = "/requests?options=groupedbyaccount";
    let query = {
      status: status,
    };
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let result = await this.connection.get(relativeUrl, {
      baseURL: coreAPIM_IdentityBase,
      headers: headers,
      params: query,
    });
    let requests = result.data as UserCustomerRequest[];
    if (status)
      requests = requests.filter((req) => {
        return req.userId === userId;
      });
    return requests;
  }

  async getPendingSoldToRequestsForUser(userId: string): Promise<any> {
    let relativeUrl = "/requests/PendingRequests/" + userId;
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let result = await this.connection.get(relativeUrl, {
      baseURL: coreAPIM_IdentityBase,
      headers: headers,
    });
    let requests = result.data as UserCustomerRequest[];

    requests?.forEach((i) => {
      i.userAccountGroupRequestsCount = i.userAccountGroupRequests?.length || 0;
      i.userBrandGroupRequestsCount = i.userBrandGroupRequests?.length || 0;
      i.userCustomerBrandRequestsCount =
        i.userCustomerBrandRequests?.length || 0;
    });
    return requests;
  }

  async getSoldToRequestDetails(
    requestId: string
  ): Promise<UserCustomerRequest> {
    let relativeUrl = `/requests/${requestId}?options=groupedbyaccount`;
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    let result = await this.connection.get(relativeUrl, {
      baseURL: coreAPIM_IdentityBase,
      headers: headers,
    });

    return result.data as UserCustomerRequest;
  }

  flattenBrandsforApi = (request: UserCustomerRequest) => {
    let userBrandGroupRequests: BrandRequest[] = [];
    let userCustomerBrandRequests: BrandRequest[] = [];
    let userAccountGroupRequests: BrandRequest[] = [];
    request.userBrandGroupRequests.map((r) => {
      return userBrandGroupRequests.push({
        name: r.name,
        salesOrg: r.salesOrg,
        division: r.division,
        enabled: true,
        accountNumber: r.accountNumber as string,
      });
    });
    // eslint-disable-next-line
    request.userCustomerBrandRequests.map((r) => {
      userCustomerBrandRequests = userCustomerBrandRequests.concat(
        r.requests.map((re) => {
          return {
            name: re.name,
            salesOrg: re.salesOrg,
            division: re.division,
            enabled: true,
            accountNumber: re.accountNumber,
          } as BrandRequest;
        })
      );
    });
    // eslint-disable-next-line
    request.userAccountGroupRequests.map((r) => {
      userAccountGroupRequests = userAccountGroupRequests.concat(
        r.requests.map((re) => {
          return {
            name: re.name,
            salesOrg: re.salesOrg,
            division: re.division,
            enabled: true,
            accountNumber: re.accountNumber,
          } as BrandRequest;
        })
      );
    });
    return {
      ...request,
      userBrandGroupRequests,
      userCustomerBrandRequests,
      userAccountGroupRequests,
    };
  };

  async createSoldToRequest(request: CustomerAccountRequest) {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let result = await this.connection
      .post("requests", request, {
        baseURL: coreAPIM_IdentityBase,
        headers: headers,
      })
      .then((resp: AxiosResponse) => {
        return resp;
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          return error.response;
        } else {
          throw new Error(error.message);
        }
      });
    return result.status === 200;
  }

  async updateSoldToRequest(
    parentId: string,
    path: string | null,
    id: string,
    approval: Approval,
    callback?: () => void
  ) {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let relativeUrl =
      path === null
        ? `requests/${parentId}/status`
        : `requests/${parentId}/${path}/${id}`;
    let result = await this.connection
      .put(
        relativeUrl,
        {
          statusReason: approval.statusReason,
          status: approval.requestStatus.name,
        },
        {
          baseURL: coreAPIM_IdentityBase,
          headers: headers,
        }
      )
      .then((resp: AxiosResponse) => {
        return resp;
      })
      .catch((error: AxiosError) => {
        if (error.response?.data) {
          throw new Error(error.response.data as string);
        } else {
          throw new Error(error.message);
        }
      });
    return result.status === 200;
  }

  async getAccountRequestDetails(): Promise<CustomerRequest[]> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let relativeUrl = "requests";
    let query = {
      type: "allaccount",
    };
    let result = await this.connection
      .get(relativeUrl, {
        headers: headers,
        baseURL: coreAPIM_IdentityBase,
        params: query,
      })
      .then((result: any) => {
        return result;
      });
    return result.data as CustomerRequest[];
  }

  async getRequestActions(): Promise<RequestActions[]> {
    let relativeUrl = "/requests/StatusReasonCodes";

    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let result = await this.connection.get(relativeUrl, {
      baseURL: coreAPIM_IdentityBase,
      headers: headers,
    });
    return result.data as RequestActions[];
  }

  async getAccountDetails(id: string): Promise<AccountLevelRequest[]> {
    let relativeUrl = "/requests/" + id + "/accountrequests";

    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let result = await this.connection.get(relativeUrl, {
      baseURL: coreAPIM_IdentityBase,
      headers: headers,
    });
    return result.data as AccountLevelRequest[];
  }

  async updateRequests(
    requestId: string,
    updateRequest: UpdateRequest,
    callback?: () => void
  ) {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let relativeUrl = `requests/${requestId}/status`;
    let result = await this.connection
      .put(
        relativeUrl,
        {
          statusReason: updateRequest.requestParameters.statusReason,
          status: updateRequest.requestParameters.status,
        },
        {
          baseURL: coreAPIM_IdentityBase,
          headers: headers,
        }
      )
      .then((resp: AxiosResponse) => {
        return resp;
      });
    return result.status === 200;
  }

  async updateBrandStatusRequest(
    requestId: string,
    updateRequest: UpdateRequest,
    requestType: string,
    parentRequestId: string,
    callback?: () => void
  ) {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let relativeUrl = `requests/${parentRequestId}/${requestType}/${requestId}`;
    let result = await this.connection
      .put(
        relativeUrl,
        {
          statusReason: updateRequest.requestParameters.statusReason,
          status: updateRequest.requestParameters.status,
        },
        {
          baseURL: coreAPIM_IdentityBase,
          headers: headers,
        }
      )
      .then((resp: AxiosResponse) => {
        return resp;
      });
    return result.status === 200;
  }
  async updateAccountStatusRequest(
    requestId: string,
    accountNumber: string,
    updateRequest: UpdateRequest,
    callback?: () => void
  ) {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let relativeUrl = `requests/${requestId}/byaccount/${accountNumber}/status`;
    let result = await this.connection
      .put(
        relativeUrl,
        {
          statusReason: updateRequest.requestParameters.statusReason,
          status: updateRequest.requestParameters.status,
        },
        {
          baseURL: coreAPIM_IdentityBase,
          headers: headers,
        }
      )
      .then((resp: AxiosResponse) => {
        return resp;
      });
    return result.status === 200;
  }
  async updateUserProfile(
    userId: string,
    companyName: string,
    phoneNumber: string,
    country:string,
    userType: UserType,
    callback?: () => void
  ) {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let relativeUrl = `users/${userId}`;
    let result = await this.connection
      .put(
        relativeUrl,
        {
          companyName: companyName,
          phoneNumber: phoneNumber,
          country:country,
          userType: userType.name,
        },
        {
          baseURL: coreAPIM_IdentityBase,
          headers: headers,
        }
      )
      .then((resp: AxiosResponse) => {
        return resp;
      });
    return result.status === 200;
  }

  async updatePhoneandCompany(
    userId: string,
    companyName: string | undefined,
    phoneNumber: string | undefined,
    country: string | undefined,
    callback?: () => void
  ) {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let relativeUrl = `users/${userId}`;
    let parameters: { [key: string]: any } = {};

      if (companyName !== undefined && companyName !== null) {
        parameters.companyName = companyName;
      }
      if (phoneNumber !== undefined && phoneNumber !== null) {
        parameters.phoneNumber = phoneNumber;
      }
      if (country !== undefined && country !== null) {
        parameters.country = country;
      }
    
    let result = await this.connection
      .put(relativeUrl, parameters, {
        baseURL: coreAPIM_IdentityBase,
        headers: headers,
      })
      .then((resp: AxiosResponse) => {
        return resp;
      });
    console.log("sowmya..userProfile", result);
    return result.status === 200;
  }

  // Brand Request management service calls
  async getBrandRequestDetails(): Promise<CustomerBrandRequest[]> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let relativeUrl = "requests";
    let query = {
      type: "brandgroup",
    };
    let result = await this.connection
      .get(relativeUrl, {
        headers: headers,
        baseURL: coreAPIM_IdentityBase,
        params: query,
      })
      .then((result: any) => {
        return result;
      });
    return result.data as CustomerBrandRequest[];
  }

  async getBrandDetails(id: string): Promise<BrandLevelRequest[]> {
    let relativeUrl = "/requests/" + id + "/brandrequests";

    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let result = await this.connection.get(relativeUrl, {
      baseURL: coreAPIM_IdentityBase,
      headers: headers,
    });
    return result.data as BrandLevelRequest[];
  }

  async updateBrandChildRequest(
    requestId: string,
    updateRequest: UpdateRequest,
    parentRequestId: string,
    callback?: () => void
  ) {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let relativeUrl = `requests/${parentRequestId}/userbrandgroup/${requestId}`;
    let result = await this.connection
      .put(
        relativeUrl,
        {
          statusReason: updateRequest.requestParameters.statusReason,
          status: updateRequest.requestParameters.status,
        },
        {
          baseURL: coreAPIM_IdentityBase,
          headers: headers,
        }
      )
      .then((resp: AxiosResponse) => {
        return resp;
      });
    return result.status === 200;
  }
}
export default AdminService;
