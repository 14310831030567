//import store from "store";
import { store } from "index";
import {
  addNotificationAction,
  clearAllNotificationsAction,
  removeNotificationAction,
  updateNotificationAction,
} from "./actions";
import Notification from "./models/Notification";
require("./reducers");

export const addNotification = (notification: Notification) => {
  store.dispatch(addNotificationAction(notification));
};

export const removeNotification = (id: string) => {
  store.dispatch(removeNotificationAction(id));
};

export const updateNotification = (
  toUpdate: { id: string } & Partial<Notification>
) => {
  store.dispatch(updateNotificationAction(toUpdate));
};

export const clearNotifications = () => {
  store.dispatch(clearAllNotificationsAction());
};
