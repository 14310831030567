import ApiService from "./ApiService";
import { ResponseData } from "./models/TypedResponse";

const baseURL = process.env.REACT_APP_CONNECTQUEUE_BASE;
const keyName = process.env.REACT_APP_QUEUEFUNCTIONS_KEYNAME;
const downloadRequestKey = process.env.REACT_APP_DOWNLOADFUNCTION_KEYVALUE;
const downloadStatusKey = process.env.REACT_APP_DOWNLOADSTATUS_KEYVALUE;

export interface DownloadRequest {
  accountNumber: string;
  documentType: string;
  lineItemNumbers: Number[];
  ApplicationUserId: string;
  salesOrderNumber: string;
  UserName: string;
  quoteNumbers: string[];
}

export interface DownloadResponse {
  Value: {
    MessageId: string;
    InsertionTime: string;
    ExpirationTime: string;
    PopReceipt: string;
    TimeNextVisible: string;
  };
}

export interface DownloadStatus {
  Id: string;
  Status: string;
  DownloadUrl: string | null;
  Error?: string;
}

export enum DocumentTypes {
  acknowledgement = "acknowledgement",
  invoice = "invoice",
  shipment = "shipment",
  packing = "packing_slip",
  Advancedshipnotice = "advanced_ship_notice",
  quoteDetail = "quotedetail",
  rmaNumber = "rmaNumber",
}

class DownloadFIleService extends ApiService {
  async downloadFileRequest(
    requestBody: Partial<DownloadRequest>,
    abortSignal?: AbortSignal
  ): Promise<ResponseData<DownloadResponse>> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    const params: { [key: string]: any } = {};
    if (keyName !== undefined) params[keyName] = downloadRequestKey;
    headers["Authorization"] = `Bearer ${token}`;
    let relativeURL = "/api/QueueDocumentsToDownloadHttpTrigger";
    return await this.connection.post(relativeURL, requestBody, {
      headers: headers,
      baseURL: baseURL,
      params: params,
      signal: abortSignal,
    });
  }

  async downloadStatus(id: string, abortSignal?: AbortSignal): Promise<any> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    headers["Authorization"] = `Bearer ${token}`;
    let relativeURL =
      "/api/DownloadStatus/" + id + "?code=" + downloadStatusKey;
    return await this.connection.get(relativeURL, {
      headers: headers,
      baseURL: baseURL,
      signal: abortSignal,
    });
  }
}

export default DownloadFIleService;
