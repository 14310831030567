import IdentityAddress from "services/models/IdentityAddress";
import { AbstractService } from "./AbstractService";
import { InvoiceDetailResponse } from "features/InvoiceSearch/store/models/InvoiceSearchQuery";

export namespace Invoice {
  export namespace Input {
    export interface SearchByInvoiceNumber {
      accountNumber: string;
      brand?: string;
      invoiceNumber?: string;
    }
    export interface SearchByOrderNumber {
      accountNumber: string;
      brand?: string;
      purchaseOrderNumber?: string;
      salesOrderNumber?: string;
    }
    export interface SearchByDateCreated {
      accountNumber: string;
      brand?: string;
      fromDate: Date;
      toDate: Date;
    }
    export interface SearchByProductNumber extends SearchByDateCreated {
      accountNumber: string;
      brand?: string;
      productNumber: string;
    }
  }

  export namespace Output {
    export type Search = {
      //invoices: Invoice[];
    };
    export interface Invoice {
      invoiceNumber: string;
      brand: string;
      salesOrg: string;
      division: string;
      printable: string;
      total: string;
      currency: string;
      type: string;
      shipTo: {
        name: string;
        city: string;
      };
      date: string;
      salesOrderNumber: string;
      purchaseOrderNumber: string;
      referenceNumber: string;
      destinationCountry: string;
      incoTerms: string;
      payTerms: string;
    }
    export type InvoiceDetail = {
      invoiceNumber: string;
      accountNumber: string;
      purchaseOrderNumber: string;
      invoiceDate: string;
      invoiceTotal: string;
      currency: string;
      brand: {
        salesOrg: string;
        division: string;
        name: string;
      };
      soldTo: {
        name: string;
        address: {
          houseNumber: string;
          street: string;
          poBox: {
            number: string;
            city: string;
            region: string;
            postal: string;
            country: string;
          };
          deliveryLine: string;
          city: string;
          region: string;
          postal: string;
          country: string;
        };
      };
      shipTo: {
        name: string;
        address: {
          houseNumber: string;
          street: string;
          poBox: {
            number: string;
            city: string;
            region: string;
            postal: string;
            country: string;
          };
          deliveryLine: string;
          city: string;
          region: string;
          postal: string;
          country: string;
        };
      };
      items: Item[];
      customerNotes: Note[];
      internalNotes: Note[];
    };
    export type Item = {
      itemNumber: number;
      lineItem: string;
      yourLine: string;
      netPrice: string;
      currency: string;
      mfrItemNumber: string;
      description: string;
      customerMaterialNumber: string;
      invoiceQuantity: string;
      su: string;
      shippingPoint: string;
      commodityCode: string;
      upcCode: string;
      deliveryNumber: string;
      carrier: string;
      carrierProNumber: string;
      weight: string;
    };
    export interface Note {
      note: string;
    }
    export interface Brand {
      division: string;
      salesOrg: string;
      name: string;
    }
    export interface Address extends Partial<IdentityAddress> {
      houseNumber: string;
      street: string;
      poBox: {
        number: string;
        city: string;
        region: string;
        postal: string;
        country: string;
      };
      deliveryLine: string;
    }
  }

  export class Service extends AbstractService {
    /**
     *
     * @description Legacy search api does not include shared parts
     * @param query
     * @param {AbortSignal|undefined} abortSignal
     * @returns
     */
    // TO DO: fix to work with invoice search and API
    /* async search(
          query:
          | Input.SearchByInvoiceNumber
          | Input.SearchByDateCreated
          | Input.SearchByProductNumber,
          abortSignal?: AbortSignal
        ) {
          const headers = await this.getAuthorizationHeaders();
          let result = await this.connection.get("invoiceSearch/search", {
            baseURL: this.baseUrl,
            headers: headers,
            params: query,
            signal: abortSignal,
          });
          let data = result.data?.invoices?.filter(
            (o: Output.Invoice) => o.invoiceNumber !== null
          );
          return data;
        }*/

    /**
     *
     * @description New search api includes shared parts
     * @param query
     * @param {AbortSignal|undefined} abortSignal
     * @returns
     */
    async searchAll(
      query:
        | Input.SearchByInvoiceNumber
        | Input.SearchByDateCreated
        | Input.SearchByOrderNumber
        | Input.SearchByProductNumber,
      abortSignal?: AbortSignal
    ): Promise<Output.Search> {
      // TO DO: fix to work with invoice search and API
      const headers = await this.getAuthorizationHeaders();

      let q: any = Object.assign({}, query, {
        division: query.brand?.split("_")[1],
        salesOrg: query.brand?.split("_")[0],
      });
      delete q.type;
      delete q.brand;

      let result = await this.connection
        .get("invoices/search", {
          baseURL: this.commerceUrl,
          headers: headers,
          params: q,
          signal: abortSignal,
        })
        .catch((e) => {
          if (e.response.status === 401 && query) {
            throw new Error(
              `User is not authorized to access invoice ${query}`
            );
          } else if (e.response?.data?.detail) {
            throw new Error(e.response.data.detail);
          } else {
            throw e;
          }
        });
      return result.data;
    }

    /**
     *
     * @param invoiceNumber
     * @param {AbortSignal|undefined} abortSignal
     * @returns
     */
    async getInvoiceDetails(
      invoiceNumber: string,
      abortSignal?: AbortSignal
    ): Promise<InvoiceDetailResponse> {
      const headers = await this.getAuthorizationHeaders();
      let result = await this.connection.get(`Invoice/${invoiceNumber}`, {
        baseURL: this.baseURlSAP,
        headers: headers,
        signal: abortSignal,
      });

      return result.data;
    }

    /**
     *
     * @param invoiceNumber
     * @param {AbortSignal|undefined} abortSignal
     * @returns
     */
    async getTermsOfSale(
      invoiceNumber: string,
      abortSignal?: AbortSignal
    ): Promise<InvoiceDetailResponse> {
      const headers = await this.getAuthorizationHeaders();
      let result = await this.connection.get(`invoiceterms/${invoiceNumber}`, {
        baseURL: this.baseUrl,
        headers: headers,
        signal: abortSignal,
      });

      return result.data;
    }
  }
}
