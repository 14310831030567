import { FC } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { removeNotification } from "../dispatchers";
import useNotifications from "../useNotifications";
import "./style.scss";
import { PublishNotificationDismissedEvent } from "../helpers";
import NotificationLevel from "../models/NotificationLevel";

const HubbellToast: FC = (): JSX.Element => {
  const { state: notifications } = useNotifications();
  return (
    <ToastContainer position="top-end" className="hubbell-toast-stack">
      {notifications?.map((n) => (
        <Toast
          key={n.id}
          className={`hubbell-notification`}
          bg={n.level || NotificationLevel.DEFAULT}
          show={true}
          onClose={() => {
            PublishNotificationDismissedEvent({ id: n.id });
            removeNotification(n.id);
          }}
        >
          {n.title && (
            <Toast.Header closeButton={n.allowDismiss}>
              <strong>{n.title}</strong>
            </Toast.Header>
          )}
          {n.message && <Toast.Body>{n.message}</Toast.Body>}
        </Toast>
      ))}
    </ToastContainer>
  );
};

export default HubbellToast;
