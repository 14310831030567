import { LocalStorage } from "store/localStorage";
import CommerceApi from "./CommerceApi";
import { HybrisApi } from "./HybrisApi";
import ProductDetail from "./HybrisApi/models/ProductDetail";

// Convenience function to get the step id for a given material number
// then use that id to fetch the product detail data.
// Returns undefined if not found
// Caches result in localStorage
export async function getProductDetailForMaterial(
  query: CommerceApi.Material.Input.MaterialToStepId,
  abortSignal?: AbortSignal
) {
  const storageKey = `${query.materialNumber}_${query.salesOrg}_${query.division}-productDetail`;
  // check for cached version first
  let local = LocalStorage.loadSerialized(storageKey);
  if (local) {
    return local;
  }
  let productDetailData: undefined | ProductDetail = undefined;
  // fetch step id
  const commerceApiService = new CommerceApi.Material.Service();
  const stepData = await commerceApiService.materialToStepId(
    query,
    abortSignal
  );
  // fetch product data from hybris
  if (stepData) {
    const hybrisApiService = new HybrisApi.Service();
    productDetailData = await hybrisApiService.productDetail(
      stepData.stepId,
      abortSignal
    );
    LocalStorage.saveSerialized(storageKey, productDetailData);
  }
  // cache result
  return productDetailData;
}
