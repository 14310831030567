import { ApiCancelTokenSource } from "services/ApiService";
import { AbstractService } from "./AbstractService";

export namespace Material {
  export const isMaterialSearchResult = (x: any): x is Output.MaterialSearch =>
    typeof x === "object" && "PIMIndicator" in x;

  export namespace Input {
    export interface MaterialToStepId {
      materialNumber: string;
      salesOrg: string;
      division: string;
      cancelTokenSource?: ApiCancelTokenSource;
    }
    export interface MaterialSearch {
      value: string;
      customerAccountNumber: string;
    }
  }
  export namespace Output {
    export type materialToStepId = {
      stepId: string;
      salesOrg: string;
      division: string;
      materialNumber: string;
    };

    export interface MaterialSearch {
      materialNumber: string;
      materialDescription: string;
      salesOrg: string;
      division: string;
      brandName: string;
      PIMIndicator: string;
    }
  }
  export class Service extends AbstractService {
    /**
     *
     * @param {string} query.salesOrg
     * @param {string} query.division
     * @param {AbortSignal|undefined} signal
     * @returns Promise<Output.materialToStepId> returns 404 if material not found
     */
    async materialToStepId(
      query: Input.MaterialToStepId,
      signal?: AbortSignal
    ): Promise<Output.materialToStepId> {
      const headers = await this.getAuthorizationHeaders();
      const params = {
        salesOrg: query.salesOrg,
        division: query.division,
        materialNumber: query.materialNumber,
      };
      const url = "material/stepid";
      let r = await this.connection.post(url, params, {
        baseURL: this.baseUrl,
        headers: headers,
        signal: signal,
      });
      return r.data;
    }

    // consolidated catalog/material search with shared parts
    /**
     *
     * @param {string} query.value
     * @param {string} query.customerAccountNumber
     * @param {AbortSignal|undefined} signal
     * @returns Promise<Output.MaterialSearch>
     */
    async materialSearch(
      query: Input.MaterialSearch,
      signal?: AbortSignal
    ): Promise<Output.MaterialSearch[]> {
      const headers = await this.getAuthorizationHeaders();

      let url = "materials/search";
      let baseUrl = this.baseUrl;

      let apiData = {
        accountNumber: query.customerAccountNumber,
        searchTerm: query.value,
      };

      if (!url) throw new Error("API URL undefined");
      const result = await this.connection.post(url, apiData, {
        baseURL: baseUrl,
        headers: headers,
        signal: signal,
      });
      return result.data;
    }
  }
}
