export namespace LocalStorage {
  export function loadSerialized(key: string) {
    try {
      const serializedState = localStorage.getItem(key);
      if (!serializedState) return undefined;
      return JSON.parse(serializedState);
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  export async function saveSerialized(key: string, state: any) {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(key, serializedState);
    } catch (e) {
      console.error(e);
    }
  }

  export function load(key: string) {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  export function save(key: string, value: any) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.error(e);
    }
  }

  export function remove(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.error(e);
    }
  }

  export function clear() {
    try {
      localStorage.clear();
    } catch (e) {
      console.error(e);
    }
  }
}
