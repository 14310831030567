import { selectNotifications } from "./selectors";
import Notification, {
  NotificationDismissedEvent,
} from "./models/Notification";
import {
  addNotification,
  removeNotification,
  updateNotification,
} from "./dispatchers";
import { publish, subscribe, unsubscribe } from "services/EventsHelper";

export function Notify(notification: Notification) {
  const notifications = selectNotifications();

  let match = notifications.find((n) => n.id === notification.id);
  if (match) {
    if (notification.message !== match.message)
      updateNotification(notification);
  } else {
    addNotification(notification);
  }
}

export function DeNotify(id: string) {
  const notifications = selectNotifications();

  if (notifications.find((n) => n.id === id) !== undefined) {
    removeNotification(id);
  }
}

export function AddNotificationDismissedListener(listener: (e: Event) => void) {
  subscribe(NotificationDismissedEvent, listener);
}

export function RemoveNotificationDismissedListener(
  listener: (e: Event) => void
) {
  unsubscribe(NotificationDismissedEvent, listener);
}

export function PublishNotificationDismissedEvent(data: any) {
  publish(NotificationDismissedEvent, data);
}
