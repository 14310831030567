import { ControlledTableState } from "features/common/components/Table/models";
import { AsyncStateObject } from "store/AsyncStateObject";
import { RMA } from "./RmaSearchQuery";

// must serialize dates manually for redux state
export interface RmaSearchQueryState {
  accountNumber?: string | undefined;
  fromDate: Date | undefined; // ISO date string
  toDate: Date | undefined; // ISO date string
}

export default interface RmaSearchState {
  query: RmaSearchQueryState;
  searchResult: AsyncStateObject<RMA[]>;
  tableState: ControlledTableState | undefined;
}

export const initialRmsSearchQueryState: RmaSearchQueryState = {
  accountNumber: undefined,
  fromDate: undefined,
  toDate: undefined,
};

