import { ResponseData } from "services/models/TypedResponse";
import ApiService, { ApiError, ApiResponse } from "services/ApiService";
import {
  RMASearchResponse,
  RmaDetailDocumentQuery,
  RmaDetailResponse,
} from "../store/model/RmaSearchQuery";
//import ErrorResponse from "services/models/ErrorResponse";
import { RmaSearchQuery } from "../store/model/RmaSearchQuery";
import { FileHelper } from "services/FileHelper";
import Document from "services/models/Document";
//import { DocumentTypes } from "features/RmaDetail/constants";
const baseURL = process.env.REACT_APP_RMA_DETAIL_API;
const apiKey_Value = process.env.REACT_APP_RMA_DETAIL_DOCUMENT_KEYVALUE;
const clientID = "hubbellconnectapi";

class RMAService extends ApiService {
  async getRMABySearchString(
    query: Partial<RmaSearchQuery>,
    abortSignal?: AbortSignal
  ): Promise<RMASearchResponse> {
    const headers = await this.getAuthorizationHeaders();

    let result = await this.connection.get("rma/search", {
      baseURL: baseURL,
      headers: headers,
      params: query,
      signal: abortSignal,
    });
    return result.data;
  }

  /**
   *
   * @param rmaNumber
   * @param {AbortSignal|undefined} abortSignal
   * @returns
   */
  async getRMADetails(
    rmaNumber: string,
    abortSignal?: AbortSignal
  ): Promise<RmaDetailResponse> {
    const headers = await this.getAuthorizationHeaders();
    let result = await this.connection.get(`rma/${rmaNumber}`, {
      baseURL: baseURL,
      headers: headers,
      signal: abortSignal,
    });

    return result.data;
  }

  /**
   *
   * @param rmaNumber
   * @param {AbortSignal|undefined} abortSignal
   * @returns
   */
  async getTermsOfSale(
    rmaNumber: string,
    abortSignal?: AbortSignal
  ): Promise<RmaDetailResponse> {
    const headers = await this.getAuthorizationHeaders();
    let result = await this.connection.get(`rmaterms/${rmaNumber}`, {
      baseURL: baseURL,
      headers: headers,
      signal: abortSignal,
    });

    return result.data;
  }

  /**
   *
   * @param rmaNumber
   * @param {AbortSignal|undefined} abortSignal
   * @returns
   */
  async downloadRmaDocument(
    query: RmaDetailDocumentQuery,
    abortSignal?: AbortSignal
  ): Promise<ResponseData<Document>> {
    let downloadApiRelPath = `rma/${query.rmaNumber}/pdf?accountNumber=${query.accountNumber}`;

    const headers = await this.getAuthorizationHeaders();
    if (headers) {
      headers["ApiKey"] = apiKey_Value;
      headers["ClientId"] = clientID;
    }

    let result = await this.connection
      .get(downloadApiRelPath, {
        baseURL: baseURL,
        headers: headers,
        responseType: "blob",
        signal: abortSignal,
      })
      .then((resp: ApiResponse) => {
        return resp;
      })
      .catch((error: ApiError) => {
        if (error.response) {
          return error.response;
        } else {
          throw new Error(error.message);
        }
      });
    let fileHelper = new FileHelper<Document>();
    let downloadResponse = await fileHelper
      .parseDownloadApiResponse(query.rmaNumber, result)
      .then((downloadResponse) => {
        return downloadResponse;
      })
      .catch((error: Error) => {
        throw error;
      });
    return downloadResponse;
  }

  async emailDocuments(
    email: string[],
    query: RmaDetailDocumentQuery,
    senderEmail: string,
    cc?: string[],
    abortSignal?: AbortSignal
  ): Promise<ResponseData<any>> {
    throw new Error("Endpoint not implemented"); /*
    let apiBaseUrl = process.env.REACT_APP_QUEUEFUNCTIONS_BASE;
    let emailApiRelPath = `api/QueueDocumentsToEmailHTTPTrigger?${process.env.REACT_APP_QUEUEFUNCTIONS_KEYNAME}=${process.env.REACT_APP_QUEUEFUNCTIONS_KEYVALUE}`;
    let apiData = {
      accountNumber: query.accountNumber,
      to: email,
      cc: cc,
      documentType: DocumentTypes.invoice,
      rmaNumber: query.rmaNumber,
      senderEmail: senderEmail,
    };
    const headers = await this.getAuthorizationHeaders();
    
    let result = await this.connection
      .post(emailApiRelPath, JSON.stringify(apiData), {
        baseURL: apiBaseUrl,
        headers: headers,
        signal: abortSignal,
      })
      .then((resp: ApiResponse) => {
        
        return resp;
      })
      .catch((error: ApiError) => {
        throw new Error(error.message);
      });

    let emailResult = undefined;
    if (result.status === 200) {
      emailResult = {
        error: undefined,
        results: "Requested document(s) will be emailed shortly.",
      };
    } else {
      emailResult = {
        error: result.data as ErrorResponse,
        results: undefined,
      };
      if (emailResult.error && emailResult.error.detail) {
        let details = JSON.parse(emailResult.error.detail) as string[];
        if (details && details.length > 0) {
          emailResult.error.detail = details.join(". ");
        }
      }
    }
    return emailResult;
    */
  }
}

export default RMAService;
