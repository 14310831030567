// For a full list of msal.js configuration parameters,

import { Configuration, RedirectRequest } from "@azure/msal-browser";

// visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_B2C_CLIENT_ID as string,
    authority: process.env.REACT_APP_B2C_AUTHORITY_URI as string,
    knownAuthorities: [
      process.env.REACT_APP_B2C_AUTHORITY_ROOT as string,
      "hubbellb2c.b2clogin.com",
      "login.microsoftonline.com",
    ],
    redirectUri: "/",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Coordinates and required scopes for your web api
export const apiConfig = {
  resourceScope: process.env.REACT_APP_B2C_APICONFIG_RESOURCE_SCOPE as string,
  resetPasswordScope: process.env.REACT_APP_B2C_RESET_URI as string,
};

/**
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters,
 * visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const loginRequest = {
  scopes: ["openid", "offline_access"],
};

// Add here scopes for access token to be used at the API endpoints.
export const tokenRequest = {
  scopes: [apiConfig.resourceScope],
};

// Add here scopes for silent token request
export const silentRequest = {
  scopes: ["openid", apiConfig.resourceScope],
};

export const resetPasswordRequest: RedirectRequest = {
  scopes: ["openid", apiConfig.resetPasswordScope, "offline_access"],
  authority: apiConfig.resetPasswordScope,
};

export const getResetPasswordRequestPolicy = () => {
  let index = apiConfig.resetPasswordScope.lastIndexOf("/");
  return apiConfig.resetPasswordScope.slice(index + 1).toLowerCase();
};
