import { ApiResponse } from "./ApiService";
import ErrorResponse from "./models/ErrorResponse";
import { formatISO } from "date-fns";
import Document from "./models/Document";
import { ResponseData } from "./models/TypedResponse";

// eslint-disable-next-line
export class FileHelper<T> {
  getFileDataFromHeader = (id: string, result: any): Document | undefined => {
    let fileName = "";
    if (result.headers["content-disposition"] !== undefined) {
      fileName = result.headers["content-disposition"]
        .split(";")[1]
        .replace(" filename=", "");
    } else if (result.headers["content-type"] !== undefined) {
      if (
        result.headers["content-type"].startsWith("application/") === true &&
        !result.headers["content-type"].startsWith("application/json")
      ) {
        // application/json is the content-type assumed when server responds with text response error message
        // this really needs to be changed to a 404 error
        let fileExtension = result.headers["content-type"].split("/")[1];
        let timeStamp = formatISO(new Date());
        fileName = `Order${id}_Documents_${timeStamp}.${fileExtension}`;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }

    return {
      fileName: fileName,
      fileData: new Blob([result.data]),
    };
  };

  readBlobAsText = async (result: any): Promise<string> => {
    var fileContent = new FileReader();
    fileContent.readAsText(result.data);

    return new Promise((resolve, reject) => {
      fileContent.onload = () => {
        let resultText = fileContent.result as string;
        resolve(resultText);
      };
    });
  };

  statusCodeToText = (code: number, title: string): ErrorResponse => {
    switch (code) {
      case 400:
        return {
          error: code.toString(),
          message: "Bad Request",
          detail: title,
        };
      case 401:
        return {
          error: code.toString(),
          message: "Unauthorized",
          detail: "Unable to access resource.",
        };
      case 404:
        return {
          error: code.toString(),
          message: "Not found",
          detail: "Unable to access resource.",
        };
      case 500:
      case 503:
      default:
        return {
          error: code.toString(),
          message: "Error",
          detail:
            "An unknown error has occurred. Please contact the site administrator.",
        };
    }
  };

  parseDownloadApiResponse = async (
    id: string,
    result: ApiResponse
  ): Promise<ResponseData<Document>> => {
    let downloadResult: ResponseData<Document> = {
      error: undefined,
      results: undefined,
    };
    if (result.status === 200) {
      let orderDoc = this.getFileDataFromHeader(id, result);
      if (orderDoc !== undefined) {
        downloadResult.results = orderDoc;
      }
    }

    if (downloadResult.results === undefined) {
      if (
        (result.headers["content-length"] &&
          parseInt(result.headers["content-length"]) > 0) ||
        result.headers["content-type"]
      ) {
        //  server provides friendly error
        let resultText = undefined;
        try {
          resultText = await this.readBlobAsText(result).then((resultText) => {
            return resultText;
          });
          let error: { status: number; title: string } = JSON.parse(resultText);
          let errObj = this.statusCodeToText(
            Number(result.status),
            error.title
          );
          downloadResult.error = resultText
            ? { ...errObj, ...JSON.parse(resultText) }
            : errObj;
          return Promise.reject(downloadResult.error?.detail);
        } catch (e) {
          if (resultText) {
            return Promise.reject(resultText);
          } else {
            throw e;
          }
        }
      } else {
        // unhandled error
        throw new Error(result.statusText);
      }
    }
    return downloadResult;
  };
}
