import { format } from "date-fns";
import { AbstractService } from "./AbstractService";
import MaterialInventory from "services/models/MaterialInventory";
import IdentityApi from "services/IdentityApi";
import BrandItem from "services/models/BrandItem";

export namespace Materials {
  export const isCustomerMaterialLookupResult = (
    x: any
  ): x is Output.CustomerMaterialLookup =>
    typeof x === "object" && "customerMaterialNumber" in x;

  export interface MaterialResult {
    materialNumber: string | null;
    customerMaterialNumber: string | null;
    description: string | null;
    salesOrg: string | null;
    itemDivision: string | null;
    headerDivision: string | null;
    brand: BrandItem;
    itemCategory: string | null;
    minimumOrderQty: string | null;
    baseUnitOfMeasure: string | null;
    standardPackageQty: string | null;
    palletQty: string | null;
    caseQty: string | null;
    cartonQty: string | null;
    averageLeadTime: string | null;
    commodityCode: string | null;
    countryOfOrigin: string | null;
    quoteGroup: string | null;
    price: string | null;
    listPrice: string | null;
    tradePrice: string | null;
    priceUnit: string | null;
    currency: string | null;
    returnable: boolean;
    inventory: MaterialInventory;
    pricingAccessMessage: string | null;
    pricingNotes: string[] | null;
    weight: string | null;
    upc: string | null;
    itemType: string | null;
    grossWeight: string | null;
    grossWeightUnitOfMeasure: string | null;
  }

  export interface ServiceError {
    callDescription: string;
    materialNumber: string;
    type: string;
    code: string;
    text: string;
    logNumber: string;
    messageVars: Array<string>;
  }

  export interface SkippedMaterial {
    materialNumber: string;
    evaluationResults: Array<{
      message: string;
      callingFunction?: string | null;
      operationName?: string | null;
      reason?: string | null;
    }>;
    brandDetails: IdentityApi.Brands.Brand[] | null;
  }

  export interface ValidationResult {
    level: string; // @note: not in api payload???
    message: string;
    reason: string;
    callingFunction: string;
    operationName: string;
  }

  export namespace Input {
    export interface CustomerMaterialLookup {
      value: string;
      customerAccountNumber: string;
    }

    export interface PriceAndAvailability {
      materialNumber: string;
      salesOrg: string;
      division: string;
      quantity: number;
      customerAccountNumber: string;
      priceDate?: Date;
      quoteNumber?: string;
    }
  }

  export namespace Output {
    export interface CustomerMaterialLookup {
      materialNumber: string | null;
      customerMaterialNumber: string;
      customerNumber: string;
      salesOrg: string | null;
    }

    export interface PriceAndAvailability {
      accountNumber: string;
      time: Date;
      materialResults: Array<MaterialResult>;
      serviceErrorsReturned: Array<ServiceError>;
      skippedMaterials: Array<SkippedMaterial>;
      validationResults: Array<ValidationResult>;
    }
  }

  export class Service extends AbstractService {
    /**
     *
     * @param {string} query.value
     * @param {string} query.customerAccountNumber
     * @param {AbortSignal|undefined} signal
     * @returns {Materials.Output.CustomerMaterialLookup}
     */
    async customerMaterialNumberLookup(
      query: Input.CustomerMaterialLookup,
      signal: AbortSignal
    ): Promise<Output.CustomerMaterialLookup> {
      const headers = await this.getAuthorizationHeaders();
      const url = "customermaterials";
      const params = {
        accountNumber: query.customerAccountNumber,
        materialNumber: query.value,
      };
      const result = await this.connection.post(url, params, {
        baseURL: this.baseUrl,
        headers: headers,
        signal: signal,
      });
      return result.data;
    }

    // P&A endpoint call - single item only
    /**
     *
     * @param {string} query.materialNumber
     * @param {number} query.quantity
     * @param {string} query.salesOrg
     * @param {string} query.division
     * @param {Date | undefined} query.priceDate
     * @param {string} query.customerAccountNumber
     * @param {AbortSignal|undefined} signal
     * @returns
     */
    async priceAndAvailability(
      query: Input.PriceAndAvailability,
      signal?: AbortSignal
    ): Promise<Output.PriceAndAvailability> {
      const headers = await this.getAuthorizationHeaders();

      const url = "materials/pricing";

      let { customerAccountNumber, quoteNumber, priceDate, ...rest } = query;
      let params = Object.assign({}, rest, {
        accountNumber: customerAccountNumber,
        priceDate: priceDate
          ? format(new Date(priceDate), "yyyyMMdd")
          : undefined,
        quoteNumber:
          quoteNumber && quoteNumber.length > 0 ? quoteNumber : undefined,
      });

      const result = await this.connection.post(url, params, {
        baseURL: this.baseUrl,
        headers: headers,
        signal: signal,
      });
      return result.data;
    }
  }
}
