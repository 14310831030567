import {
  AsyncStateObjectInitialStateFactory,
  AsyncStatus,
} from "store/AsyncStateObject";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RmaSearchState, {
  initialRmsSearchQueryState,
} from "./model/RmaSearchState";
import { RmaSearchQuery } from "./model/RmaSearchQuery";
import RMAService from "../services/RMAService";
import { LocalStorage } from "store/localStorage";
import { ControlledTableState } from "features/common/components/Table/models";

export const storeKey = "rmaSearch";

export const initialState: RmaSearchState = {
  query: initialRmsSearchQueryState,
  searchResult: AsyncStateObjectInitialStateFactory(),
  tableState: undefined,
};

const cachedState: RmaSearchState = LocalStorage.loadSerialized(storeKey);

export const searchRMA = createAsyncThunk(
  storeKey + "/searchRma",
  async (query: Partial<RmaSearchQuery>, thunkApi) => {
    const RmaSearchService = new RMAService();
    const abortController = new AbortController();
    const abortSignal = abortController.signal;
    thunkApi.signal.addEventListener("abort", () => {
      abortController.abort();
    });
    let data;
    await RmaSearchService.getRMABySearchString(query, abortSignal)
      .then((r) => {
        data = r;
      })
      .catch((e) => {
        return Promise.reject(e?.message || e);
      });
    return data;
  }
);

export const rmaSearchSlice = createSlice({
  name: storeKey,
  initialState: cachedState || initialState,
  reducers: {
    setTableState: (
      state,
      action: PayloadAction<ControlledTableState | undefined>
    ) => {
      state.tableState = action.payload;
    },
    reset: (
      state,
      action: PayloadAction<Partial<RmaSearchState> | undefined>
    ) => {
      return action.payload
        ? Object.assign({}, initialState, action.payload)
        : initialState;
    },
  },

  extraReducers(builder) {
    // THUNK getSystemBrands
    builder.addCase(searchRMA.pending, (state) => {
      state.searchResult.status = AsyncStatus.LOADING;
      state.searchResult.error = undefined;
      state.searchResult.data = undefined;
    });
    builder.addCase(searchRMA.fulfilled, (state, { payload }) => {
      state.searchResult.status = AsyncStatus.SUCCEEDED;
      state.searchResult.data= payload;

    });
    builder.addCase(searchRMA.rejected, (state, { error }) => {
      state.searchResult.status = AsyncStatus.FAILED;
      state.searchResult.error =
        error.message || "An unknown error occurred while searching for RMA.";
      console.error("searchRma", error);
    });
  },
});
