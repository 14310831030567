import RMAEntryService from "../services/RMAEntryServices";
import { Notify } from "store/system/notifications/helpers";

export const fetchReturnReasonOptions = async (salesOrg: string) => {
  const rmaEntryServiceCall = new RMAEntryService();
  if (salesOrg) {
    const response = await rmaEntryServiceCall.getRMAReturnReasons(salesOrg);
    return response;
  }
};

export const fetchReturnUsageOptions = async (
  salesOrg: string,
  returnCode: string
) => {
  const rmaEntryServiceCall = new RMAEntryService();
  if (salesOrg && returnCode) {
    const response = await rmaEntryServiceCall.getRMAReturnUsageCodes(
      salesOrg,
      returnCode
    );
    return response;
  }
};

export const showNoReasonsFoundToast = (message: string) => {
  let id = new Date().getTime().toString();
  Notify({
    id: id,
    title: "Alert!",
    message: message,
    allowDismiss: true,
  });
};

export const mapSalesApiResponseToRMA = (data: any) => {
  let filteredItem =
    data.items?.filter((item: any) => item.invoiceNumber !== null) || [];
  return {
    invoiceNumber: data.invoiceNumber || "",
    accountNumber: data.accountNumber || "",
    salesOrg: data.brand?.salesOrg || "",
    division: data.brand?.division || "",
    jobIndicator: data.jobIndicator || "",
    salesOrderNumber: data.salesOrderNumber || "",
    invoiceDate: data.invoiceDate || "",
    purchaseOrderNumber: data.purchaseOrderNumber || "",
    specialMarkingsInstructions: data.specialMarkingsInstructions || "",
    brand: data.brand,
    items:
      filteredItem.length > 0
        ? data.items
            .map((item: any) => {
              if (
                item.invoiceNumber &&
                item.invoiceNumber !== null &&
                item.invoiceNumber !== ""
              ) {
                return {
                  invoiceNumber: item.invoiceNumber,
                  materialNumber: item.materialNumber || item.productNumber,
                  customerMaterialNumber:
                    item.customerMaterialNumber || item.customerProductNumber,
                  materialDescription:
                    item.materialDescription || item.description,
                  division: item.division || data.brand?.division || "",
                  quantity: item.quantity,
                  unitOfMeasure: item.unitOfMeasure || item.salesUnit,
                  itemNumber: item.itemNumber,
                  plant: item.plant,
                  salesOrg: data.brand?.salesOrg,
                  salesOrderNumber: data.salesOrderNumber,
                  unitPrice: item.unitPrice,
                  lineTotal: item.lineTotal,
                  currency: item.currency,
                  reason: { code: "", name: "" },
                  isSelected: false,
                  isQuantityValid: false,
                  isQuantityEdited: false,
                };
              } else {
                return undefined;
              }
            })
            .filter((i: any) => i !== undefined)
        : [],
  };
};
