import { AsyncStateObject } from "store/AsyncStateObject";
import { RMAEntryResponse, RMAReasons, RMAReviewFormResponse, ScreenStage, SearchType } from "./RmaEntrySearchQuery";
import { ControlledTableState } from "features/common/components/Table/models";

export interface RmaEntrySearchQueryState {
  invoiceNumber: string | undefined
};

export interface RmaEntrySearchState {
  query: RmaEntrySearchQueryState
  searchResult: AsyncStateObject<RMAEntryResponse>
  formReviewDetails: RMAReviewFormResponse | undefined;
  tableState: ControlledTableState | undefined;
  reasons:RMAReasons;
  reasonsList:undefined;
  rmaSearchType: SearchType;
  itemsValidtionFailed:boolean;
  rmaScreenStage: ScreenStage;
};

export const initialRmaEntrySearchQueryState: RmaEntrySearchQueryState = {
  invoiceNumber: undefined
};
export const initialreviewFormDetailsState: RMAReviewFormResponse = {
  customerContactExt:"",
  customerNotes:"",
  debitMemoNumber:"",
  ext:"",
  firstName:"",
  internalNotes:"",
  searchNumber:"",
  lastName:"",
  phoneNumber:"",
  purchaseOrderNumber:"",
  salesAgentExt:"",
  salesAgentFirstName:"",
  salesAgentLastName:"",
  salesAgentPhoneNumber:"",
  reasonForReturn:"",
};


export const initialReasonState: RMAReasons = {
  code:"",
  value:"",
}
