import classNames from "classnames";
import { ReactNode } from "react";
import { ServiceMessage } from "services/CDNApi/ServiceMessage";
import { useSystem } from "store/system/useSystem";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";

const RenderServiceMessage: React.FC<{ message: ServiceMessage }> = ({
  message,
}): ReactNode => {
  return (
    <div
      className={classNames([
        "serviceMessage",
        message.level,
        message?.type ?? "unknown-type",
      ])}
    >
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        fixedWidth
        className="icon"
      />
      <span className="title">{message.title}</span>
      <span className="message">{message.message}</span>
    </div>
  );
};

export const ServiceMessages = (): ReactNode => {
  const {
    state: { serviceMessages },
  } = useSystem();

  return (
    <div className="serviceMessages">
      {serviceMessages?.map((d, i) => (
        <RenderServiceMessage message={d} key={i} />
      ))}
    </div>
  );
};
