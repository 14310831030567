import { FC, useEffect, useState } from "react";
import "./style.scss";
import ItemUpdateListener from "./ItemUpdateListener";
import OrderEventModal from "./OrderEventModal";
import {
  selectSystemCurrentAccountNumber,
  userHasFeatureAccess,
} from "store/system/selectors";
import useUserProfile from "store/system/useUserProfile";
import { AddToCartEventKey, RefreshCartEventKey } from "./events";
import { OrderEventPayload } from "../OrderEntryActionButton/models/OrderEventPayload";
import CartIcon from "../CartIcon";
import {
  fetchOrderDraftForAccount,
  resetCart,
} from "features/OrderEntry/store/dispatchers";

const CartActionEventListener: FC<{ isLoading: boolean }> = (
  props
): JSX.Element => {
  const {
    state: { currentAccount },
  } = useUserProfile();

  const [orderEventPayload, setOrderEventPayload] =
    useState<OrderEventPayload>();

  const handleAddToCartEvent = (evt: CustomEvent<OrderEventPayload>): void => {
    console.info("AddToCart Event Handler", evt.type);
    setOrderEventPayload(evt.detail);
  };

  const handleRefreshLocalCartStateEvent = (evt: Event): void => {
    const currentAccount = selectSystemCurrentAccountNumber();
    if (currentAccount) {
      console.info("RefreshCart Event Handler", evt.type);
      refreshLocalCartState(currentAccount);
    }
  };

  useEffect(() => {
    document?.addEventListener(
      AddToCartEventKey,
      handleAddToCartEvent as (evt: Event) => void
    );
    return () =>
      document?.removeEventListener(
        AddToCartEventKey,
        handleAddToCartEvent as (evt: Event) => void
      );
  }, []);

  useEffect(() => {
    document?.addEventListener(
      RefreshCartEventKey,
      handleRefreshLocalCartStateEvent as (evt: Event) => void
    );
    return () =>
      document?.removeEventListener(
        RefreshCartEventKey,
        handleRefreshLocalCartStateEvent as (evt: Event) => void
      );
  }, []);

  useEffect(() => {
    if (currentAccount?.accountNumber) {
      if (displayCartItem()) {
        setOrderEventPayload(undefined);
        refreshLocalCartState(currentAccount.accountNumber);
      }
    }
  }, [currentAccount?.accountNumber]);

  const handleClose = () => {
    setOrderEventPayload(undefined);
  };

  return !props.isLoading && displayCartItem() ? (
    <>
      <CartIcon />
      <ItemUpdateListener />
      {orderEventPayload && (
        <OrderEventModal onClose={handleClose} payload={orderEventPayload} />
      )}
    </>
  ) : (
    <div></div>
  );
};

export default CartActionEventListener;

const displayCartItem = () => {
  return userHasFeatureAccess("order-entry");
};

const refreshLocalCartState = async (accountNumber: string) => {
  // make sure we don't have any active service calls?
  resetCart();
  await fetchOrderDraftForAccount(accountNumber);
};
