import { UserRecord } from "../store/systemStore";
import { initialState, UserProfile } from "../store/userProfileStore";
import { formatLastLogon } from "./formatHelper";
import { AccountInfo } from "@azure/msal-browser";

export interface CustomClaimsToken {
  firstName: string;
  lastName: string;
  domainIdentityType: "employee" | string;
}

export const mapAccountToUserProfile = (obj: AccountInfo, appUser: UserRecord | Partial<UserRecord> | undefined) => {  
  let user: Partial<UserProfile>;
  if (obj && appUser) {
    user = {
      id: obj.homeAccountId.slice(
        0,
        obj.homeAccountId.indexOf("-b2c_")
      ),
      connectId: appUser?.userId?.toString() || "",
      identityApiId: appUser?.identityApiId?.toString() || "",
      userName: obj.username,
      name: obj.name || "",
      firstName: (obj.idTokenClaims as CustomClaimsToken)?.firstName,
      lastName: (obj.idTokenClaims as CustomClaimsToken)?.lastName,
      isInternalUser: (obj.idTokenClaims as CustomClaimsToken)?.domainIdentityType
        ? (obj.idTokenClaims as CustomClaimsToken)?.domainIdentityType === "employee"
        : false,
      lastLogon: formatLastLogon(appUser?.lastLogon || ""),
      accountsAssigned: appUser?.accountsAssigned || false,
      userType: appUser.userTypeDetails,
      phoneNumber: appUser?.phoneNumber || "",
      companyName: appUser?.companyName || "",
      country: appUser?.country || "",
      ...obj,
    };
  } else {    
    user = { ...initialState, ...obj };
  }  
  return user;
}
