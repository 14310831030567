import hcLogo from "../../assets/images/HubbellConnectLogo2024.svg";
import "../../assets/scss/App.scss";
import "./style.scss";

const Maintenance: React.FC<{}> = () => {
  return (
    <div id="page_maintenance">
      <img src={hcLogo} alt="HubbellConnect" />
      <h1>We'll be back shortly!</h1>
      <p>
        HubbellConnect is down temporarily for scheduled maintenance, but we'll
        be back online as soon as possible.
      </p>
    </div>
  );
};

export default Maintenance;
