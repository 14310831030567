import ApiService from "services/ApiService";

const subscriptionKeyHeader = process.env.REACT_APP_APIM_CORE_KEY_HEADER_NAME;
const subscriptionKeyValue = process.env.REACT_APP_APIM_CORE_KEY;

export abstract class AbstractService extends ApiService {
  protected baseUrl = process.env.REACT_APP_HYBRIS_API_BASE;
  getAuthorizationHeaders = async (): Promise<{ [key: string]: any }> => {
    const headers: Record<string, unknown> = {};
    let token = await this.authorize();
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
      headers[`${subscriptionKeyHeader}`] = subscriptionKeyValue;
    }
    return headers;
  };
}
