import { CSSProperties, FC } from "react";
import "./style.scss";
import ErrorResponse from "services/models/ErrorResponse";

const ErrorPanel: FC<{
  style?: CSSProperties;
  error: ErrorResponse;
}> = ({ error, style }) => {
  return (
    <div className={`error-panel`} style={style}>
      <h5 className={`error-title`}>{error.message}</h5>
      <span className={`error-message`}>{error.detail}</span>
    </div>
  );
};

export default ErrorPanel;
