import SearchTypes from "features/QuoteSearch/store/models/SearchType";
import { ControlledTableState } from "features/common/components/Table/models";
import SapRfcIntegrationApi from "services/SapRfcIntegrationApi";
import { AsyncStateObject } from "store/AsyncStateObject";

export interface QuoteSearchApiResult
  extends SapRfcIntegrationApi.SalesQuote.Output.Search {}

// must serialize dates manually for redux state
export interface QuoteSearchQueryState {
  type: SearchTypes | undefined;
  accountNumber: string | undefined;
  createdFromDate: string | undefined; // ISO date string
  createdToDate: string | undefined; // ISO date string
  productNumber: string | undefined;
  quoteNumber: string | undefined;
  projectName: string | undefined;
  quoteType: string | undefined;
  modeSelect: string | undefined;
}
export default interface QuoteSearchState {
  query: QuoteSearchQueryState;
  searchResult: AsyncStateObject<QuoteSearchApiResult>;
  tableState: ControlledTableState | undefined;
}

export const initialQuoteSearchQueryState: QuoteSearchQueryState = {
  type: SearchTypes.QuoteNumber,
  accountNumber: undefined,
  createdFromDate: undefined,
  createdToDate: undefined,
  productNumber: undefined,
  quoteNumber: undefined,
  projectName: undefined,
  quoteType: undefined,
  modeSelect: undefined,
};
