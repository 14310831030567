import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
//import store, { registerReducer, StateSelectorFactory } from "store";

import { LocalStorage } from "store/localStorage";
import ReplacementPartsState, {
  InitialReplacementPartsState,
} from "./models/ReplacementPartsState";
import { ControlledTableState } from "features/common/components/Table/models";
import {
  AsyncStateObjectInitialStateFactory,
  AsyncStatus,
} from "store/AsyncStateObject";
import SapRfcIntegrationApi from "services/SapRfcIntegrationApi";
import { getProductDetailForMaterial } from "services/ProductDetailHelper";
import CommerceApi from "services/CommerceApi";
import { has } from "lodash";

export const storeKey = "replacementParts";

export const priceAndAvailabilityStoreKey = (
  query:
    | SapRfcIntegrationApi.Materials.Input.PriceAndAvailability
    | Pick<
        SapRfcIntegrationApi.Materials.Input.PriceAndAvailability,
        "materialNumber" | "salesOrg" | "division"
      >
) => {
  return "{material}_{salesOrg}_{division}"
    .replace("{material}", query.materialNumber)
    .replace("{salesOrg}", query.salesOrg)
    .replace("{division}", query.division);
};

export const searchReplacementParts = createAsyncThunk(
  storeKey + "/searchOrders",
  async (
    query: SapRfcIntegrationApi.ReplacementParts.Input.Search,
    thunkApi
  ) => {
    const replacementPartsService =
      new SapRfcIntegrationApi.ReplacementParts.Service();
    const abortController = new AbortController();
    const abortSignal = abortController.signal;
    thunkApi.signal.addEventListener("abort", () => {
      abortController.abort();
    });
    let data;
    await replacementPartsService.search(query, abortSignal).then((r) => {
      data = r;
    });
    return data;
  }
);

export const getBomComponents = createAsyncThunk(
  storeKey + "/getBomComponents",
  async (query: string, thunkApi) => {
    const replacementPartsService =
      new SapRfcIntegrationApi.ReplacementParts.Service();
    const abortController = new AbortController();
    const abortSignal = abortController.signal;
    let data;
    await replacementPartsService
      .getBomComponentsForMaterial(query, abortSignal)
      .then((r) => {
        data = r;
      });
    return data;
  }
);

export const getMaterialDetail = createAsyncThunk(
  storeKey + "/getMaterialDetail",
  async (query: CommerceApi.Material.Input.MaterialToStepId, thunkApi) => {
    const abortController = new AbortController();
    const abortSignal = abortController.signal;
    thunkApi.signal.addEventListener("abort", () => {
      abortController.abort();
    });
    let data;
    await getProductDetailForMaterial(query, abortSignal).then((r) => {
      data = r;
    });
    return data;
  }
);

export const getMaterialPriceAndAvailability = createAsyncThunk(
  storeKey + "/getMaterialPriceAndAvailability",
  async (
    query: SapRfcIntegrationApi.Materials.Input.PriceAndAvailability,
    thunkApi
  ) => {
    let service = new SapRfcIntegrationApi.Materials.Service();
    const abortController = new AbortController();
    const abortSignal = abortController.signal;
    thunkApi.signal.addEventListener("abort", () => {
      abortController.abort();
    });
    let data;
    // check store first to see if the data is cached
    const key = priceAndAvailabilityStoreKey(query);
    const currentState = thunkApi.getState() as ReplacementPartsState;
    // if so, return
    if (has(currentState.materialPriceAndAvailability, key)) {
      data = currentState.materialPriceAndAvailability[key];
    }
    // else, fetch from API
    else {
      await service.priceAndAvailability(query, abortSignal).then((r) => {
        data = r;
      });
    }
    return data;
  }
);

export const initialState: ReplacementPartsState = InitialReplacementPartsState;
const cachedState: ReplacementPartsState =
  LocalStorage.loadSerialized(storeKey);

export const replacementPartsSlice = createSlice({
  name: storeKey,
  initialState: cachedState || initialState,
  reducers: {
    setUserInput: (
      state,
      action: PayloadAction<ReplacementPartsState["userInput"]>
    ) => {
      state.userInput = action.payload;
    },
    reset: (
      state,
      action: PayloadAction<Partial<ReplacementPartsState> | undefined>
    ) => {
      return action.payload
        ? Object.assign({}, initialState, action.payload)
        : initialState;
    },
    setSearchResult: (
      state,
      action: PayloadAction<Partial<ReplacementPartsState["searchResult"]>>
    ) => {
      state.searchResult = Object.assign(
        {},
        state.searchResult,
        action.payload
      );
    },
    setComponentsResult: (
      state,
      action: PayloadAction<Partial<ReplacementPartsState["componentsResult"]>>
    ) => {
      state.componentsResult = Object.assign(
        {},
        state.componentsResult,
        action.payload
      );
    },
    setMaterialDetail: (
      state,
      action: PayloadAction<Partial<ReplacementPartsState["materialDetail"]>>
    ) => {
      state.materialDetail = Object.assign(
        {},
        state.materialDetail,
        action.payload
      );
    },
    setSearchResultTableState: (
      state,
      action: PayloadAction<ControlledTableState | undefined>
    ) => {
      state.tableState.searchResult = action.payload;
    },
    setComponentsResultTableState: (
      state,
      action: PayloadAction<ControlledTableState | undefined>
    ) => {
      state.tableState.componentsResult = action.payload;
    },
    resetPriceAndAvailabilityResults: (state) => {
      state.materialPriceAndAvailability =
        initialState.materialPriceAndAvailability;
    },
  },
  extraReducers(builder) {
    // THUNK searchReplacementParts
    builder.addCase(searchReplacementParts.pending, (state) => {
      state.searchResult.status = AsyncStatus.LOADING;
      state.searchResult.error = undefined;
      state.searchResult.data = undefined;
    });
    builder.addCase(searchReplacementParts.fulfilled, (state, { payload }) => {
      state.searchResult.status = AsyncStatus.SUCCEEDED;
      state.searchResult.data = payload;
    });
    builder.addCase(searchReplacementParts.rejected, (state, { error }) => {
      state.searchResult.status = AsyncStatus.FAILED;
      state.searchResult.error =
        error.message ||
        "An unknown error occurred while searching for replacement parts.";
      console.error("searchReplacementParts", error);
    });
    // THUNK getBomComponents
    builder.addCase(getBomComponents.pending, (state) => {
      state.componentsResult.status = AsyncStatus.LOADING;
      state.componentsResult.error = undefined;
      state.componentsResult.data = undefined;
    });
    builder.addCase(getBomComponents.fulfilled, (state, { payload }) => {
      state.componentsResult.status = AsyncStatus.SUCCEEDED;
      state.componentsResult.data = payload;
    });
    builder.addCase(getBomComponents.rejected, (state, { error }) => {
      state.componentsResult.status = AsyncStatus.FAILED;
      state.componentsResult.error =
        error.message ||
        "An unknown error occurred while searching for replacement parts.";
      console.error("getBomComponents", error);
    });
    // THUNK getMaterialDetail
    builder.addCase(getMaterialDetail.pending, (state) => {
      state.materialDetail.status = AsyncStatus.LOADING;
      state.materialDetail.error = undefined;
      state.materialDetail.data = undefined;
    });
    builder.addCase(getMaterialDetail.fulfilled, (state, { payload }) => {
      state.materialDetail.status = AsyncStatus.SUCCEEDED;
      state.materialDetail.data = payload;
    });
    builder.addCase(getMaterialDetail.rejected, (state, { error }) => {
      state.materialDetail.status = AsyncStatus.FAILED;
      state.materialDetail.error =
        error.message ||
        "An unknown error occurred while searching for replacement parts.";
      console.error("getBomComponents", error);
      state.materialDetail.data = undefined;
    });
    // THUNK getMaterialPriceAndAvailability
    builder.addCase(
      getMaterialPriceAndAvailability.pending,
      (state, thunkApi) => {
        const key = priceAndAvailabilityStoreKey(thunkApi.meta.arg);
        if (has(state.materialPriceAndAvailability, key)) {
          return state;
        } else {
          let value = AsyncStateObjectInitialStateFactory();
          value.status = AsyncStatus.LOADING;
          state.materialPriceAndAvailability[key] = value;
        }
      }
    );
    builder.addCase(
      getMaterialPriceAndAvailability.fulfilled,
      (state, { meta, payload }) => {
        const key = priceAndAvailabilityStoreKey(meta.arg);
        if (!has(state.materialPriceAndAvailability, key)) {
          console.error(
            "Replacement Parts: " + key + " not found when promise resolved."
          );
          return state;
        } else {
          let value = Object.assign(
            {},
            state.materialPriceAndAvailability[key]
          );
          value.status = AsyncStatus.SUCCEEDED;
          // @ToDo: follow up on this typing
          value.data =
            payload as unknown as SapRfcIntegrationApi.Materials.Output.PriceAndAvailability;
          state.materialPriceAndAvailability[key] = value;
        }
      }
    );
    builder.addCase(
      getMaterialPriceAndAvailability.rejected,
      (state, { meta, error }) => {
        const key = priceAndAvailabilityStoreKey(meta.arg);
        if (!has(state.materialPriceAndAvailability, key)) {
          console.error(
            "Replacement Parts: " + key + " not found when promise rejected."
          );
          return state;
        } else {
          let value = Object.assign(
            {},
            state.materialPriceAndAvailability[key]
          );
          value.status = AsyncStatus.FAILED;
          value.data = undefined;
          value.error = error.message;
          state.materialPriceAndAvailability[key] = value;
        }
      }
    );
  },
});

/*
export function initModuleState() {
  registerReducer(storeKey, featureSlice.reducer);
}

export const getState = StateSelectorFactory(initialState, storeKey);

export const subscribe = (f: Function) => {
  let lastState = getState();
  return store.subscribe(
    () => lastState !== getState() && f((lastState = getState()))
  );
};

// cached state changes to local storage
store.subscribe(
  debounce(() => {
    LocalStorage.saveSerialized(storeKey, getState());
  }, 800)
);
*/
