import { FC, useCallback, useMemo, useState } from "react";
import useInterval from "../hooks/useInterval";
import { AuthService } from "../services/AuthService";

const intervalDelay = 1000 * 60 * 5; // 5 minutes before refresh

/*
 * Component to periodically check the user's authentication status
 */
const UserSessionTokenPollerComponent: FC<{}> = (): JSX.Element => {
  const auth = useMemo(() => new AuthService(), []);
  const [lastRan, setLastRan] = useState(new Date().getTime());

  const hybernationPoll = useCallback(() => {
    const currentTime = new Date().getTime();
    // if the interval was paused for more than 15 minutes, log them out
    // this can happen if the computer was sleeping
    if (currentTime > lastRan + intervalDelay * 3) {
      console.info(
        "Signing user out due to long period of sleep.",
        lastRan,
        currentTime
      );
      auth.signOut();
    }
  }, [auth, lastRan]);

  // periodically refresh the user's token and log the time
  useInterval(() => {
    const currentTime = new Date().getTime();
    console.info("Auto refreshing session token.", currentTime);
    auth.acquireToken();
    setLastRan(currentTime);
  }, intervalDelay);

  useInterval(hybernationPoll, 5000);

  return <></>;
};
export default UserSessionTokenPollerComponent;
