export const EnvironmentName = process.env.REACT_APP_ENVIRONMENT_NAME;
export enum EnvironmentNames {
  PROD = "PROD",
  DEV = "DEV",
  QA = "QA",
  UAT = "UAT",
}
export const CountryCode =
  process.env.REACT_APP_COUNTRY_CODE &&
  process.env.REACT_APP_COUNTRY_CODE !== "#{COUNTRY_CODE}#"
    ? process.env.REACT_APP_COUNTRY_CODE
    : "US";
