import React, {
  ChangeEvent,
  createRef,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  PopoverBody,
  Row,
} from "react-bootstrap";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import {
  faArrowCircleRight,
  faCircle,
  faFolderOpen,
  faHeart,
} from "@fortawesome/pro-solid-svg-icons";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import classNames from "classnames";
import "./style.scss";
import UserAccountService from "../../services/UserAccountService";
import { UserAccount } from "../../models/UserAccount";
import { ApiError } from "../../services/ApiService";

import useUserProfile from "store/system/useUserProfile";
import useSystem from "store/system/useSystem";
import { setSystemUserCurrentAccount } from "store/system/dispatchers";

const FolderCirleIcon = () => {
  return (
    <span className="fa-layers fa-fw folderCircleIcon">
      <FontAwesomeIcon icon={faCircle} fixedWidth color="#ffd100" />
      <FontAwesomeIcon
        icon={faFolderOpen}
        fixedWidth
        color="#000000"
        transform="shrink-9"
      />
    </span>
  );
};

export const UserAccountSearch: FC<{}> = (): JSX.Element => {
  const { state: user } = useUserProfile();
  const [validated, setValidated] = useState(false);
  const [valid, setValid] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const [searching, setSearching] = useState<boolean>(false);
  const inputRef = createRef<HTMLInputElement>();
  const userAccountService = useRef(new UserAccountService());

  const validateInputFormat = (input: string) => {
    const regex = RegExp("[0-9]{6}");
    return regex.test(input) && input.length === 6;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    inputRef.current?.setCustomValidity("");
    setValidated(false);
    setError("");
    const newValue = event.target.value.trim();
    setValue(newValue);
    if (newValue.length === 0) {
      setValid(undefined);
    } else {
      setValid(validateInputFormat(newValue));
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    let msg = "";
    if (valid) {
      if (user.identityApiId) {
        msg = `Couldn't find account "${value}"`;
        setSearching(true);
        userAccountService.current
          .findUserAccount(
            { userId: user.identityApiId, accountNumber: value },
            userAccountService.current.generateSourceToken()
          )
          .then((r) => {
            if (r.data?.customers?.length > 0) {
              /*
              ProfileDuck.actions.SET_CURRENT_ACCOUNT(dispatch, {
                account: r.data.customers[0],
                user,
              });
              */
              setSystemUserCurrentAccount(r.data.customers[0], user);
              document.body.click();
              setValid(true);
            } else {
              if (r.data?.error && r.data?.message?.length) {
                msg = r.data.message;
              }
              inputRef.current?.setCustomValidity(msg);
              setError(msg);
              setValid(false);
            }
          })
          .catch((e: ApiError) => {
            console.error(e.message, e.response?.data);
            let msg = e.message;
            inputRef.current?.setCustomValidity(msg);
            setError(msg);
            setValid(false);
          })
          .finally(() => {
            setValidated(true);
            setSearching(false);
          });
      }
    } else {
      let msg = "invalid format";
      inputRef.current?.setCustomValidity(msg);
      setError(msg);
      setValid(false);
      setValidated(true);
    }
  };
  return (
    <Form
      id="soldToSearch"
      onSubmit={handleSubmit}
      noValidate
      validated={validated}
    >
      <Row className="g-0">
        <Col>
          <Form.Control
            ref={inputRef}
            name="accountNumber"
            placeholder="6-digit acct number"
            value={value}
            onChange={handleChange}
            //isValid={valid}
          />
        </Col>
        <Col className="col-auto">
          <Button type="submit" variant="clear">
            <FontAwesomeIcon
              icon={searching ? faSpinner : faArrowCircleRight}
              color={valid ? "#ffd100" : "#545454"}
              size="2x"
              title="Switch to this account"
            />
          </Button>
        </Col>
      </Row>
      {validated && !valid && (
        <Form.Text className="text-danger text-left">
          {value.length > 0 ? error : null}
          <br />
          <Link
            className="text-danger"
            onClick={() => {
              document.body.click();
            }}
            to="/user/requestaccounts"
          >
            {value.length === 6 ? "Request access?" : null}
          </Link>
        </Form.Text>
      )}
    </Form>
  );
};

const UserAccountMenuItem: FC<{ isLoading: boolean }> = (
  props
): JSX.Element => {
  const { state: user } = useUserProfile();
  const { state: system } = useSystem();

  const [isActive, setIsActive] = useState(false);
  const [isLoadingUserAccounts, setIsLoadingUserAccounts] = useState(true);
  const handleSetCurrentAccount = (account: UserAccount) => {
    //ProfileDuck.actions.SET_CURRENT_ACCOUNT(dispatch, { account, user });
    setSystemUserCurrentAccount(account, user);
  };

  let popovercontent = <></>;
  const currentAccountContent = useCallback(() => {
    if (user.loading || !system.isReady) {
      return <>{"Loading user accounts..."}</>;
    } else if (user.currentAccount && user.loadingBrands) {
      return <>{"Switching accounts..."}</>;
    } else if (!user.currentAccount && user.loadingBrands) {
      return <>{"Loading account..."}</>;
    } else if (!user.currentAccount) {
      return <>{"Select an account "}</>;
    } else {
      return (
        <>
          <span className="soldTo_account">
            {user.currentAccount.accountNumber}
          </span>
          <span className="soldTo_name">{user.currentAccount.name}</span>
        </>
      );
    }
  }, [user, system]);

  useEffect(() => {
    if (props.isLoading === false) {
      setIsLoadingUserAccounts(false);
    }
  }, [props.isLoading]);

  const favoriteButtons = () =>
    user.favoriteAccounts?.map((st: UserAccount, index: number) => {
      if (st.accountNumber === user.currentAccount?.accountNumber) {
        return <React.Fragment key={st.accountNumber}></React.Fragment>;
      } else {
        return (
          <Button
            variant="clear"
            className="soldToBtn"
            onClick={(e) => {
              e.preventDefault();
              handleSetCurrentAccount(st);
              document.body.click();
            }}
            key={st.accountNumber}
          >
            <span className="soldTo_text">
              <span className="soldTo_account">{st.accountNumber}</span>
              <span className="soldTo_name">{st.name}</span>
              <span className="soldTo_branch">{st.branch}</span>
              <span className="soldTo_location">
                {st.city}, {st.region}
              </span>
            </span>
          </Button>
        );
      }
    });

  popovercontent = (
    <div>
      {user.currentAccount?.accountNumber && (
        <div className="currentAccount">
          <span className="soldTo_text">
            <span className="soldTo_account">
              {user.currentAccount.accountNumber}
            </span>
            <span className="soldTo_name">{user.currentAccount.name}</span>
            <span className="soldTo_branch">{user.currentAccount.branch}</span>
            <span className="soldTo_location">
              {user.currentAccount.city}, {user.currentAccount.region}
            </span>
          </span>
        </div>
      )}
      {user.favoriteAccounts?.length > 0 ? (
        <div className="favoriteAccounts">{favoriteButtons()}</div>
      ) : (
        <>
          <FontAwesomeIcon icon={faHeart} color="red" size="sm" /> Favorite some
          accounts for quick access from this menu!
        </>
      )}
      <div className="my-2">
        <UserAccountSearch />
      </div>
      <hr />
      <div className="d-grid">
        <Link
          onClick={() => {
            document.body.click();
          }}
          className="btn btn-sm btn-primary"
          to="/user/accounts"
        >
          View All Accounts
        </Link>
      </div>
    </div>
  );

  if (isLoadingUserAccounts) {
    return <span></span>;
  }

  const pop = (
    <Popover id="sold-to-popover">
      <PopoverBody>{popovercontent}</PopoverBody>
    </Popover>
  );

  return user.accountsAssigned === false ? (
    <div></div>
  ) : (
    <OverlayTrigger
      trigger="click"
      rootClose={true}
      placement="bottom"
      overlay={pop}
      onToggle={(show: boolean) => setIsActive(show)}
    >
      <Button
        variant="clear"
        className={classNames({ active: isActive })}
        id="soldToPopoverTrigger"
        disabled={user.loading || !system.isReady || user.loadingBrands}
      >
        <FolderCirleIcon />
        <span className="soldTo_text">{currentAccountContent()}</span>
        <FontAwesomeIcon icon={faChevronDown} size="xs" />
      </Button>
    </OverlayTrigger>
  );
};
export default UserAccountMenuItem;
