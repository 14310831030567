import { faCircle, faSpinnerThird, faHourglassHalf, faExclamationSquare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

export const SearchingIcon: FC<{ title?: string; error?: string }> = ({ title, error }) => {
  return <>
    <span className="fa-layers fa-fw fa-7x">
      <FontAwesomeIcon icon={faCircle} color="#f4f4f4" />
      {error == null && <FontAwesomeIcon icon={faSpinnerThird} spin color="#2d8c9e" />}
      <FontAwesomeIcon icon={error != null ? faExclamationSquare : faHourglassHalf} transform="shrink-11" color={error ? "#d0021b" : "#78787b"} />
    </span>
    <br />
    <span>
      {error}
    </span>
    <h6>
      <b >{title}</b>
    </h6>
  </>;
};
