import ApiService from "../../../services/ApiService";
import {
  RMAInvoiceValidateQuery,
  RMAInvoiceValidateResponse,
} from "../store/model/RMAInvoiceItemsValidationQuery";
import {
  ReviewScreenResponse,
  ReviewScreenSearchQuery,
  RMAEntryResponse,
  RmaEntrySearchQuery,
  RMAReturnsReasons,
  SearchType,
} from "../store/model/RmaEntrySearchQuery";

const rmaCodesAPIURL = process.env.REACT_APP_RMA_CODES_API;
const coreAPIM_Header = process.env.REACT_APP_APIM_CORE_KEY_HEADER_NAME;
const coreAPIM_Key = process.env.REACT_APP_APIM_CORE_KEY;

const baseURL = process.env.REACT_APP_RMA_ENTRY;
const coreAPIM_SRI_PATH = process.env.REACT_APP_SRI_API_BASE;

class RMAEntryService extends ApiService {
  async getRMARequestForm(
    query: RmaEntrySearchQuery,
    abortSignal?: AbortSignal
  ): Promise<RMAEntryResponse> {
    const headers = await this.getAuthorizationHeaders();
    let result: any;
    let url;
    switch (query.type) {
      case SearchType.invoice:
        url = `/invoice/${query?.invoiceNumber}`;
        result = await this.connection.get(url, {
          baseURL: baseURL,
          headers: headers,
          signal: abortSignal,
        });
        break;
      case SearchType.saleOrder:
        if (coreAPIM_Header) {
          headers[coreAPIM_Header] = coreAPIM_Key;
        }
        url = `/salesorder/${query?.salesOrderNumber}`;
        result = await this.connection.get(url, {
          baseURL: coreAPIM_SRI_PATH,
          headers: headers,
          signal: abortSignal,
        });
        break;
      case SearchType.purchaseOrder:
        if (coreAPIM_Header) {
          headers[coreAPIM_Header] = coreAPIM_Key;
        }
        url = `salesorder/searchall`;
        result = await this.connection.get(url, {
          baseURL: coreAPIM_SRI_PATH,
          headers: headers,
          params: {
            orderNumber: '',
            purchaseOrderNumber: query.purchaseOrderNumber,
            isHubbellConnectOrder: false,
          },
          signal: abortSignal,
        })
        break;
    }
    if (query.type === SearchType.purchaseOrder) {
      let data = result?.data?.salesOrders?.filter(
        (o: any) => o.salesOrderNumber !== null
      );
      return data;
    }
    return await result.data;
  }

  async rmaReviewScreenSubmission(
    query: ReviewScreenSearchQuery,
    abortSignal?: AbortSignal
  ): Promise<ReviewScreenResponse[]> {
    let token = await this.authorize();
    const headers = await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    const url = `/rma/create`;
    let result = await this.connection.post(url, query, {
      baseURL: coreAPIM_SRI_PATH,
      headers: headers,
      signal: abortSignal,
    });
    return await result.data;
  }

  async validateInvoiceItems(
    query: RMAInvoiceValidateQuery,
    abortSignal?: AbortSignal
  ): Promise<RMAInvoiceValidateResponse> {
    const headers = await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const url = `/rma/Validate`;
    let result = await this.connection.post(url, query, {
      baseURL: baseURL,
      headers: headers,
      signal: abortSignal,
    });
    return await result.data;
  }

  async getRMAReturnReasons(
    salesOrg: string,
    abortSignal?: AbortSignal
  ): Promise<RMAReturnsReasons[]> {
    const headers = await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    //const url = `usagecodes?salesorg=${salesOrg}&reasoncode=AIQ`
    const url = `reasoncodes?salesorg=${salesOrg}`;
    let result = await this.connection.get(url, {
      baseURL: rmaCodesAPIURL,
      headers: headers,
      signal: abortSignal,
    });
    return result.data;
  }

  async getRMAReturnUsageCodes(
    salesOrg: string,
    returnCode: string,
    abortSignal?: AbortSignal
  ): Promise<RMAReturnsReasons[]> {
    const headers = await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const url = `usagecodes?salesorg=${salesOrg}&reasoncode=${returnCode}`;
    let result = await this.connection.get(url, {
      baseURL: rmaCodesAPIURL,
      headers: headers,
      signal: abortSignal,
    });
    return result.data;
  }
}

export default RMAEntryService;
