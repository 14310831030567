import NotificationLevel from "./NotificationLevel";

export const NotificationDismissedEvent = "HC_NotificationDismissed";

interface Notification {
  id: string;
  time?: number;
  type?: string;
  message?: string;
  title?: string;
  level?: NotificationLevel;
  allowDismiss?: boolean;
}
export default Notification;
