import SapRfcIntegrationApi from "services/SapRfcIntegrationApi";
import MaterialInventory from "services/models/MaterialInventory";

export enum OrderAddressTypeName {
  SoldTo = "Sold-To",
  BillTo = "Bill-To",
  ShipTo = "Ship-To",
  ThirdPartyFreight = "Freight-Third-Party",
}

export enum OrderSourceName {
  Reorder = "ExistingOrder",
  Quote = "ExistingQuote",
  New = "New",
}

export enum OrderTypeName {
  Standard = "standard",
  ReplacementPart = "replacement-standard",
  ReplacementPartWarranty = "replacement-warranty",
  Sample = "sample"
}

export default interface HubbellConnectOrder {
  orderId: string;
  orderNumber: string;
  orderType: OrderType;
  orderSource: OrderSource;
  accountNumber: string;
  sapOrderNumbers: string;
  orderState: OrderState;
  orderStateNote: string;
  createdBy: userData;
  createdOn: string;
  modifiedBy: userData;
  modifiedOn: string;
  orderAddresses: Address[];
  items: Item[];
  details: Detail[];
  suggestedAddress?: Address;
}

export function isHubbellConnectOrder(x: any): x is HubbellConnectOrder {
  const test =
    typeof x === "object" &&
    "orderId" in x &&
    "accountNumber" in x &&
    "orderNumber" in x &&
    "orderType" in x &&
    "orderState" in x;

  return test;
}

export interface OrderSource {
  id?: string;
  name?: OrderSourceName;
  description?: string;
}

export interface OrderType {
  id?: string;
  name?: OrderTypeName;
  description?: string;
}

export interface userData {
  userId: string;
  userName: string;
  name: string;
}

export interface Detail {
  agentNotes: string;
  carrierInstructions: string;
  carrierPreference: string;
  carrierPreferenceName: string;
  createdBy: userData;
  createdOn: string;
  destinationCountry: string;
  division: string;
  freightAccountNumber: string;
  freightTermsName: string;
  freightTermsValue: string;
  freightThirdPartyAddress: Address | undefined;
  holdForReview: boolean;
  internalNotes: string;
  markings: string;
  modifiedBy: userData;
  modifiedOn: string;
  notes: string;
  orderDetailId: string;
  orderType: string; // Note: this field is specific to the detail object, not the same as the draft orderType
  poAttachmentUrl: string;
  purchaseOrderNumber: string;
  purchaseOrderDate: string | Date;
  requestedShipDate: string | Date;
  rush: boolean;
  salesOrg: string;
  sapOrderNumber: string;
  shipComplete: boolean;
  specialInstructions: string;
  payWithCreditCard: boolean;
  reasonForHold?: string; // Adding optional parameter to store the reason for hold
}

export interface AddressError {
  message: string;
  subject: string;
}

export interface FreightTerms {
  freightTermsId: string;
  name: string;
  description: string;
  value: string;
}

export interface FreightDetailsTerms extends FreightTerms {
  salesOrg: string;
  division: string;
  isRush: boolean;
  isStandard: boolean;
}

export interface Address {
  orderAddressId: string;
  orderAddressType: OrderAddressType;
  name1: string;
  name2: string;
  name3: string;
  address1: string;
  address2: string;
  city: string;
  region: string;
  postal: string;
  country: string;
  createdBy?: userData;
  createdOn?: string;
  modifiedBy?: userData;
  modifiedOn?: string;
  isUserSupplied: boolean;
}

export interface OrderAddressType {
  orderAddressTypeId: string;
  name: string;
}

export interface Item {
  orderDetailId: string;
  orderItemId: string;
  currency: string;
  salesOrg: string;
  division: string;
  pricingDivision: string;
  materialNumber: string;
  quantity: string;
  lineNumber: string;
  purchaseOrderLineNumber: string;
  orderItemState: OrderItemState;
  orderItemStateNote: string;
  sapOrderNumber: string;
  customerPartNumber: string;
  catalogNumber: string;
  materialDescription: string;
  referenceQuoteNumber: string;
  referenceOrderNumber: string;
  requestedShipDate: string;
  consigneePurchaseOrderNumber: string;
  markings: string;
  unitPrice: string;
  priceUnit: number;
  extendedPrice: string;
  priceLastRefreshed: string;
  inventoryLastRefreshed: string;
  itemCategory: string;
  minimumOrderQty: string;
  baseUnitOfMeasure: string;
  standardPackageQty: string;
  palletQty: number;
  caseQty: number;
  cartonQty: string;
  plantNumber: string;
  plantDescription: string;
  createdBy: userData;
  createdOn: string;
  modifiedBy: userData;
  modifiedOn: string;
  grossWeight: number;
  inventory: Omit<MaterialInventory, "leadTime">;
  hasError?: boolean;
  validationResults: SapRfcIntegrationApi.Materials.ValidationResult[];
  serviceErrorsReturned: SapRfcIntegrationApi.Materials.ServiceError[];
  skippedMaterials: SapRfcIntegrationApi.Materials.SkippedMaterial[];
}

export interface OrderItemState {
  orderItemStateId: string;
  name: string;
}

export interface OrderState {
  orderStateId: string;
  name: string;
}

export enum FreightTermsEnum {
  PrepayAdd = "Prepay & Add",
  CollectYOB = "Collect",
  ThirdParty = "3rd Party",
  WillCall = "Will Call",
}

export enum OrderShipmentType {
  Standard = "Standard",
  Export = "Export",
}

export interface DetailData extends Detail {
  sapOrderNumber: string;
  carrierPreferenceName: string;
  poAttachmentUrl: string;
}

export interface CarrierPreference {
  carrierId: string;
  number: string;
  name: string;
  source: string;
  country: string;
  salesOrg: string;
  division?: string;
  createdBy: string | null;
  createdOn: string;
  modifiedBy: string | null;
  modifiedOn: string;
  isRush: boolean;
  isStandard: boolean;
}
