import { Dictionary } from "models/Indexable";
import ApiService from "services/ApiService";
import { CountryCode } from "store/system/constants";

const subscriptionKeyHeader = process.env.REACT_APP_APIM_CORE_KEY_HEADER_NAME;
const subscriptionKeyValue = process.env.REACT_APP_APIM_CORE_KEY;

const docQueueKeyName = process.env.REACT_APP_QUEUEFUNCTIONS_KEYNAME;
const docQueueKeyValue = process.env.REACT_APP_QUEUEFUNCTIONS_KEYVALUE;
export abstract class AbstractService extends ApiService {
  protected baseUrl = process.env.REACT_APP_SRI_API_BASE;
  protected commerceUrl = process.env.REACT_APP_COMMERCE_API;
  protected baseURlSAP = process.env.REACT_APP_RMA_ENTRY;
  protected documentQueueBaseUrl = process.env.REACT_APP_QUEUEFUNCTIONS_BASE;
  protected sapEnvironmentKey = process.env.REACT_APP_SAPRFC_ENV_KEY;
  protected emailQueueApiRelPath = `api/QueueDocumentsToEmailHTTPTrigger?${docQueueKeyName}=${docQueueKeyValue}`;

  getAuthorizationHeaders = async (): Promise<{ [key: string]: any }> => {
    const headers: Dictionary = {};
    let token = await this.authorize();
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
      headers[`${subscriptionKeyHeader}`] = subscriptionKeyValue;
      headers["Country"] = CountryCode;
      headers["app-env-key"] = this.sapEnvironmentKey;
    }
    return headers;
  };
}
